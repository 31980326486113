import mapboxgl from 'mapbox-gl'
import * as turf from "@turf/turf";
import handysize from '../../Assets/images/icons/handysize.png'
import handymax from '../../Assets/images/icons/handymax.png'
import mini from '../../Assets/images/icons/mini.png'
import panamax from '../../Assets/images/icons/panamax.png'
import vloc from '../../Assets/images/icons/vloc.png'
import cross from '../../Assets/images/icons/cross.png'
import capesize from '../../Assets/images/icons/capesize.png'
import countryCodes from '../../Components/CountryCodes.json'
import { IntransitPopup } from './InTransitPopup';
import { createRoot } from 'react-dom/client';
let currentPopup = null;
let logoPopup = null;
var customMarker = null
let currentLayer = [];
let markers = [];
var currentOpenSpiral = null;
let currentSpiralId = null;
let currentMapShow = null;
const SOURCE_EARTHQUAKE = 'earthquakes'
var maindata = {}
let currentZoomLevel = null;
var highlightedMarker = null;
let zoomLevel = null;
let tableCompanies = {};
let points = [];
let routesPoint = [];
let LayerPoint = [];
let SourcePoint = [];
let tableId = '';
let popupContent = null;
let globalComodity;
const intransitLayers = ['handysize', 'handymax', 'mini', 'panamax', 'vloc', 'capesize']
export const getColumns = (Filters, comodity) => {
    const DriHbiArrivalcolumns = [
        {
            title: 'Timestamp UTC',
            dataIndex: 'ata_utc'
        },
        {
            title: 'Ship Name',
            dataIndex: 'ship_name'
        },
        {
            title: 'Ship Type',
            dataIndex: 'ship_type'
        },
        {
            title: 'Vessel Class',
            dataIndex: 'size_class'
        },
        {
            title: 'DWT',
            dataIndex: 'dwt'
        },
        {
            title: 'Move Type',
            dataIndex: 'move_type'
        },
        {
            title: 'Estimated Cargo Load (t)',
            dataIndex: 'total_cargo_load'
        },
        {
            title: 'Buyer',
            dataIndex: 'mill'
        },
        {
            title: 'Arrival Country',
            dataIndex: 'dest_country'
        },
        {
            title: 'Arrival Port',
            dataIndex: 'actual_dest'
        },
        {
            title: 'Arrival Berth',
            dataIndex: 'dest_berth'
        },
        {
            title: 'Co Discharging Port',
            dataIndex: 'co_discharge'
        },
        {
            title: 'Supplier',
            dataIndex: 'company'
        },
        {
            title: 'Origin Country',
            dataIndex: 'country'
        },
        {
            title: 'Origin Port',
            dataIndex: 'previous_port_name'
        },
        {
            title: 'Origin Berth',
            dataIndex: 'dep_berth'
        },
        {
            title: 'Co Loading Port',
            dataIndex: 'co_load'
        },

    ]

    const IronOreArrivalcolumns = [
        {
            title: 'Timestamp UTC',
            dataIndex: 'ata_utc'
        },
        {
            title: 'Ship Name',
            dataIndex: 'ship_name'
        },
        {
            title: 'Ship Type',
            dataIndex: 'ship_type'
        },
        {
            title: 'Vessel Class',
            dataIndex: 'size_class'
        },
        {
            title: 'DWT',
            dataIndex: 'dwt'
        },
        {
            title: 'Move Type',
            dataIndex: 'move_type'
        },
        {
            title: 'Estimated Cargo Load (t)',
            dataIndex: 'arr_total_cargo_load'
        },
        {
            title: 'Buyer',
            dataIndex: 'mill'
        },
        {
            title: 'Arrival Country',
            dataIndex: 'actual_dest_country'
        },
        {
            title: 'Arrival Port',
            dataIndex: 'actual_dest'
        },
        {
            title: 'Arrival Berth',
            dataIndex: 'dest_berth'
        },
        {
            title: 'Co Discharging Port',
            dataIndex: 'co_discharge',
        },
        {
            title: 'Supplier',
            dataIndex: 'company'
        },
        {
            title: 'Origin Country',
            dataIndex: 'country'
        },
        {
            title: 'Origin Port',
            dataIndex: 'previous_port_name'
        },
        {
            title: 'Origin Berth',
            dataIndex: 'dep_berth'
        },
        {
            title: 'Co Loading Port',
            dataIndex: 'co_load'
        },

    ]

    const Arrivalcolumns = [
        {
            title: 'Timestamp UTC',
            dataIndex: 'ata_utc'
        },
        {
            title: 'Ship Name',
            dataIndex: 'ship_name'
        },
        {
            title: 'Ship Type',
            dataIndex: 'ship_type'
        },
        {
            title: 'Vessel Class',
            dataIndex: 'size_class'
        },
        {
            title: 'DWT',
            dataIndex: 'dwt'
        },
        {
            title: 'Move Type',
            dataIndex: 'move_type'
        },
        {
            title: 'Estimated Cargo Load (t)',
            dataIndex: 'total_cargo_load'
        },
        {
            title: 'Buyer',
            dataIndex: 'mill'
        },
        {
            title: 'Arrival Country',
            dataIndex: 'dest_country'
        },
        {
            title: 'Arrival Port',
            dataIndex: 'actual_dest'
        },
        {
            title: 'Arrival Berth',
            dataIndex: 'dest_berth'
        },
        {
            title: 'Supplier',
            dataIndex: 'company'
        },
        {
            title: 'Origin Country',
            dataIndex: 'country'
        },
        {
            title: 'Origin Port',
            dataIndex: 'previous_port_name'
        },
        {
            title: 'Origin Berth',
            dataIndex: 'dep_berth'
        },
        {
            title: 'Co Loading Port',
            dataIndex: 'co_load'
        },

    ]

    const Shipmentcolumns = [
        {
            title: 'Timestamp UTC',
            dataIndex: 'timestamp_utc'
        },
        {
            title: 'Ship Name',
            dataIndex: 'ship_name'
        },
        {
            title: 'Ship Type',
            dataIndex: 'ship_type'
        },
        {
            title: 'Vessel Class',
            dataIndex: 'size_class'
        },
        {
            title: 'DWT',
            dataIndex: 'dwt'
        },
        {
            title: 'Move Type',
            dataIndex: 'move_type'
        },
        {
            title: 'Estimated Cargo Load (t)',
            dataIndex: 'total_cargo_load'
        },
        {
            title: 'Supplier',
            dataIndex: 'company'
        },
        {
            title: 'Departure Country',
            dataIndex: 'country'
        },
        {
            title: 'Departure Port',
            dataIndex: 'port_name'
        },
        {
            title: 'Departure Berth',
            dataIndex: 'dep_berth'
        },
        {
            title: 'Co Loading Port',
            dataIndex: 'co_load'
        },
        {
            title: 'Destination Country',
            dataIndex: 'dest_country'
        },

        {
            title: 'Destination Port',
            dataIndex: 'destination'
        },

        {
            title: 'Reported ETA',
            dataIndex: 'reported_eta'
        },
        {
            title: 'Berthing Time',
            dataIndex: 'berthing_time'
        },
        {
            title: 'Loading Rate (t/hr)',
            dataIndex: 'loading_rate'
        }
    ]

    const Bargescolumns = [
        {
            title: 'Timestamp UTC',
            dataIndex: 'ata_utc'
        },
        {
            title: 'Ship Name',
            dataIndex: 'ship_name'
        },
        {
            title: 'Ship Type',
            dataIndex: 'ship_type'
        },
        {
            title: 'Move Type',
            dataIndex: 'move_type'
        },
        {
            title: 'Estimated Cargo Load (t)',
            dataIndex: 'estimated_cargo_load'
        },
        {
            title: 'Buyer',
            dataIndex: 'mill'
        },
        {
            title: 'Arrival Country',
            dataIndex: 'actual_dest_country'
        },
        {
            title: 'Arrival Port',
            dataIndex: 'actual_dest'
        },
        {
            title: 'Arrival Berth',
            dataIndex: 'dest_berth'
        },
        {
            title: 'Supplier',
            dataIndex: 'company'
        },
        {
            title: 'Origin Country',
            dataIndex: 'country'
        },
        {
            title: 'Origin Port',
            dataIndex: 'port_name'
        },
        {
            title: 'Origin Berth',
            dataIndex: 'dep_berth'
        },
        {
            title: 'Dep Timestamp UTC',
            dataIndex: 'atd_utc'
        },
        {
            title: 'Berthing Time',
            dataIndex: 'berthing_time'
        },

    ]
    const Intransitcolumns = [
        {
            title: 'Timestamp UTC',
            dataIndex: 'time_received'
        },
        {
            title: 'Ship Name',
            dataIndex: 'ship_name'
        },
        {
            title: 'Ship Type',
            dataIndex: 'ship_type'
        },
        {
            title: 'Vessel Class',
            dataIndex: 'size_class'
        },
        {
            title: 'DWT',
            dataIndex: 'dwt'
        },
        {
            title: 'Move Type',
            dataIndex: 'move_type'
        },
        {
            title: 'Estimated Cargo Load (t)',
            dataIndex: 'total_cargo_load'
        },
        {
            title: 'Supplier',
            dataIndex: 'company'
        },
        {
            title: 'Departure Country',
            dataIndex: 'country'
        },
        {
            title: 'Departure Port',
            dataIndex: 'port_name'
        },
        {
            title: 'Departure Berth',
            dataIndex: 'dep_berth'
        },
        {
            title: 'Co Loading Port',
            dataIndex: 'co_load'
        },
        {
            title: 'Destination Country',
            dataIndex: 'dest_country'
        },

        {
            title: 'Destination Port',
            dataIndex: 'destination'
        },
        {
            title: 'Reported ETA',
            dataIndex: 'reported_eta'
        },
        {
            title: 'departure_time',
            dataIndex: 'ATD UTC',
        },
        {
            title: 'Berthing Time',
            dataIndex: 'berthing_time'
        },
        {
            title: 'Loading Rate (t/hr)',
            dataIndex: 'loading_rate'
        }
    ]

    var typeColumns = Filters?.type == 'intransit' ? Intransitcolumns : Filters?.type == 'barges' ? Bargescolumns : Filters?.type == 'arrivals' ? comodity == "drihbi" ? DriHbiArrivalcolumns : comodity == "ironore" ? IronOreArrivalcolumns : Arrivalcolumns : Shipmentcolumns
    return typeColumns;
}
const highlightMarker = marker => {
    console.log(marker)
    if (highlightedMarker) {
        highlightedMarker.getElement().classList.remove('highlighted-marker')
    }
    highlightedMarker = marker
    marker.getElement().classList.add('highlighted-marker')
}

function calculateSpiderPositions(centerLngLat, numPoints, radius = 0.01) {
    var positions = numPoints?.map((item, index) => {
        var angle = (360 / numPoints?.length) * index
        var radian = (angle * Math.PI) / 180
        var x = centerLngLat[0] + radius * Math.cos(radian)
        var y = centerLngLat[1] + radius * Math.sin(radian)
        return { ...item, location: [x, y] }
    })

    return positions
}

export function haversineDistance(coord1, coord2) {
    const earthRadius = 6371; // Earth's radius in kilometers

    const toRadians = (degrees) => (degrees * Math.PI) / 180;
    const dLat = toRadians(coord2.lat - coord1.lat);
    const dLng = toRadians(coord2.lng - coord1.lng);

    // console.log(dLng,dLat)

    const a =
        Math.sin(dLat / 2) ** 2 +
        Math.cos(toRadians(coord1.lat)) *
        Math.cos(toRadians(coord2.lat)) *
        Math.sin(dLng / 2) ** 2;

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return earthRadius * c;
}

export const changeMapStyle = (style, type) => {
    // getCompaniesdata()

    // changeMapStyles(style) Need to update
    // setCurrentMap(type) Need to update
    if (mapdata) {
        const currentZoom = mapdata.getZoom()
        const currentCenter = mapdata.getCenter()
        mapdata.remove()
        const newMap = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: style,
            center: currentCenter,
            zoom: currentZoom,
            projection: 'mercator',
        })
        // setMap(newMap) Need to update
        // drawData(newMap) Need to update
    }
}

export const adjustColumnWidths = (tableId) => {
    // console.log(tableId)
    const table = document.querySelector(`#${tableId}`);
    const table2 = document.querySelector('.modaltable1')
    // console.log(table)
    // console.log(table2)
    if (table) {
        const headerColumns = table.querySelectorAll('thead th:nth-child(n+2)');
        const bodyColumns = table.querySelectorAll('tbody td:nth-child(n+2)');

        headerColumns?.forEach((headerCol, index) => {
            const headerColWidth = headerCol.offsetWidth;

            const bodyCol = bodyColumns[index];
            const bodyColWidth = bodyCol?.offsetWidth;

            const greaterWidth = Math.max(headerColWidth, bodyColWidth);
            // console.log(greaterWidth)

            headerCol.style.width = `${greaterWidth}px`;
            bodyCol.style.width = `${greaterWidth}px`;
        });
    }
};

const showDestinationPop = async (companydata, Filters, map, id, e, currentSelected = {}, showPath) => {

    console.log(currentSelected)
    if (Object.keys(currentSelected)?.length == 0) {
        return
    }
    localStorage.setItem('id', id)
    let currentCompany = {}
    const currentCompany1 = companydata?.find(item => item?.id == id)
    if (currentCompany1 != undefined) {
        currentCompany = currentCompany1
    }
    console.log(currentCompany)
    // #Add intransit check
    const found =
        (Filters?.type == 'arrivals' || Filters?.type == 'barges')
            ? `${currentCompany?.destination_long}-${currentCompany?.destination_lat}`
            : Filters?.type == 'intransit' ?
                `${currentCompany?.current_lon}-${currentCompany?.current_lat}`
                : `${currentCompany?.origin_long}-${currentCompany?.origin_lat}`
    let allCompanies = companydata?.filter(item => {
        let query =
            (Filters?.type == 'arrivals' || Filters?.type == 'barges')
                ? `${item?.destination_long}-${item?.destination_lat}`
                : Filters?.type == 'intransit' ?
                    `${item?.current_lon}-${item?.current_lat}`
                    : `${item?.origin_long}-${item?.origin_lat}`
        const mills = (Filters?.type == 'arrivals' || Filters?.type == 'barges') ? currentCompany?.mill?.trim() : currentCompany?.company?.trim()
        const mill2 = (Filters?.type == 'arrivals' || Filters?.type == 'barges') ? item?.mill?.trim() : item?.company?.trim()
        if (
            query == found &&
            mills == mill2
        ) {
            return item
        }
    })
    const element = document?.querySelectorAll(
        '.mapboxgl-popup-anchor-bottom'
    )

    console.log(element)
    if (element?.length > 0) {
        element?.forEach(item => {
            item?.remove()
        })
    }
    if (Filters.type !== 'intransit') {
        const popup = new mapboxgl.Popup({
            closeButton: false, // Show or hide the close button
            closeOnClick: true // Keep the popup open when the user clicks outside it
        })
        let url =
            (Filters?.type == 'arrivals' || Filters?.type == 'barges')
                ? `https://${currentCompany?.mill_logo}`
                : `https://${currentCompany?.company_logo}`
        popup.setHTML(
            `<div class="main-pop-container"><div class="popup-data"><img src=${url}></img><div><div class="arrow"></div><div>`
        )
        const feature = e?.features[0]
        const coordinates = feature.geometry.coordinates.slice()
        console.log(coordinates)
        localStorage.setItem('currentElement', coordinates)
        popup.setLngLat(coordinates).addTo(map)
        logoPopup = popup;
    }
    console.log(map?.getStyle())
    const mapStyle = map?.getStyle()
    const layer = mapStyle?.layers
    const source = mapStyle?.sources
    let layerIndex = layer?.findIndex(item => item?.id == "unclustered-point")
    layer?.map((item, index) => {
        if (index > layerIndex) {
            if (item?.id?.includes('route') || item?.id?.includes('line-dashed')) {
                map?.removeLayer(item?.id)
            }
        }
    })
    Object.keys(source)?.map((item, index) => {
        if (index > 1 && item?.includes('route')) {
            map?.removeSource(item)
        }
    })
    console.log(map?.getStyle())
    LayerPoint?.map(item => {
        if (map.getLayer(item)) {
            map.removeLayer(item)
        }
    })
    SourcePoint?.map(item => {
        if (map.getSource(item)) {
            map.removeSource(item)
        }
    })

    LayerPoint = []
    SourcePoint = []
    console.log(allCompanies)
    allCompanies = [...allCompanies, currentCompany]
    console.log("this is second")
    // showPath2(map,allCompanies)
    console.log(allCompanies.length)
    // setLoadingLimit(allCompanies.length) Need to update
    tableCompanies = {}
    if (Filters.type == 'intransit') {
        const popup = new mapboxgl.Popup({
            closeButton: false, // Show or hide the close button
            closeOnClick: true,// Keep the popup open when the user clicks outside it
            anchor: "left",
            offset: 170
        })
        const zoom = map.getZoom();
        if (zoom < 1.7)
            map.setZoom(1.7);
        map.flyTo({
            center: [currentCompany.current_lon, currentCompany.current_lat],
            essential: true,
            speed: 0.3
        });
        const popupNode = document.createElement('div');
        const root = createRoot(popupNode);
        root.render(<IntransitPopup companyRecord={currentCompany} />)
        popup.setDOMContent(popupNode)
        popup.setHTML(createTable(currentCompany))
        popup.setMaxWidth('370px')
        popup.addClassName('company-data-popup')
        // const points = moveCoordinate10MetersEast(map, currentCompany.current_lon, currentCompany.current_lat);
        popup.setLngLat([currentCompany.current_lon, currentCompany.current_lat]).addTo(map);

    } else {

        for (let index = 0; index < allCompanies.length; index++) {
            const element = allCompanies[index];
            LayerPoint.push(`route${index}`)
            LayerPoint.push(`line-dashed${index}`)
            SourcePoint.push(`route${index}`)
            SourcePoint.push(`line-dashed${index}`)
            await showPath(companydata, Filters, map, element?.id, index, allCompanies)

        }
        console.log("Route Dispalyed")
    }
}

function moveCoordinate10MetersEast(map, lng, lat) {
    // Create a point feature with the current coordinates
    var point = turf.point([lng, lat]);
    // Define the distance and bearing (90 degrees for east)
    let distance = 3500; // in meters
    const zoomL = map.getZoom();
    distance = zoomL < 1.5 ? 3500 : zoomL < 2 ? 3000 : zoomL < 2.5 ? 2500 : zoomL < 3 ? 1400 : zoomL < 3.5 ? 1200 : zoomL < 4 ? 800 : zoomL < 4.5 ? 600 : zoomL < 5 ? 400 : zoomL < 5.5 ? 300 : zoomL < 6 ? 290 : zoomL < 6.5 ? 250 : zoomL < 7 ? 80 : zoomL < 8 ? 40 : zoomL < 9 ? 20 : zoomL < 10 ? 10 : 2


    if (zoomL > 10) {
        distance = 40 / zoomL;
    }
    if (zoomL > 12) {
        distance = 5 / zoomL;
    }
    if (zoomL > 15) {
        distance = 2 / zoomL;
    }
    if (zoomL > 18) {
        distance = 0.3 / zoomL;
    }
    if (zoomL > 20) {
        distance = 0.1 / zoomL;
    }
    console.log('Zoom Level distance : ' + zoomL + ' ', distance)
    var bearing = 90; // 90 degrees for east
    var options = { units: 'miles' };
    // Use Turf.js to get the destination point
    var destination = turf.destination(point, distance, bearing, options);
    return destination.geometry.coordinates;
}

const intransitMarker = (currentCompany, e, map, companydata, Filters, showPath) => {
    let coordinates = [currentCompany?.current_lon, currentCompany?.current_lat]

    customMarker = document.createElement('div')
    customMarker.style.width = '50px'
    customMarker.style.height = '50px'
    customMarker.style.borderRadius = '50%'
    // const img = document.createElement('img');
    // img.src = gifImg
    const lottie = document.createElement('lottie-player');
    lottie.src = process.env.REACT_APP_FRONTEND_URL + '/AvXIrmMzDA.json';
    lottie.background = "transparent";
    lottie.style = "width: 50px; height: 50px;"
    lottie.speed = '1';
    lottie.loop = true;
    // lottie.controls = true;
    lottie.autoplay = true;
    customMarker.appendChild(lottie)
    console.log(coordinates)
    new mapboxgl.Marker(customMarker).setLngLat([currentCompany?.current_lon, currentCompany?.current_lat]).addTo(map)
    showDestinationPop(
        companydata,
        Filters,
        map,
        e?.features[0]?.properties?.internalNodeId,
        e,
        currentCompany,
        showPath
    )
    return
}

const drawaZoomLevel = (e, map, datapoint, id, Filters, companydata, showPath) => {
    // clearSpiderifiedCluster(map)
    console.log(e)
    let features = datapoint
    console.log(features)
    // console.log(e?.features[0])
    currentOpenSpiral = null
    if (customMarker != null) {
        customMarker?.remove()
    }
    markers?.map(item => {
        item?.remove()
    })
    // currentZoomLevel = map.getZoom() + 1
    markers = []
    console.log(currentLayer)
    let currentCompany = companydata?.find(item => item?.id == id)
    console.log(currentCompany)
    console.log(Filters?.type)
    let allFiltersCompany = [];
    // #Add intransit check
    if (Filters?.type == 'intransit') {
        allFiltersCompany = companydata?.filter(item => {
            const query = `${currentCompany?.current_lon}-${currentCompany?.current_lat}`
            const found = `${item?.current_lon}-${item?.current_lat}`
            const mills = currentCompany?.company?.trim()
            const mill2 = item?.company?.trim()
            if (
                query == found &&
                mills != mill2
            ) {
                return item
            }
        })
    } else {
        allFiltersCompany = companydata?.filter(item => {
            const query =
                (Filters?.type == 'arrivals' || Filters?.type == 'barges')
                    ? `${currentCompany?.destination_long}-${currentCompany?.destination_lat}`
                    : `${currentCompany?.origin_long}-${currentCompany?.origin_lat}`

            const found =
                (Filters?.type == 'arrivals' || Filters?.type == 'barges')
                    ? `${item?.destination_long}-${item?.destination_lat}`
                    : `${item?.origin_long}-${item?.origin_lat}`
            const mills = (Filters?.type == 'arrivals' || Filters?.type == 'barges') ? currentCompany?.mill?.trim() : currentCompany?.company?.trim()
            const mill2 = (Filters?.type == 'arrivals' || Filters?.type == 'barges') ? item?.mill?.trim() : item?.company?.trim()
            if (
                query == found &&
                mills != mill2
            ) {
                return item
            }
        })
    }
    let filterCompany = []
    let allCompanies = [];
    if (Filters?.type == 'intransit') {
        allCompanies = [currentCompany, ...allFiltersCompany]?.filter(
            item => {
                const mill2 = item?.company?.trim()
                const found = `${currentCompany?.current_lon}-${currentCompany?.current_lat
                    }-${mill2}`
                if (!filterCompany.includes(found)) {
                    filterCompany.push(found)
                    return item
                }
            }
        )
    }
    else {
        allCompanies = [currentCompany, ...allFiltersCompany]?.filter(
            item => {
                const mills = (Filters?.type == 'arrivals' || Filters?.type == 'barges') ? currentCompany?.mill?.trim() : currentCompany?.company?.trim()
                const mill2 = (Filters?.type == 'arrivals' || Filters?.type == 'barges') ? item?.mill?.trim() : item?.company?.trim()
                const found =
                    (Filters?.type == 'arrivals' || Filters?.type == 'barges')
                        ? `${currentCompany?.destination_long}-${currentCompany?.destination_lat
                        }-${mill2}`
                        : `${currentCompany?.origin_long}-${currentCompany?.origin_lat
                        }-${mill2}`
                if (!filterCompany.includes(found)) {
                    filterCompany.push(found)
                    return item
                }
            }
        )
    }
    console.log(currentLayer)
    currentLayer?.map(item => {
        if (map.getLayer(item)) {
            map.removeLayer(item)
        }
        if (map.getSource(item)) {
            map.removeSource(item)
        }
    })
    console.log(allCompanies)
    currentLayer = []

    if (allCompanies?.length <= 1) {
        if (Filters?.type == 'intransit') {
            return intransitMarker(currentCompany, e, map, companydata, Filters, showPath);
        } else {
            let coordinates =
                (Filters?.type == 'arrivals' || Filters?.type == 'barges')
                    ? [
                        currentCompany?.destination_long,
                        currentCompany?.destination_lat
                    ]
                    : [currentCompany?.origin_long, currentCompany?.origin_lat]
            customMarker = document.createElement('div')
            customMarker.style.width = '20px'
            customMarker.style.height = '20px'
            customMarker.style.borderRadius = '50%'
            customMarker.style.backgroundColor = 'red'
            customMarker.style.border = '3px solid #ffff'
            console.log(coordinates)
            new mapboxgl.Marker(customMarker).setLngLat(coordinates).addTo(map)
            showDestinationPop(
                companydata,
                Filters,
                map,
                e?.features[0]?.properties?.internalNodeId,
                e,
                currentCompany,
                showPath
            )
            return
        }
    }

    currentOpenSpiral = _.clone(e)
    let value = allCompanies?.length > 10 ? 6 : 5
    let radius = 0.9 / Math.pow(2, map.getZoom() - value)
    console.log(radius)

    var spiderPositions = calculateSpiderPositions(
        e?.features[0].geometry.coordinates,
        allCompanies,
        radius
    )

    if (map.getLayer('line0')) {
        map.removeLayer('line0')
    }
    if (map.getSource('line0')) {
        map.removeSource('line0')
    }
    console.log(spiderPositions)
    spiderPositions.forEach(function (location, index) {
        currentLayer.push(`line${index}`)
        var customMarker = document.createElement('div')
        customMarker.className = 'custom-marker'
        customMarker.textContent = ''
        const popup = new mapboxgl.Popup({
            closeButton: false, // Show or hide the close button
            closeOnClick: true,
            class: 'SinglePop' // Keep the popup open when the user clicks outside it
        })
        // map.setLayerZoomRange(`line${index}`, 10, Infinity)
        let url =
            (Filters?.type == 'arrivals' || Filters?.type == 'barges')
                ? `https://${location?.mill_logo}`
                : `https://${location?.company_logo}`
        popup.setHTML(
            `<div class="main-pop-container"><div class="popup-data"><img src=${url?.includes('https') || url?.includes('http')
                ? url
                : `https://${url}`
            }></img><div><div class="arrow"></div><div>`
        )
        let logoPopup = popup;

        let marker = new mapboxgl.Marker(customMarker)
            .setLngLat(location?.location)
            .setPopup(popup)
            .addTo(map)
        markers?.push(marker)
        marker.getElement().addEventListener('click', async function () {

            highlightMarker(marker);
            let SameCompany = []
            if (Filters?.type == 'intransit') {
                SameCompany = companydata?.filter(item => {
                    const mills = location?.company?.trim()
                    const mill2 = item?.company?.trim()
                    const query = `${location?.current_lon}-${location?.current_lat}-${mills}`
                    const found = `${item?.current_lon}-${item?.current_lat}-${mill2}`
                    if (query == found) {
                        return item
                    }
                })
            } else {
                SameCompany = companydata?.filter(item => {
                    const mills = (Filters?.type == 'arrivals' || Filters?.type == 'barges') ? location?.mill?.trim() : location?.company?.trim()
                    const mill2 = (Filters?.type == 'arrivals' || Filters?.type == 'barges') ? item?.mill?.trim() : item?.company?.trim()
                    const query =
                        (Filters?.type == 'arrivals' || Filters?.type == 'barges')
                            ? `${location?.destination_long}-${location?.destination_lat
                            }-${mills}`
                            : `${location?.origin_long}-${location?.origin_lat
                            }-${mills}`
                    const found =
                        (Filters?.type == 'arrivals' || Filters?.type == 'barges')
                            ? `${item?.destination_long}-${item?.destination_lat
                            }-${mill2}`
                            : `${item?.origin_long}-${item?.origin_lat
                            }-${mill2}`
                    if (query == found) {
                        return item
                    }
                })
            }
            console.log(SameCompany)
            // showDestinationPop(map, currentCompany?.id, location?.location)
            console.log(map?.getStyle())
            const mapStyle = map?.getStyle()
            const layer = mapStyle?.layers
            const source = mapStyle?.sources
            let layerIndex = layer?.findIndex(item => item?.id == "unclustered-point")
            layer?.map((item, index) => {
                if (index > layerIndex) {
                    if (item?.id?.includes('route') || item?.id?.includes('line-dashed')) {
                        map?.removeLayer(item?.id)
                    }
                }
            })
            Object.keys(source)?.map((item, index) => {
                if (index > 1 && item?.includes('route')) {
                    map?.removeSource(item)
                }
            })
            console.log(map?.getStyle())
            LayerPoint?.map(item => {
                if (map.getLayer(item)) {
                    map.removeLayer(item)
                }
            })
            SourcePoint?.map(item => {
                if (map.getSource(item)) {
                    map.removeSource(item)
                }
            })
            SameCompany = [...SameCompany, location]
            LayerPoint = []
            SourcePoint = []
            console.log("this is first")
            tableCompanies = {}

            for (let index = 0; index < SameCompany.length; index++) {
                const item = SameCompany[index];
                LayerPoint.push(`route${index}`)
                LayerPoint.push(`line-dashed${index}`)
                SourcePoint.push(`route${index}`)
                SourcePoint.push(`line-dashed${index}`)
                await showPath(companydata, Filters, map, item?.id, index, SameCompany)

            }
        })
        marker.getElement().addEventListener('mouseenter', function () {
            map.getCanvas().style.cursor = 'pointer'
            if (currentPopup) {
                currentPopup.remove();
            }
            if (logoPopup) {
                logoPopup.remove();
            }

            // const loc =[parseFloat(currentCompany.destination_long),parseFloat(currentCompany.destination_lat)]
            // console.log(loc)
            const locPixels = (map.project(location?.location))

            console.log(e)
            // Create a new popup with the company name
            const popup = new mapboxgl.Popup({
                closeButton: false, // Show or hide the close button
                closeOnClick: true,
                className: 'SinglePop', // Apply a custom CSS class
            })
                .setLngLat(location?.location)
                .setHTML(`<div class="main-pop-container"><div><div class="popup-data1"><h1>${(Filters?.type == 'arrivals' || Filters?.type == 'barges') ? location.mill : location.company}</h1><h3>${(Filters?.type == 'arrivals' || Filters?.type == 'barges') ? location.actual_dest : location.port_name}, ${Filters?.type == 'arrivals' ? globalComodity == 'ironore' ? location.actual_dest_country : location.dest_country : Filters?.type == 'barges' ? location.actual_dest_country : location.country}</h3></div><div><div class="arrow"></div><div>`)
                .addTo(map);

            const popupContainer = document.querySelector('.popup-data1');
            const h1Width = popupContainer.querySelector('h1').scrollWidth;
            const h3Width = popupContainer.querySelector('h3').scrollWidth;
            const totalWidth = Math.max(h1Width, h3Width) + 20; // Add padding
            console.log(totalWidth)

            // Set the width of the container
            popupContainer.style.width = totalWidth + 'px';

            const popupDiv = document.querySelector('.SinglePop');
            popupDiv.style.transform = `translate(-50%, -100%) translate(${locPixels.x}px, ${locPixels.y}px)`;

            currentPopup = popup;
        })
        marker.getElement().addEventListener('mouseleave', function () {
            map.getCanvas().style.cursor = ''
            if (currentPopup) {
                currentPopup.remove();
                currentPopup = null; // Clear the reference to the closed popup
            }
        })

        var coordinates = [e?.features[0].geometry.coordinates, location?.location]

        if (map.getLayer(`line${index}`)) {
            map.removeLayer(`line${index}`)
        }
        if (map.getSource(`line${index}`)) {
            map.removeSource(`line${index}`)
        }
        var line = {
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: coordinates
            }
        }
        console.log(`line${index}`)
        map.addSource(`line${index}`, {
            type: 'geojson',
            data: line
        })
        map.addLayer({
            id: `line${index}`,
            type: 'line',
            source: `line${index}`,
            layout: {
                'line-join': 'round',
                'line-cap': 'round'
                // visibility: 'visible',
                // 'z-index': 100
            },
            paint: {
                'line-color': '#b283ce',
                'line-width': 3
            }
        })
    })
}

const onZoom = (map, Filters, companydata, showPath) => {
    map.on('zoom', () => {
        const currentZoom = map.getZoom()

        console.log(currentZoom)
        console.log(currentZoomLevel)

        if (currentZoom < 1.27) {
            map.setZoom(1.27)
        }
        else {
            if (zoomLevel < currentZoom) {
                if (currentZoomLevel < currentZoom) {
                    if (currentOpenSpiral != null) {
                        drawaZoomLevel(
                            currentOpenSpiral,
                            map,
                            currentMapShow,
                            currentSpiralId,
                            Filters,
                            companydata,
                            showPath
                        )
                        currentZoomLevel = map.getZoom() + 1
                    }
                }
            } else {
                if (currentZoomLevel > currentZoom) {
                    if (currentOpenSpiral != null) {
                        drawaZoomLevel(
                            currentOpenSpiral,
                            map,
                            currentMapShow,
                            currentSpiralId,
                            Filters,
                            companydata,
                            showPath
                        )
                        currentZoomLevel = map.getZoom() - 1
                    }
                }
            }
        }
    })
}

const onClickCluster = (map, Filters) => {
    map.on('click', 'clusters', e => {
        localStorage.removeItem('id')
        localStorage.removeItem('currentElement')

        let features = map.queryRenderedFeatures(e.point, {
            layers: ['clusters']
        })

        let clusterId = features[0].properties.cluster_id
        map.getSource(SOURCE_EARTHQUAKE)
            .getClusterExpansionZoom(clusterId, (err, zoom) => {
                //console.log(clickedFeature.properties.id)
                if (err) return
                map.easeTo({
                    center: features[0].geometry.coordinates,
                    zoom: zoom
                })
            })
    }).on('click', e => {
        if (customMarker != null && Filters.type == 'intransit') { // if we remove marker we have to remove routes also
            customMarker?.remove()
        }

    }).on('zoomstart', () => { })
}
const onClickUnCluster = (map, Filters, companydata, showPath) => {
    // From https://docs.mapbox.com/mapbox-gl-js/example/cluster/

    // Start of Mapbox cluster example

    map.on('click', [...intransitLayers, 'unclustered-point'], e => {
        // currentZoomLevel = map?.getZoom() + 1
        let features = map.queryRenderedFeatures(e?.point, {
            layers: [...intransitLayers, 'unclustered-point']
        })
        zoomLevel = map?.getZoom()
        let id = e?.features[0]?.properties?.internalNodeId
        drawaZoomLevel(e, map, features, id, Filters, companydata, showPath)
        currentMapShow = features

        currentSpiralId = id
    })
}

const onMouseEnterUnCluster = (map, Filters, companydata, comodity) => {
    map.on(
        'mouseenter',
        [...intransitLayers, 'unclustered-point'],
        e => {
            map.getCanvas().style.cursor = 'pointer';
            // console.log(e)
            // console.log(currentOpenSpiral)
            let dist = 260
            if (currentOpenSpiral !== null)
                dist = haversineDistance(e.lngLat, currentOpenSpiral.lngLat)
            console.log(dist)
            if (dist > 250) {
                const id = e.features[0].properties.internalNodeId;
                const currentCompany = companydata.find(item => item.id == id);
                const allFiltersCompany = companydata?.filter(item => {
                    const query =
                        (Filters?.type == 'arrivals' || Filters?.type == 'barges')
                            ? `${currentCompany?.destination_long}-${currentCompany?.destination_lat}`
                            : (Filters?.type == 'intransit')
                                ? `${currentCompany?.current_lon}-${currentCompany?.current_lat}`
                                : `${currentCompany?.origin_long}-${currentCompany?.origin_lat}`

                    const found =
                        (Filters?.type == 'arrivals' || Filters?.type == 'barges')
                            ? `${item?.destination_long}-${item?.destination_lat}`
                            : (Filters?.type == 'intransit')
                                ? `${item?.current_lon}-${item?.current_lat}`
                                : `${item?.origin_long}-${item?.origin_lat}`

                    const mills = (Filters?.type == 'arrivals' || Filters?.type == 'barges') ? currentCompany?.mill?.trim() : currentCompany?.company?.trim()
                    const mill2 = (Filters?.type == 'arrivals' || Filters?.type == 'barges') ? item?.mill?.trim() : item?.company?.trim()
                    if (
                        query == found &&
                        mills != mill2
                    ) {
                        return item
                    }
                })
                let filterCompany = []
                let allCompanies = [currentCompany, ...allFiltersCompany]?.filter(

                    item => {
                        const mills = (Filters?.type == 'arrivals' || Filters?.type == 'barges') ? currentCompany?.mill?.trim() : currentCompany?.company?.trim()
                        const mill2 = (Filters?.type == 'arrivals' || Filters?.type == 'barges') ? item?.mill?.trim() : item?.company?.trim()
                        const found =
                            (Filters?.type == 'arrivals' || Filters?.type == 'barges')
                                ? `${currentCompany?.destination_long}-${currentCompany?.destination_lat
                                }-${mill2}`
                                : (Filters?.type == 'intransit')
                                    ? `${currentCompany?.current_lon}-${currentCompany?.current_lat
                                    }-${mill2}`
                                    : `${currentCompany?.origin_long}-${currentCompany?.origin_lat
                                    }-${mill2}`
                        if (!filterCompany.includes(found)) {
                            filterCompany.push(found)
                            return item
                        }
                    }
                )

                console.log(allCompanies)

                // Close the current popup if one is open
                if (currentPopup) {
                    currentPopup.remove();
                }
                console.log(logoPopup)
                if (logoPopup) {
                    logoPopup.remove();
                }
                console.log(e.features[0].geometry.coordinates)

                const locPixels = e.point;//(map.project(e.features[0].geometry.coordinates))
                const htmlElement = document.getElementsByClassName('company-data-popup')
                if (Filters.type == 'intransit') {
                    if (htmlElement.length > 0 && htmlElement[0].style.display !== 'none')
                        return;
                    const feature = e?.features[0]
                    ////Need to add layer here
                    if (map.getLayer('hover_uncluster_point')) {
                        map.removeLayer('hover_uncluster_point')
                    }
                    map.addLayer({
                        id: 'hover_uncluster_point',
                        type: 'circle',
                        interactive: true,
                        source: SOURCE_EARTHQUAKE,
                        filter: ['all', ['==', 'id', feature.properties.id]],
                        paint: {
                            'circle-color': 'transparent',
                            'circle-radius': 12,
                            'circle-stroke-color': '#fff',
                            'circle-stroke-width': 2
                        }
                    })

                    const popup = new mapboxgl.Popup({
                        closeButton: false, // Show or hide the close button
                        closeOnClick: true // Keep the popup open when the user clicks outside it
                    })
                    let url = `https://${currentCompany?.company_logo}`
                    if (currentCompany?.company_logo == "")
                        url = 'https://marine-company-logo.s3.ap-southeast-1.amazonaws.com/logo/Coy%20-%20Common%20User-logo-TFTIY1RKU2YE.png'

                    popup.setHTML(
                        `<div class="main-pop-container"><div class="popup-data"><img src=${url}></img><div><div class="arrow"></div><div>`
                    )

                    const coordinates = feature.geometry.coordinates.slice()
                    console.log(coordinates)
                    localStorage.setItem('currentElement', coordinates)
                    popup.setLngLat(coordinates).addTo(map)
                    currentPopup = popup;
                } else {
                    // Create a new popup with the company name
                    const popup = new mapboxgl.Popup({
                        closeButton: false, // Show or hide the close button
                        closeOnClick: true,
                        className: 'SinglePop', // Apply a custom CSS class
                    })
                        .setLngLat(e.features[0].geometry.coordinates)
                        .setHTML(`<div class="main-pop-container"><div><div class="popup-data1"><h1>${(Filters?.type == 'arrivals' || Filters?.type == 'barges') ? currentCompany.mill : currentCompany.company}</h1><h3>${(Filters?.type == 'arrivals' || Filters?.type == 'barges') ? currentCompany.actual_dest : currentCompany.port_name}, ${Filters?.type == 'arrivals' ? comodity == 'ironore' ? currentCompany.actual_dest_country : currentCompany.dest_country : Filters?.type == 'barges' ? currentCompany.actual_dest_country : currentCompany.country}</h3></div><div><div class="arrow"></div><div>`
                        )
                        .addTo(map);
                    const popupContainer = document.querySelector('.popup-data1');
                    const h1element = popupContainer.querySelector('h1')
                    if (allCompanies.length > 1) {
                        h1element.style.display = 'none';
                    }
                    const h1Width = popupContainer.querySelector('h1').scrollWidth;
                    const h3Width = popupContainer.querySelector('h3').scrollWidth;
                    const totalWidth = Math.max(h1Width, h3Width) + 20; // Add padding
                    console.log(totalWidth)
                    console.log(popup)

                    // Set the width of the container
                    popupContainer.style.width = totalWidth + 'px';

                    const popupDiv = document.querySelector('.SinglePop');
                    popupDiv.style.transform = `translate(-50%, -100%) translate(${locPixels.x}px, ${locPixels.y}px)`;

                    currentPopup = popup;
                }
            }
        }
    );
}

const onMouseLeaveUnCluster = map => {
    map.on(
        'mouseleave',
        [...intransitLayers, 'unclustered-point'],
        () => {
            map.getCanvas().style.cursor = '';

            // Remove the popup on mouse leave
            if (currentPopup) {
                currentPopup.remove();
                currentPopup = null; // Clear the reference to the closed popup
            }
            if (map.getLayer('hover_uncluster_point')) {
                // alert('I am layer data')
                map.removeLayer('hover_uncluster_point')
            }
        }
    );

}

const onMouseEnterCluster = map => {
    map.on(
        'mouseenter',
        'clusters',
        () => (map.getCanvas().style.cursor = 'pointer')
    )
}

const onMouseLeaveCluster = map => {
    map.on(
        'mouseleave',
        'clusters',
        () => (map.getCanvas().style.cursor = '')
    )
}

const addMarker = async (map, imageName, pointName, iconColor) => {

    map.addLayer({
        'id': pointName,
        'type': 'symbol',
        'interactive': true,
        'filter': ['all', ['==', 'iconColor', iconColor]],
        'source': SOURCE_EARTHQUAKE,

        'layout': {
            'icon-image': imageName,
            'icon-allow-overlap': true,
            'icon-rotate': ['get', 'rotation'],
            'icon-size': 0.8,
        }
    });

    map.addControl(
        new mapboxgl.ScaleControl({
            maxWidth: 80,
            unit: 'metric'
        })
    )
}

const addTomap = (map, features, url) => {
    for (const marker of features) {
        const el = document.createElement('div');
        const width = 23;
        const height = 23;
        el.className = 'marker';
        el.style.backgroundImage = url;
        el.style.width = `${width}px`;
        el.style.height = `${height}px`;
        el.style.backgroundSize = '100%';
        el.style.display = 'block';
        el.style.border = 'none';
        el.style.borderRadius = '50%';
        el.style.cursor = 'pointer';
        el.style.padding = 0;

        el.addEventListener('click', () => {
            window.alert("ok");
        });

        new mapboxgl.Marker(el)
            .setLngLat(marker.geometry.coordinates)
            .addTo(map);
    }
}
const addIntransitLayers = async (map, newFeature) => {
    const length = newFeature.length;
    maindata = {
        type: 'FeatureCollection',
        crs: {
            type: 'name',
            properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' }
        },
        features: newFeature
    }

    if (newFeature.length > 0) {
        const imageUrls = [
            handysize,
            handymax,
            mini,
            panamax,
            vloc,
            capesize
            // Add more image URLs here for additional icons
        ];

        map.addSource(SOURCE_EARTHQUAKE, {
            type: 'geojson',
            data: maindata,
            cluster: false,
            clusterMaxZoom: 30, // Max zoom to cluster points on
            clusterRadius: 30 // Radius of each cluster when clustering points (defaults to 50)
        })
        map.addLayer({
            id: 'clusters',
            type: 'circle',
            interactive: true,
            source: SOURCE_EARTHQUAKE,
            filter: ['has', 'point_count'],
            paint: {
                'circle-color': [
                    'step',
                    ['get', 'point_count'],
                    '#362554',
                    100,
                    '#362554',
                    750,
                    '#362554'
                ],
                'circle-radius': [
                    'step',
                    ['get', 'point_count'],
                    20,
                    100,
                    30,
                    750,
                    40
                ],
                'circle-stroke-color': '#fff',
                'circle-stroke-width': 2
            }
        })

        map.addLayer({
            id: 'cluster-count',
            type: 'symbol',
            source: SOURCE_EARTHQUAKE,
            filter: ['has', 'point_count'],
            layout: {
                'text-field': '{point_count_abbreviated}',
                'text-size': 20,
                'text-font': ['Open Sans Bold'],
                "text-allow-overlap": true
            },
            paint: {
                'text-color': 'white' // Change the color of the cluster count text
            }
        })

        const imagePromises = imageUrls.map((url) =>
            new Promise((resolve, reject) => {
                map.loadImage(url, (error, image) => {
                    if (error) reject(error);
                    resolve({ url, image });
                });
            })
        );
        Promise.all(imagePromises)
            .then((images) => {
                images.forEach((imageObj, i) => {
                    const { url, image } = imageObj; // Handymax, Panamax 
                    switch (i) {
                        case 0:
                            map.addImage('handysize', image);
                            addMarker(map, 'handysize', 'handysize', 'Handysize')
                            break;
                        case 1:
                            map.addImage('handymax', image);
                            addMarker(map, 'handymax', 'handymax', 'Handymax')
                            break;
                        case 2:
                            map.addImage('mini', image);
                            addMarker(map, 'mini', 'mini', 'Mini')
                            break;
                        case 3:
                            map.addImage('panamax', image);
                            addMarker(map, 'panamax', 'panamax', 'Panamax')
                            break;
                        case 4:
                            map.addImage('vloc', image);
                            addMarker(map, 'vloc', 'vloc', 'VLOC')
                            break;
                        case 5:
                            map.addImage('capesize', image);
                            addMarker(map, 'capesize', 'capesize', 'Capesize')
                            break;

                    }
                });


            })
    }
}

const addLayers = (map, newFeature) => {
    maindata = {
        type: 'FeatureCollection',
        crs: {
            type: 'name',
            properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' }
        },
        features: newFeature
    }
    map.addSource(SOURCE_EARTHQUAKE, {
        type: 'geojson',
        data: maindata,
        cluster: true,
        clusterMaxZoom: 30, // Max zoom to cluster points on
        clusterRadius: 30 // Radius of each cluster when clustering points (defaults to 50)
    })

    map.addLayer({
        id: 'clusters',
        type: 'circle',
        interactive: true,
        source: SOURCE_EARTHQUAKE,
        filter: ['has', 'point_count'],
        paint: {
            'circle-color': [
                'step',
                ['get', 'point_count'],
                '#362554',
                100,
                '#362554',
                750,
                '#362554'
            ],
            'circle-radius': [
                'step',
                ['get', 'point_count'],
                20,
                100,
                30,
                750,
                40
            ],
            'circle-stroke-color': '#fff',
            'circle-stroke-width': 2
        }
    })

    map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: SOURCE_EARTHQUAKE,
        filter: ['has', 'point_count'],
        layout: {
            'text-field': '{point_count_abbreviated}',
            'text-size': 20,
            'text-font': ['Open Sans Bold']
        },
        paint: {
            'text-color': 'white' // Change the color of the cluster count text
        }
    })

    map.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        interactive: true,
        source: SOURCE_EARTHQUAKE,
        filter: ['!', ['has', 'point_count']],
        paint: {
            'circle-color': '#675689',
            'circle-radius': 10,
            'circle-stroke-width': 1,
            'circle-stroke-color': '#fff'
        }
    })

    map.addControl(
        new mapboxgl.ScaleControl({
            maxWidth: 80,
            unit: 'metric'
        })
    )

}
const onLoad = (map, Filters, companydata, newFeature, showPath, comodity) => {
    // if (mapboaxLoaded == false) {
    map.on('load', () => {
        //console.log(map)
        // setMap(map)
        // setMapBox(true)
        globalComodity = comodity;
        onZoom(map, Filters, companydata, showPath);
        onClickCluster(map, Filters);
        onClickUnCluster(map, Filters, companydata, showPath);
        onMouseEnterUnCluster(map, Filters, companydata, comodity);
        onMouseLeaveUnCluster(map);
        Filters.type == 'intransit' ? addIntransitLayers(map, newFeature) : addLayers(map, newFeature);
        onMouseEnterCluster(map);
        onMouseLeaveCluster(map);
        // End of Mapbox cluster example
        let currenId = localStorage.getItem('id')
        let currentElement = localStorage.getItem('currentElement')
        if (currenId != undefined) {
            // showDestinationPopAuto(map, currenId, currentElement)
        }
    })
}

const getIntransitFeature = (Filters, companydata) => {
    let items = []
    console.log('Company data for intransit feature', companydata)
    let newFeature = companydata?.map(item => {
        let angle = 90;
        if (Filters?.type == 'intransit')
            angle = handleCalculateCog(item?.current_lat, item?.current_lon, item.sog, item.cog)
        if (items?.includes(`${item?.current_lon}-${item?.current_lat}`)) {
            return
        }
        items?.push(`${item?.current_lon}-${item?.current_lat}`)
        return {
            type: 'Feature',
            properties: {
                id: `${item?.current_lon}-${item?.current_lat}`,
                mag: 2.3,
                time: 1507425650893,
                felt: null,
                tsunami: 0,
                "rotation": angle,
                iconColor: item.size_class,
                internalNodeId: item?.id
            },
            geometry: {
                type: 'Point',
                coordinates: [item?.current_lon, item?.current_lat]
            }
        }
    })
    console.log(newFeature)
    newFeature = newFeature?.filter(item => item != null)
    console.log(newFeature)

    return newFeature;
}
const getFeatures = (Filters, companydata) => {
    let items = []
    let newFeature =
        (Filters?.type == 'arrivals' || Filters?.type == 'barges')
            ? companydata?.map(item => {
                if (
                    items?.includes(
                        `${item?.destination_long}-${item?.destination_lat}`
                    )
                ) {
                    return
                }
                items?.push(`${item?.destination_long}-${item?.destination_lat}`)
                return {
                    type: 'Feature',
                    properties: {
                        id: `${item?.destination_long}-${item?.destination_lat}`,
                        mag: 2.3,
                        time: 1507425650893,
                        felt: null,
                        tsunami: 0,

                        internalNodeId: item?.id
                    },
                    geometry: {
                        type: 'Point',
                        coordinates: [item?.destination_long, item?.destination_lat]
                    }
                }
            })
            : companydata?.map(item => {
                if (items?.includes(`${item?.origin_long}-${item?.origin_lat}`)) {
                    return
                }
                items?.push(`${item?.origin_long}-${item?.origin_lat}`)
                return {
                    type: 'Feature',
                    properties: {
                        id: `${item?.origin_long}-${item?.origin_lat}`,
                        mag: 2.3,
                        time: 1507425650893,
                        felt: null,
                        tsunami: 0,

                        internalNodeId: item?.id
                    },
                    geometry: {
                        type: 'Point',
                        coordinates: [item?.origin_long, item?.origin_lat]
                    }
                }
            })
    console.log(newFeature)
    newFeature = newFeature?.filter(item => item != null)
    console.log(newFeature)

    return newFeature;
}
const removeLayers = map => {
    //console.log(map)
    if (map.getLayer('route')) {
        // alert('I am layer data')
        map.removeLayer('route')
    }


    if (map.getSource('route')) {
        map.removeSource('route')
    }

    if (map.getLayer('handysize')) {
        // alert('I am layer data')
        map.removeLayer('handysize')
    }
    if (map.getLayer('handymax')) {
        // alert('I am layer data')
        map.removeLayer('handymax')
    }
    if (map.getLayer('mini')) {
        // alert('I am layer data')
        map.removeLayer('mini')
    }
    if (map.getLayer('panamax')) {
        // alert('I am layer data')
        map.removeLayer('panamax')
    }
    if (map.getLayer('vloc')) {
        // alert('I am layer data')
        map.removeLayer('vloc')
    }
    if (map.getLayer('capesize')) {
        // alert('I am layer data')
        map.removeLayer('capesize')
    }

    if (map.getLayer('clusters')) {
        // alert('I am layer data')
        map.removeLayer('clusters')
    }
    if (map.getLayer('cluster-count')) {
        // alert('I am layer data')
        map.removeLayer('cluster-count')
    }

    if (map.getLayer('unclustered-point')) {
        map.removeLayer('unclustered-point')
    }

    if (map.getSource(SOURCE_EARTHQUAKE)) {
        map.removeSource(SOURCE_EARTHQUAKE)
    }
}
export const drawData = (map, Filters, companydata, showPath, comodity) => {
    const mapElement = document?.getElementsByClassName(
        'mapboxgl-canvas-container'
    )
    console.log(mapElement)
    if (mapElement?.length > 1) {
        for (var i = 0; i < mapElement?.length - 1; i++) {
            mapElement?.[i]?.remove()
        }
    }
    const toggleButton = document.querySelector('.toggle-btn')
    const expandableRow = document.querySelector('.expandable-row')

    toggleButton?.addEventListener('click', function () {
        expandableRow.classList.toggle('show-additional')
    })

    let data_collection = {}
    console.log(companydata)
    companydata?.forEach?.((data, i) => {
        let key = `${data?.destination_lat}-${data?.destination_long}`
        if (data_collection.hasOwnProperty(key)) {
            data_collection[key] = [...data_collection[key], data]
        } else {
            data_collection[key] = [data]
        }
    })

    // To show map on same view that's why commented out. Atilla suggested this change 
    // if (companydata?.length > 0) {
    //     (Filters?.type == 'arrivals' || Filters?.type == 'barges')
    //         ? map.setCenter([
    //             companydata?.[0]?.destination_long,
    //             companydata?.[0]?.destination_lat
    //         ]) :
    //         (Filters?.type == 'intransit') ?
    //             map.setCenter([
    //                 companydata?.[0]?.current_lon,
    //                 companydata?.[0]?.current_lat
    //             ])
    //             : map.setCenter([
    //                 companydata?.[0]?.origin_long,
    //                 companydata?.[0]?.origin_lat
    //             ])
    // }

    const newFeature = Filters.type == 'intransit' ? getIntransitFeature(Filters, companydata) : getFeatures(Filters, companydata);
    removeLayers(map);
    onLoad(map, Filters, companydata, newFeature, showPath, comodity);
}

const calculateNewPosition = (lat, lon, distance, bearing) => {
    const R = 6371.0; // Earth's radius in kilometers
    const radLat = degreesToRadians(lat);
    const radLon = degreesToRadians(lon);
    const radBearing = degreesToRadians(bearing);

    const newLat = Math.asin(
        Math.sin(radLat) * Math.cos(distance / R) +
        Math.cos(radLat) * Math.sin(distance / R) * Math.cos(radBearing)
    );

    const newLon = radLon + Math.atan2(
        Math.sin(radBearing) * Math.sin(distance / R) * Math.cos(radLat),
        Math.cos(distance / R) - Math.sin(radLat) * Math.sin(newLat)
    );

    return {
        latitude: radiansToDegrees(newLat),
        longitude: radiansToDegrees(newLon),
    };
};

const degreesToRadians = (degrees) => {
    return degrees * Math.PI / 180;
};

const radiansToDegrees = (radians) => {
    return radians * 180 / Math.PI;
};

const handleCalculateCog = (latitude, longitude, speed, cog) => {
    // Convert speed to distance in kilometers (nautical miles to km: 1 nm = 1.852 km)
    const time = 1;
    const distance = speed * time * 1.852;
    const result = calculateNewPosition(latitude, longitude, distance, cog);
    // let bearing = turf.bearing(turf.point([latitude, longitude]), turf.point([result.latitude, result.longitude]));
    let bearing = turf.bearing(turf.point([longitude, latitude]), turf.point([result.longitude, result.latitude]));
    return bearing;
};

const intransitLableMapping = {
    "time_received": "Timestamp UTC",
    "ship_name": "Ship Name",
    "ship_type": "Ship Type",
    "size_class": "Vessel Class",
    "dwt": "DWT",
    "move_type": "Move Type",
    "total_cargo_load": "Estimated Cargo Load (t)",
    "company": "Supplier",
    "country": "Departure Country",
    "port_name": "Departure Port",
    "dep_berth": "Departure Berth",
    "co_load": "Co Loading Port",
    'expected_buyer': 'Expected Buyer',
    "dest_country": "Destination Country",
    "destination": "Destination Port",
    "berthing_time": "Berthing Time",
    "loading_rate": "Loading Rate (t/hr)",
}

const creatDataList = (currentCompany) => {
    let dataList = ''
    Object.keys(intransitLableMapping).forEach((key, id) => {

        let value = currentCompany[key];

        if (key == 'dwt' || key == 'total_cargo_load')
            value = parseInt(value, 10).toLocaleString();
        if (!value)
            value = '-'

        const data =
            key == 'expected_buyer'
                ? `<div ${id % 2 == 0 ? 'class="container data-row"' : 'class="container data-row1" '}    key={${id}}>
                                <div class="left-col">${intransitLableMapping[key]}</div>
                                <div id="intransit-expected_buyer" style='width:auto' class="right-col"></div>
                            </div>`

                : `<div ${id % 2 == 0 ? 'class="container data-row"' : 'class="container data-row1" '}    key={${id}}>
                                <div class="left-col">${intransitLableMapping[key]}</div>
                                <div class="right-col">${value}</div>
                            </div>`

        dataList = dataList + data;
    }
    )
    return dataList;
}


export const createTable = (currentCompany) => {
    const title = currentCompany.ship_name; //"NALINEE NAREE (General Cargo)";
    const iconLink = currentCompany.company_logo == "" ? 'marine-company-logo.s3.ap-southeast-1.amazonaws.com/logo/Coy%20-%20Common%20User-logo-TFTIY1RKU2YE.png' : currentCompany.company_logo;
    const departureCountry = currentCompany.country;
    const destinationCountry = currentCompany.dest_country;

    const departureFlag = departureCountry == 'Gibraltar' ? `${process.env.REACT_APP_FRONTEND_URL}/gibraltar.png`
        : `https://hatscripts.github.io/circle-flags/flags/${countryCodes[departureCountry].toLowerCase()}.svg`;
    const destinationFlag = destinationCountry == 'Gibraltar' ? `${process.env.REACT_APP_FRONTEND_URL}/gibraltar.png`
        : `https://hatscripts.github.io/circle-flags/flags/${countryCodes[destinationCountry].toLowerCase()}.svg`;

    const d = ` <div class="modal-dialog" >
                <div class="dialog-title">
                <p class="p-title">${title}</p>
                <img  class="b-close" onclick="document.getElementsByClassName('company-data-popup')[0].style.display = 'none'" src="${cross}"}></button>
                </div>
                <div class="company-icon">
                <img src="https://${iconLink}" />
                </div>
                <div  class="direaction-area">
                    <div class="ship-direction" style="padding-right:13px; padding-left:13px; padding-top:5px">
                        <div class="ship-left-col">
                            <p class="direction-title">DEPARTURE</P>
                            
                             <div class="flag-style">
                             <img src="${departureFlag}" width="48">
                             </div>
                            <p class="country-name">${departureCountry}</p>
                        </div>
                        <div class="ship-direction-icon">
                         <img src="${process.env.REACT_APP_FRONTEND_URL}/direction.svg" />
                        </div>
                        <div class="ship-right-col">
                            <p class="direction-title">DESTINATION</P>
                         <div class="flag-style">
                          <img src="${destinationFlag}" width="48">
                         </div>
                            <p class="country-name">${destinationCountry}</p>
                        </div>
                    </div>
                    <div class="atd-eta">
                        <div class="atd-eta-section" style="padding-left:12px">
                            <p class="atd-eta-label">ATD:&nbsp;</p>
                            <p class="atd-eta-date">${currentCompany.departure_time ?? '-'}</p>
                        </div>
                        <div class="atd-eta-section" style="${currentCompany.reported_eta ? "padding-right:17px" : "padding-right:80px"} " >
                            <p class="atd-eta-label">ETA:&nbsp;</p>
                            <p class="atd-eta-date">${currentCompany.reported_eta ?? '-'}</p>
                        </div>
                    </div>
                </div>

                <div class="ship-data"> 
                 ${creatDataList(currentCompany)}
                </div>
            </div>`
    return d;
}