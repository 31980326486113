import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";

const GooglePieChart = ({ record, options, data, data2 }) => {

    const [flexDir, setFlexDir] = useState('center');
    useEffect(() => {
        if (data) {
            if (data.length > 7)
                setFlexDir('start');
            else
                setFlexDir('center');
        }
    }, [data])

    function generateLightGrayVariants(index) {
        const minGray = 150; // Start from a light gray
        const maxGray = 230;
        const stepSize = Math.floor((maxGray - minGray) / (data.length - 1)); // Increment for each shade

        const color = record?.colour == 'red' ? "#CB3C36" : record?.colour == 'green' ? "#3CCC7E" : record?.colour == 'orange' ? "#FFB248" : "";


        const grayValue = minGray + index * stepSize; // Calculate gray intensity
        const limitedGrayValue = Math.min(grayValue, maxGray);
        const hexGray = `#${limitedGrayValue.toString(16).padStart(2, '0').repeat(3)}`; // Convert to hex
        if (data[index].type == record?.expected_buyer) {
            return color
        } else
            return hexGray


    }

    return (

        <div style={{ display: 'flex' }}>
            {/* Chart */}
            <div style={{ width: "60%" }}>
                <Chart
                    chartType="PieChart"
                    width="100%"
                    height="300px"
                    data={data2}
                    options={options}
                />
            </div>

            {/* Custom Legend */}
            <div
                style={{
                    width: "40%",
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: flexDir,
                    overflowY: 'auto',
                    maxHeight: '270px',
                    marginTop: '20px',
                    marginBottom: '20px',
                    marginLeft: '7px'
                }}
            >
                {data2.slice(1).map(([label], index) => (
                    <div
                        key={label}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "10px",
                            cursor: "pointer",
                        }}
                    >
                        <div
                            style={{
                                width: "12px",
                                height: "12px",
                                backgroundColor: generateLightGrayVariants(index), //`hsl(${(index / data.length) * 360}, 70%, 50%)`,
                                marginRight: "10px",
                                borderRadius: "50%",
                            }}
                        ></div>



                        <span style={{ color: '#424141', fontFamily: 'Inter', fontSize: '12px', fontWeight: 500, width: '150px' }}>{label}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default GooglePieChart;
