import React, { useEffect, useState } from 'react'
import mapboxgl from 'mapbox-gl'
import './style.css'
import { Button, message, Radio, Modal, Table } from 'antd'
import { filterRoutesIds, getRouteData, getRouteDataByIds, updateDataToDB } from '../../Pages/Company/company-reducers'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { Spin } from 'antd'
import { createTable, drawData, getColumns } from './mapUtils'
import { Legend } from './mapBox/legend'
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
mapboxgl.accessToken =
  'pk.eyJ1IjoiY2FzcGlhbmdyb3VwIiwiYSI6ImNpcWt1cnp0MzAwMmRpM21iN2hqbXZ3b2MifQ.s3g4iqhjULtckf5ZdjE9yg'
let alldatatemp = []
const Map = React.memo(
  ({
    showDrawer,
    showChartsDrawer,
    companydata,
    getCompaniesdata,
    mapType,
    changeMapStyles,
    currentMap,
    setCurrentMap,
    projection,
    setProjections,
    Filters,
    loader,
    setLoader,
    done,
    setDone,
    comodity,
    legends,
    addLegend,
    removeLegend
  }) => {
    // const {data,isPending,error}=UseFetchAxios('/smelter-map-data?type=steel','get');
    const SOURCE_EARTHQUAKE = 'earthquakes'
    const [mapdata, setMap] = useState(null)
    const [style, setStyle] = useState(mapType)
    const [project, setProjection] = useState(projection)
    const [mapboaxLoaded, setMapBox] = useState(false)
    const [modalVisible, setModalVisible] = useState(false);
    const [popupContent, setPopupContent] = useState(null);
    const [loadingLimit, setLoadingLimit] = useState(0)
    const [nodeloader, setNodeLoader] = useState(false);


    const company = useSelector(state => state) // Spiderify after zoom N, zoom otherwise

    console.log(mapType)

    const [messageApi, contextHolder] = message.useMessage()
    const mapContainerRef = React.useRef(null)
    const btngroupRef = React.useRef(null)
    const [tableId, setTableId] = useState(null)
    let tableCompanies = {}

    var typeColumns = getColumns(Filters, comodity);

    useEffect(() => {
      // window?.location?.href?.includes('/company') ? 'app-container' : ''
      const element = document?.getElementById('App-container')
      if (element) {
        element.classList.add('app-container')
      }
      return () => {
        if (element) {
          element.classList.remove('app-container')
        }
      }
    }, [])

    useEffect(() => {
      ; () => {
        mapdata?.remove()
      }
    })

    useEffect(() => {
      setProjection(projection)
    }, [projection])

    useEffect(() => {
      if (mapdata != null) {
        mapdata?.setStyle(mapType)
        drawData(mapdata, Filters, companydata, showPath, comodity)
      }
    }, [companydata, project])

    useEffect(() => {
      if (mapdata != null) {
        mapdata?.setStyle(mapType)

      }
    }, [mapType])

    useEffect(() => {
      if (mapdata == null || done == false) {
        let map = new mapboxgl.Map({
          container: mapContainerRef.current,
          projection: 'mercator',
          style: style,
          center: Filters?.type == 'barges' ? [10.452764000000002, 51.1657065] : [-8.841969, 38.49458],
          zoom: Filters?.type == 'barges' ? 5 : 1.27
        })

        if (mapdata == null) {
          drawData(map, Filters, companydata, showPath, comodity)
          setMap(map)
        }
        else {
          drawData(mapdata, Filters, companydata, showPath, comodity)
        }
        if (companydata.length > 0) {
          setDone(true)
          setLoader(false)
        }
      }
    }, [companydata])


    function haversineDistance(coord1, coord2) {
      const earthRadius = 6371; // Earth's radius in kilometers

      const toRadians = (degrees) => (degrees * Math.PI) / 180;
      const dLat = toRadians(coord2.lat - coord1.lat);
      const dLng = toRadians(coord2.lng - coord1.lng);

      // console.log(dLng,dLat)

      const a =
        Math.sin(dLat / 2) ** 2 +
        Math.cos(toRadians(coord1.lat)) *
        Math.cos(toRadians(coord2.lat)) *
        Math.sin(dLng / 2) ** 2;

      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      return earthRadius * c;
    }

    const showPath = async (companydata, Filters, map, id, index, companiesdata) => {
      setNodeLoader(true);
      if (index == 0) {
        const unique_route_ids = [...new Set(companiesdata?.filter(item => item?.unique_route_id)?.map(item => item.unique_route_id))];

        const filteredIds = filterRoutesIds(unique_route_ids);
        console.log("unique_route_ids", filteredIds)

        const allDbData = filteredIds.length > 0 ? await getRouteDataByIds(filteredIds) : [];

        allDbData.length > 0 && await updateDataToDB(allDbData);
        tableCompanies = {};
        alldatatemp = [];
      }
      const company = companydata?.find(item => item?.id == id)
      const currentCompanyid = company?.unique_route_id
      const comapnyroute = getRouteData(currentCompanyid)
      let currentCompanyRoute;
      if (comapnyroute && comapnyroute.length > 0)
        currentCompanyRoute = comapnyroute[0];

      let data = currentCompanyRoute?.coords?.map(item => {
        return [item?.longitude, item?.latitude]
      })
      console.log(data)
      const tempcompanyData = {
        company: company,
        coordinates: data,
      };
      tableCompanies[index] = tempcompanyData
      currentCompanyRoute = null
      console.log("End of Data")
      const geojson = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {},
            geometry: {
              // coordinates: Filters?.type != 'arrivals' ? data.reverse() : data,
              coordinates: data,
              type: 'LineString'
            }
          }
        ]
      }
      data = null
      map.addSource(`route${index}`, {
        type: 'geojson',
        lineMetrics: true,
        data: geojson
      })
      map.addLayer({
        id: `route${index}`,
        type: 'line',
        source: `route${index}`,

        paint: {
          'line-width': 20,
          'line-gradient': [
            'interpolate',
            ['linear'],
            ['line-progress'],
            0,
            'blue',
            0.1,
            'royalblue',
            0.3,
            'cyan',
            0.5,
            'lime',
            0.7,
            'yellow',
            1,
            'red'
          ],
          'line-width': 6,
          'line-opacity': 0.4
        }
      })

      // let endColor = Filters?.type == 'arrivals' ? 'red' : 'blue'
      // let startColor = Filters?.type == 'arrivals' ? 'blue' : 'red'

      map.addLayer({
        type: 'line',
        source: `route${index}`,
        id: `line-dashed${index}`,
        paint: {
          // 'line-color': 'yellow',
          'line-width': 20,
          'line-gradient': [
            'interpolate',
            ['linear'],
            ['line-progress'],
            0,
            'blue',
            0.1,
            'royalblue',
            0.3,
            'cyan',
            0.5,
            'lime',
            0.7,
            'yellow',
            1,
            'red'
          ],
          'line-width': 6,
          'line-dasharray': [0, 4, 3]
        }
      })


      const dashArraySequence = [
        [0, 4, 3],
        [0.5, 4, 2.5],
        [1, 4, 2],
        [1.5, 4, 1.5],
        [2, 4, 1],
        [2.5, 4, 0.5],
        [3, 4, 0],
        [0, 0.5, 3, 3.5],
        [0, 1, 3, 3],
        [0, 1.5, 3, 2.5],
        [0, 2, 3, 2],
        [0, 2.5, 3, 1.5],
        [0, 3, 3, 1],
        [0, 3.5, 3, 0.5]
      ]

      // let step = 0
      // function animateDashArray (timestamp) {
      //   const newStep = parseInt((timestamp / 125) % dashArraySequence.length)

      //   if (newStep !== step) {
      //     map.setPaintProperty(
      //       `line-dashed${index}`,
      //       'line-dasharray',
      //       dashArraySequence[step]
      //     )
      //     step = newStep
      //   }
      //   requestAnimationFrame(animateDashArray)
      // }
      // if (map.getLayer(`line-dashed${index}`)) {
      //   animateDashArray(0)
      // }
      var popupshow = null

      map.on('click', `route${index}`, e => {
        const element = document?.querySelectorAll(
          '.mapboxgl-popup-anchor-bottom'
        )
        // let SameCompany = companydata?.filter(item => {
        //   const query =
        //     Filters?.type == 'arrivals'
        //       ? `${company?.destination_long}-${company?.destination_lat}`
        //       : `${company?.origin_long}-${company?.origin_lat}`
        //   const found =
        //     Filters?.type == 'arrivals'
        //       ? `${item?.destination_long}-${item?.destination_lat}`
        //       : `${item?.origin_long}-${item?.origin_lat}`
        //       const mills = Filters?.type == 'arrivals' ? company?.mill?.trim() :company?.company?.trim()
        //     const mill2 = Filters?.type == 'arrivals' ? item?.mill?.trim() :item?.company?.trim() 
        //   if (
        //     query == found &&
        //     mills == mill2
        //   ) {
        //     return item
        //   }
        // })

        console.log(element)
        if (element?.length > 0) {
          element?.forEach(item => {
            item?.remove()
          })
        }

        if (popupshow) {
          popupshow.remove() // Close the current popup
          popupshow = null
          return // Return to prevent opening another popup
        }
        // alert('I am Awas Niaz')
        const coordinates = e.lngLat
        let datalist = ''
        typeColumns?.map(item => {
          if (company[item?.dataIndex] == null) {
            return
          }
          datalist =
            datalist +
            `<div class="datalist"><p>${item?.title}:</p><b><p>${company[item?.dataIndex]
            }</p></b></div>`
        })
        // popupshow = new mapboxgl.Popup({
        //   closeButton: SameCompany?.length > 0 ? false : true,
        //   closeOnClick: true
        // })

        // console.log(SameCompany)
        // if(Filters?.type == 'arrivals')
        // {
        //   SameCompany=SameCompany.map((item) => {
        //     const dwt = parseInt(item.dwt, 10).toLocaleString();
        //     const total_cargo_load= parseInt(item.total_cargo_load, 10).toLocaleString();

        //     return { ...item, dwt,total_cargo_load };
        //   })
        // }
        // else{
        //   SameCompany=SameCompany.map((item) => {
        //     const dwt = parseInt(item.dwt, 10).toLocaleString();
        //     const total_cargo_load= parseInt(item["Estimated Cargo Load (t)"], 10).toLocaleString();

        //     return { ...item, dwt,total_cargo_load };
        //   })
        // } 

        let headerTitle =
          Filters?.type == 'barges'
            ? {
              'Timestamp UTC': 'ata_utc',
              Vessel: 'ship_name',
              'Est Cargo Load': 'estimated_cargo_load',
              'Buyer': 'mill',
              'Arrival Country': 'actual_dest_country',
              'Supplier': 'company',
              'Origin Country': 'country'
            } :
            Filters?.type == 'arrivals'
              ? {
                'Timestamp UTC': 'ata_utc',
                Vessel: 'ship_name',
                'Est Cargo Load': comodity == 'ironore' ? 'arr_total_cargo_load' : 'total_cargo_load',
                'Buyer': 'mill',
                'Country': comodity == 'ironore' ? 'actual_dest_country' : 'dest_country',
                'Supplier': 'company',
                'Origin Country': 'country'
              }
              : {
                'Timestamp UTC': 'timestamp_utc',
                Vessel: 'ship_name',
                'Est Cargo Load': 'total_cargo_load',
                'Supplier': 'company',
                'Country': 'country',
                'Dest Port': 'destination',
                'Dest Country': 'dest_country'
              }

        let title = `<tr><th></th>`
        let rowList = ``
        // let newcomp=[company]
        // console.log(company)
        // console.log(index)
        // console.log(e.lngLat)
        // console.log(tableCompanies)

        // console.log(map.getLayer(`route${index}`))
        // console.log(map.getSource(`route${index}`))
        // const x=map.queryRenderedFeatures([-85.85424109801191,25.879157343300463], {
        //   layers: ['route0','route1','route2'],
        // })
        // console.log(x)

        let uniqueCompanyIDs = [];
        let uniquecomps = [];
        let closestCompany = null;
        let closestDistance = Infinity;
        let foundCloseCompany = false;

        for (const key in tableCompanies) {
          const companyData = tableCompanies[key];
          const companyID = companyData.company.id;


          for (const coordinates of companyData.coordinates) {
            const companyCoords = {
              lng: parseFloat(coordinates[0]),
              lat: parseFloat(coordinates[1]),
            };

            const distance = haversineDistance(e.lngLat, companyCoords);

            let distanceThreshold = 50;
            if (Filters?.type == 'barges')
              distanceThreshold = 2;

            if (distance < distanceThreshold) {
              if (!uniqueCompanyIDs.includes(companyID)) {
                uniquecomps.push(companyData.company);
                uniqueCompanyIDs.push(companyID);
              }
              foundCloseCompany = true;
              break; // No need to check other coordinates for the same company
            }

            // Check for the closest company when no company is within the threshold
            if (distance < closestDistance) {
              closestDistance = distance;
              closestCompany = companyData.company;
            }
          }
        }
        if (!foundCloseCompany && closestCompany) {
          uniquecomps.push(closestCompany);
          uniqueCompanyIDs.push(closestCompany.id);
        }

        console.log(uniqueCompanyIDs);
        console.log(uniquecomps)

        if (Filters?.type == 'arrivals') {
          uniquecomps = uniquecomps.map((item) => {
            const dwt = parseInt(item.dwt, 10).toLocaleString();
            const total_cargo_load = parseInt(item.total_cargo_load, 10).toLocaleString();
            const arr_total_cargo_load = parseInt(item.arr_total_cargo_load, 10).toLocaleString();

            return { ...item, dwt, total_cargo_load, arr_total_cargo_load };
          })
        } else if (Filters?.type == 'barges') {
          uniquecomps = uniquecomps.map((item) => {
            const estimated_cargo_load = parseInt(item.estimated_cargo_load, 10).toLocaleString();

            return { ...item, estimated_cargo_load };
          })
        }
        else {
          uniquecomps = uniquecomps.map((item) => {
            const dwt = parseInt(item.dwt, 10).toLocaleString();
            let total_cargo_load = parseInt(item["Estimated Cargo Load (t)"], 10).toLocaleString();
            if (total_cargo_load == "NaN")
              total_cargo_load = parseInt(item["total_cargo_load"]).toLocaleString();
            return { ...item, dwt, total_cargo_load };
          })
        }

        uniquecomps?.map((item, index) => {
          rowList =
            rowList +
            `<tr class="collapsed" id="collapsed-row-${index}" onclick="const expandedRow = document.getElementById('expanded-row-${index}');
            if (expandedRow.style.display === 'table-row') {
              expandedRow.style.display = 'none';
            } else {
              const allExpandedRows = document.querySelectorAll('.expanded-row');
              allExpandedRows.forEach(row => {
                row.style.display = 'none';
              });
              expandedRow.style.display = 'table-row';
            }
            const collapsedRow = document.getElementById('collapsed-row-${index}');
            if (collapsedRow.classList.contains('expanded')) {
              collapsedRow.classList.remove('expanded');
            } else {
              collapsedRow.classList.add('expanded');
            }
            "
            ><td></td>`
          Object.keys(headerTitle).map(key => {
            let currentKey = headerTitle[key]
            rowList = rowList + `<td>${item[currentKey]}</td>`
          })
          rowList = rowList + `<td class="rowarrow">&#x2304;</td></tr>`

          // Additional row for expanded content
          rowList =
            rowList +
            `<tr class="expanded-row" id="expanded-row-${index}" style="display: none;"><td  colspan="9"><div class="detail-show">`
          typeColumns?.map(i => {
            if (item[i?.dataIndex] == null) {
              return
            }
            rowList =
              rowList +
              `<div class="company-data"><span class="righttitle"><b>${i?.title
              }:   </b> </span><span>      </span><span>${item[i?.dataIndex]
              }</span></div>`
          })
          rowList = rowList + `</div></td></tr>`
        })

        Object.keys(headerTitle).map(item => {
          title = title + `<th>${item}</th>`
        })
        title = title + `<th></th></tr>`
        const uniqueNumberString = new Date().getTime().toString().slice(-6);
        setTableId('table' + uniqueNumberString)
        var newPopup = `<div class="secondcontainer"><table>${title}${rowList}</table></div>`
        setPopupContent(`<table class="modaltable1" id="table${uniqueNumberString}"><thead>${title}</thead><tbody class="modal-tbody">${rowList}</tbody></table>`)

        var popupContent = `<div class="popcontainer" ><div class="header"><h5>
       ${company?.company}
        </h5><button onclick="function close(){var popup = document.querySelector('.mapboxgl-popup-close-button')
        console.log(popup)
        if (popup) {
          popup?.click()
        }}()"
        class="close-btn"></button></div><div class="datacontainer">
        ${datalist}
        </div></div>`
        // Set the popup coordinates and content and add it to the map.
        let popuptemplate = newPopup
        // popupshow.setLngLat(coordinates).setHTML(popuptemplate).addTo(map)
        setModalVisible(true)

        const elementdata = document?.querySelectorAll(
          '.mapboxgl-popup-anchor-top'
        )

        // console.log(elementdata)
        if (elementdata?.length > 1) {
          elementdata?.forEach((item, index) => {
            if (index != 0) {
              item?.remove()
            }
          })
        }
      })
      map.on('mouseenter', `route${index}`, function () {
        map.getCanvas().style.cursor = 'pointer' // Change cursor style
      })
      map.on('mouseleave', `route${index}`, function () {
        map.getCanvas().style.cursor = '' // Reset cursor style
      })
      const element = document?.querySelectorAll(
        '.mapboxgl-popup-anchor-bottom'
      )
      console.log(loadingLimit)
      setLoadingLimit(prev => prev - 1)

      console.log(element)
      alldatatemp = []
      setNodeLoader(false);
      return null
    }

    const changeMapStyle = (style, type) => {
      // getCompaniesdata()

      changeMapStyles(style)
      setCurrentMap(type)
      if (mapdata) {
        const currentZoom = mapdata.getZoom()
        const currentCenter = mapdata.getCenter()
        mapdata.remove()
        const newMap = new mapboxgl.Map({
          container: mapContainerRef.current,
          style: style,
          center: currentCenter,
          zoom: currentZoom,
          projection: 'mercator',
        })
        setMap(newMap)
        drawData(newMap, Filters, companydata, showPath, comodity)
      }
    }

    useEffect(() => {
      if (modalVisible)
        adjustColumnWidths();
    }, [modalVisible]);

    const adjustColumnWidths = () => {
      // console.log(tableId)
      const table = document.querySelector(`#${tableId}`);
      const table2 = document.querySelector('.modaltable1')
      // console.log(table)
      // console.log(table2)
      if (table) {
        const headerColumns = table.querySelectorAll('thead th:nth-child(n+2)');
        const bodyColumns = table.querySelectorAll('tbody td:nth-child(n+2)');

        headerColumns?.forEach((headerCol, index) => {
          const headerColWidth = headerCol.offsetWidth;

          const bodyCol = bodyColumns[index];
          const bodyColWidth = bodyCol?.offsetWidth;

          const greaterWidth = Math.max(headerColWidth, bodyColWidth);
          // console.log(greaterWidth)

          headerCol.style.width = `${greaterWidth}px`;
          bodyCol.style.width = `${greaterWidth}px`;
        });
      }
    };


    useEffect(() => {
      const observer = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
          if (mutation.target.classList.contains('modal-body') && mutation.addedNodes.length > 0) {
            // When modal-body is populated, call your function
            console.log("first")
            adjustColumnWidths();
          }
        }
      });

      const modalBodyElement = document.querySelector('.modal-body');
      if (modalBodyElement) {
        observer.observe(modalBodyElement, { childList: true });
      }

      return () => {
        observer.disconnect();
      };
    }, [popupContent]);

    const closeModal = () => {
      setModalVisible(false)
      setPopupContent(null)
      setTableId(null)
    }

    return (
      <>
        {(!loader && (loadingLimit > 0 || !done)) || (nodeloader) &&
          <div className='map-loading-overlay'>
            <Spin tip='' size='large' className='routespin' style={{ zIndex: '99' }}>
              <div className='content' />
            </Spin>
          </div>}
        {contextHolder}
        <div className='map-container' id='company-map' ref={mapContainerRef}>
          <Button
            className='absolute top-2/4 z-10 transform -rotate-90 -left-14 pt-3 h-auto font-medium'
            type='primary'
            onClick={showDrawer}
          >
            More Filters
            <DownOutlined />
          </Button>
          {Filters.type !== 'intransit' && <Button
            className='absolute top-2/4 z-10 transform -rotate-90 -right-14 pb-3 h-auto font-medium'
            type='primary'
            onClick={showChartsDrawer}
          >
            View Charts
            <UpOutlined />
          </Button>}

          <Radio.Group
            id='menu'
            ref={btngroupRef}
            defaultValue={currentMap}
            buttonStyle='solid'
            className='custom-map-radio-group absolute w-full flex justify-center bottom-8 z-10'
          >
            <Radio.Button
              id='outdoors-v12'
              value='outdoors'
              className='map-radio-btn'
              onClick={() => {
                // mapdata.setStyle(
                //   'mapbox://styles/caspiangroup/cljpfar92003e01qo2jpfc83b'
                // )
                changeMapStyle(
                  'mapbox://styles/caspiangroup/cljpfar92003e01qo2jpfc83b',
                  'outdoors'
                )
                // drawData()
              }}
            >
              Outdoors
            </Radio.Button>
            <Radio.Button
              id='streets-v12'
              value='streets'
              className='map-radio-btn'
              onClick={() => {
                // mapdata.setStyle('mapbox://styles/mapbox/streets-v12')
                changeMapStyle('mapbox://styles/mapbox/streets-v12', 'streets')
                // drawData()
              }}
            >
              Streets
            </Radio.Button>
            <Radio.Button
              id='satellite-streets-v12'
              value='satelite'
              className='map-radio-btn'
              onClick={() => {
                // mapdata.setStyle('mapbox://styles/mapbox/satellite-streets-v12')
                changeMapStyle(
                  'mapbox://styles/mapbox/satellite-streets-v12',
                  'satelite'
                )
                // drawData()
              }}
            >
              Satellite
            </Radio.Button>
          </Radio.Group>
        </div>
        <div id='map'></div>

        <Modal centered open={modalVisible} className="secondcontainer" footer={null} onCancel={closeModal}>
          <div className='modal-body' dangerouslySetInnerHTML={{ __html: popupContent }}>
          </div>
        </Modal>
        {Filters.type == 'intransit' && !loader ? <Legend legends={legends} addLegend={addLegend} removeLegend={removeLegend} /> : null}
      </>
    )
  }
)

export default Map
