import React, { useState, useEffect, useContext } from 'react'
import { Pagination } from 'antd'
import { ProTable } from '@ant-design/pro-components'
import { Button } from 'antd'
import { ReactComponent as FilterIcon } from '../../Assets/images/filter-icon.svg'
import { ReactComponent as DownloadIcon } from '../../Assets/images/fixtures/Downloadicon.svg'
import './fixtures.css'
import axios from '../../axiosconfiguration/axios'
import UserContext from '../../store/UserContext'

import { useNavigate } from 'react-router-dom'

import { exportToExcel } from '../../utilities/SaveAsCsv'
import { EditOutlined } from '@ant-design/icons'
import PopoverDialog from '../Misc/PopoverDialog'
import ChartPopOverDialog from '../Misc/ChartPopOverDialog'

let columns = []
const Arrivalcolumns = [
  {
    title: <p className='text-xs font-bold'>Timestamp UTC</p>,
    dataIndex: 'ata_utc'
  },
  {
    title: <p className='text-xs font-bold'>Ship Name</p>,
    dataIndex: 'ship_name'
  },
  {
    title: <p className='text-xs font-bold'>Ship Type</p>,
    dataIndex: 'ship_type'
  },
  {
    title: <p className='text-xs font-bold'>Vessel Class</p>,
    dataIndex: 'size_class'
  },
  {
    title: <p className='text-xs font-bold'>DWT</p>,
    dataIndex: 'dwt'
  },
  {
    title: <p className='text-xs font-bold'>Move Type</p>,
    dataIndex: 'move_type'
  },
  {
    title: <p className='text-xs font-bold'>Estimated Cargo Load (t)</p>,
    dataIndex: 'total_cargo_load'
  },
  {
    title: <p className='text-xs font-bold'>Buyer</p>,
    dataIndex: 'mill'
  },
  {
    title: <p className='text-xs font-bold'>Arrival Country</p>,
    dataIndex: 'dest_country'
  },
  {
    title: <p className='text-xs font-bold'>Arrival Port</p>,
    dataIndex: 'actual_dest'
  },
  {
    title: <p className='text-xs font-bold'>Arrival Berth</p>,
    dataIndex: 'dest_berth'
  },

  {
    title: <p className='text-xs font-bold'>Supplier</p>,
    dataIndex: 'company'
  },
  {
    title: <p className='text-xs font-bold'>Origin Country</p>,
    dataIndex: 'country'
  },
  {
    title: <p className='text-xs font-bold'>Origin Port</p>,
    dataIndex: 'previous_port_name'
  },
  {
    title: <p className='text-xs font-bold'>Origin Berth</p>,
    dataIndex: 'dep_berth'
  },
  {
    title: <p className='text-xs font-bold'>Co Loading Port</p>,
    dataIndex: 'co_load'
  }
]
const Shipmentcolumns = [
  {
    title: <p className='text-xs font-bold'>Timestamp UTC</p>,
    dataIndex: 'timestamp_utc'
  },
  {
    title: <p className='text-xs font-bold'>Ship Name</p>,
    dataIndex: 'ship_name'
  },
  {
    title: <p className='text-xs font-bold'>Ship Type</p>,
    dataIndex: 'ship_type'
  },
  {
    title: <p className='text-xs font-bold'>Vessel Class</p>,
    dataIndex: 'size_class'
  },
  {
    title: <p className='text-xs font-bold'>DWT</p>,
    dataIndex: 'dwt'
  },
  {
    title: <p className='text-xs font-bold'>Move Type</p>,
    dataIndex: 'move_type'
  },
  {
    title: <p className='text-xs font-bold'>Estimated Cargo Load (t)</p>,
    dataIndex: 'total_cargo_load'
  },
  {
    title: <p className='text-xs font-bold'>Supplier</p>,
    dataIndex: 'company'
  },
  {
    title: <p className='text-xs font-bold'>Departure Country</p>,
    dataIndex: 'country'
  },
  {
    title: <p className='text-xs font-bold'>Departure Port</p>,
    dataIndex: 'port_name'
  },
  {
    title: <p className='text-xs font-bold'>Departure Berth</p>,
    dataIndex: 'dep_berth'
  },
  {
    title: <p className='text-xs font-bold'>Co Loading Port</p>,
    dataIndex: 'co_load'
  },

  {
    title: <p className='text-xs font-bold'>Destination Country</p>,
    dataIndex: 'dest_country'
  },
  {
    title: <p className='text-xs font-bold'>Destination Port</p>,
    dataIndex: 'destination'
  },
  {
    title: <p className='text-xs font-bold'>Reported ETA</p>,
    dataIndex: 'reported_eta'
  },
  {
    title: <p className='text-xs font-bold'>Berthing Time</p>,
    dataIndex: 'berthing_time'
  },
  {
    title: <p className='text-xs font-bold'>Loading Rate (t/hr)</p>,
    dataIndex: 'loading_rate'
  }
]

const Loadingcolumns = [
  {
    title: <p className='text-xs font-bold'>Arrival Time</p>,
    dataIndex: 'timestamp_utc'
  },
  {
    title: <p className='text-xs font-bold'>Ship Name</p>,
    dataIndex: 'ship_name'
  },
  {
    title: <p className='text-xs font-bold'>Ship Type</p>,
    dataIndex: 'ship_type'
  },
  {
    title: <p className='text-xs font-bold'>Vessel Class</p>,
    dataIndex: 'size_class'
  },
  {
    title: <p className='text-xs font-bold'>DWT</p>,
    dataIndex: 'dwt'
  },
  {
    title: <p className='text-xs font-bold'>Move Type</p>,
    dataIndex: 'move_type'
  },
  {
    title: <p className='text-xs font-bold'>Supplier</p>,
    dataIndex: 'company',
    render: (text, record) => (
      <PopoverDialog record={record} content={`Probability of Loading Ferrous Scrap: ${record.confidence_rate ? record.confidence_rate + "%" : '-'}`} />
    ),
  },
  {
    title: <p className='text-xs font-bold'>Load Country</p>,
    dataIndex: 'country'
  },
  {
    title: <p className='text-xs font-bold'>Load Port</p>,
    dataIndex: 'port_name'
  },

  {
    title: <p className='text-xs font-bold'>Load Berth</p>,
    dataIndex: 'load_berth'
  },
  {
    title: <p className='text-xs font-bold'>Co Loading Port</p>,
    dataIndex: 'co_load'
  },

  {
    title: <p className='text-xs font-bold'>Berthing Time</p>,
    dataIndex: 'berthing_time'
  },
  {
    title: <p className='text-xs font-bold'>Prev Country</p>,
    dataIndex: 'previous_country'
  },
  {
    title: <p className='text-xs font-bold'>Prev Port</p>,
    dataIndex: 'previous_port_name'
  },
  {
    title: <p className='text-xs font-bold'>Prev Leg Time</p>,
    dataIndex: 'previous_leg_time'
  }
]
const Bargescolumns = [
  {
    title: <p className='text-xs font-bold'>Timestamp UTC</p>,
    dataIndex: 'ata_utc'
  },
  {
    title: <p className='text-xs font-bold'>Ship Name</p>,
    dataIndex: 'ship_name'
  },
  {
    title: <p className='text-xs font-bold'>Ship Type</p>,
    dataIndex: 'ship_type'
  },
  {
    title: <p className='text-xs font-bold'>Move Type</p>,
    dataIndex: 'move_type'
  },
  {
    title: <p className='text-xs font-bold'>Estimated Cargo Load (t)</p>,
    dataIndex: 'estimated_cargo_load'
  },
  {
    title: <p className='text-xs font-bold'>Buyer</p>,
    dataIndex: 'mill'
  },
  {
    title: <p className='text-xs font-bold'>Arrival Country</p>,
    dataIndex: 'actual_dest_country'
  },
  {
    title: <p className='text-xs font-bold'>Arrival Port</p>,
    dataIndex: 'actual_dest'
  },
  {
    title: <p className='text-xs font-bold'>Arrival Berth</p>,
    dataIndex: 'dest_berth'
  },

  {
    title: <p className='text-xs font-bold'>Supplier</p>,
    dataIndex: 'company'
  },
  {
    title: <p className='text-xs font-bold'>Origin Country</p>,
    dataIndex: 'country'
  },
  {
    title: <p className='text-xs font-bold'>Origin Port</p>,
    dataIndex: 'port_name'
  },
  {
    title: <p className='text-xs font-bold'>Origin Berth</p>,
    dataIndex: 'dep_berth'
  },
  {
    title: <p className='text-xs font-bold'>Dep Timestamp UTC</p>,
    dataIndex: 'atd_utc'
  },
  {
    title: <p className='text-xs font-bold'>Berthing Time</p>,
    dataIndex: 'berthing_time'
  }
]
const Intransitcolumns = [
  {
    title: <p className='text-xs font-bold'>Timestamp UTC</p>,
    dataIndex: 'time_received'
  },
  {
    title: <p className='text-xs font-bold'>Ship Name</p>,
    dataIndex: 'ship_name'
  },
  {
    title: <p className='text-xs font-bold'>Ship Type</p>,
    dataIndex: 'ship_type'
  },
  {
    title: <p className='text-xs font-bold'>Vessel Class</p>,
    dataIndex: 'size_class'
  },
  {
    title: <p className='text-xs font-bold'>DWT</p>,
    dataIndex: 'dwt'
  },
  {
    title: <p className='text-xs font-bold'>Move Type</p>,
    dataIndex: 'move_type'
  },
  {
    title: <p className='text-xs font-bold'>Estimated Cargo Load (t)</p>,
    dataIndex: 'total_cargo_load'
  },
  {
    title: <p className='text-xs font-bold'>Supplier</p>,
    dataIndex: 'company'
  },
  {
    title: <p className='text-xs font-bold'>Departure Country</p>,
    dataIndex: 'country'
  },
  {
    title: <p className='text-xs font-bold'>Departure Port</p>,
    dataIndex: 'port_name'
  },
  {
    title: <p className='text-xs font-bold'>Departure Berth</p>,
    dataIndex: 'dep_berth'
  },
  {
    title: <p className='text-xs font-bold'>Co Loading Port</p>,
    dataIndex: 'co_load'
  },
  {
    title: <p className='text-xs font-bold'>Expected Buyer</p>,
    dataIndex: 'expected_buyer',
    render: (text, record) => {
      return <ChartPopOverDialog key={record.id} record={record} />
    },
  },
  {
    title: <p className='text-xs font-bold'>Destination Country</p>,
    dataIndex: 'dest_country'
  },
  {
    title: <p className='text-xs font-bold'>Destination Port</p>,
    dataIndex: 'destination'
  },
  {
    title: <p className='text-xs font-bold'>ATD UTC</p>,
    dataIndex: 'departure_time'
  },
  {
    title: <p className='text-xs font-bold'>Reported ETA</p>,
    dataIndex: 'reported_eta'
  },
  {
    title: <p className='text-xs font-bold'>Berthing Time</p>,
    dataIndex: 'berthing_time'
  },
  {
    title: <p className='text-xs font-bold'>Loading Rate (t/hr)</p>,
    dataIndex: 'loading_rate'
  }
]

const DataTable = ({
  showDrawer,
  dataSource,
  dataSourceType,
  setDataSourceType,
  appliedFilter,
  setAppliedFilter,
  trailversion,
  current_page,
  pageSizes,
  totalPage,
  getfixturesDatas,
  getfixturesFilterDatas,
  loadings,
  setLoadings,
  arrivcount,
  loadingCount,
  bargesCount,
  shipcount,
  intransitcount,
  shipmentFilterUpdated,
  setFilterApply,
  applyFilter,
  totalEntries,
  setSetShipmentFiltersdata,
  setPageSize1,
  defaultArrivCount,
  defaultShipCount,
  defaultIntransitcount,
  defaultLoadingCount,
  defaultBargesCount,
  defaultShipmentFilter,
  defaultArrivalFilter,
  defaultLoadingFilter,
  defaultBargesFilter,
  defaultIntransitFilter,
  bargesAccessLevel
}) => {
  const [loading, setLoading] = useState(loadings)
  const [dataSource1, setDataSource1] = useState([])
  const [currentPage, setCurrentPage] = useState(current_page)
  const [Total, setTotalPage] = useState(totalPage)
  const [pageSize, setPageSize] = useState(pageSizes)
  const [filterFields, setFields] = useState([])
  const [totalData, setTotaldata] = useState(0)
  const [colOrder, setColOrder] = useState({})
  const [url, setUrl] = useState(
    `/shipData/fixtures/arrival-data-in-chunks?limit=${pageSize}`
  )
  const [clickable, setIsClickable] = useState(false)
  const [toggle, setToggle] = useState(false)
  const [actuallyDone, setActDone] = useState(false)
  const navigate = useNavigate()
  const {
    userState: { isAuth },
    setUserState
  } = useContext(UserContext)

  const arrivalKeyMapping = {
    ata_utc: 'Timestamp UTC',
    ship_name: 'Ship Name',
    ship_type: 'Ship Type',
    size_class: 'Vessel Class',
    dwt: 'DWT',
    move_type: 'Move Type',
    total_cargo_load: 'Estimated Cargo Load (t)',
    mill: 'Buyer',
    dest_country: 'Arrival Country',
    actual_dest: 'Arrival Port',
    dest_berth: 'Arrival Berth',
    company: 'Supplier',
    country: 'Origin Country',
    previous_port_name: 'Origin Port',
    dep_berth: 'Origin Berth',
    co_load: 'Co Loading Port',
  };

  const shipmentKeyMapping = {
    timestamp_utc: 'Timestamp UTC',
    ship_name: 'Ship Name',
    ship_type: 'Ship Type',
    size_class: 'Vessel Class',
    dwt: 'DWT',
    move_type: 'Move Type',
    total_cargo_load: 'Estimated Cargo Load (t)',
    company: 'Supplier',
    country: 'Departure Country',
    port_name: 'Departure Port',
    dep_berth: 'Departure Berth',
    co_load: 'Co Loading Port',
    dest_country: 'Destination Country',
    destination: 'Destination Port',
    reported_eta: 'Reported ETA',
    berthing_time: 'Berthing Time',
    loading_rate: 'Loading Rate (t/hr)',
  };

  const liveLoadingKeyMapping = {
    timestamp_utc: 'Arrival Time',
    ship_name: 'Ship Name',
    ship_type: 'Ship Type',
    size_class: 'Vessel Class',
    dwt: 'DWT',
    move_type: 'Move Type',
    company: 'Supplier',
    country: 'Load Country',
    port_name: 'Load Port',
    load_berth: 'Load Berth',
    co_load: 'Co Loading Port',
    berthing_time: 'Berthing Time',
    previous_country: 'Prev Country',
    previous_port_name: 'Prev Port',
    previous_leg_time: 'Prev Leg Time',
  };

  const bargesKeyMapping = {
    ata_utc: 'Timestamp UTC',
    ship_name: 'Ship Name',
    ship_type: 'Ship Type',
    move_type: 'Move Type',
    estimated_cargo_load: 'Estimated Cargo Load (t)',
    mill: 'Buyer',
    actual_dest_country: 'Arrival Country',
    actual_dest: 'Arrival Port',
    dest_berth: 'Arrival Berth',
    company: 'Supplier',
    country: 'Origin Country',
    port_name: 'Origin Port',
    dep_berth: 'Origin Berth',
    atd_utc: 'Dep Timestamp UTC',
    berthing_time: 'Berthing Time',
  };

  const intransitKeyMapping = {
    time_received: 'Timestamp UTC',
    ship_name: 'Ship Name',
    ship_type: 'Ship Type',
    size_class: 'Vessel Class',
    dwt: 'DWT',
    move_type: 'Move Type',
    total_cargo_load: 'Estimated Cargo Load (t)',
    company: 'Supplier',
    country: 'Departure Country',
    port_name: 'Departure Port',
    dep_berth: 'Departure Berth',
    co_load: 'Co Loading Port',
    expected_buyer: 'Expected Buyer',
    dest_country: 'Destination Country',
    destination: 'Destination Port',
    reported_eta: 'Reported ETA',
    departure_time: 'ATD UTC',
    berthing_time: 'Berthing Time',
    loading_rate: 'Loading Rate (t/hr)',
  };

  useEffect(() => {
    const tableWrapper = document.querySelector('.ant-table-wrapper');
    console.log(tableWrapper)
    if (tableWrapper) {
      tableWrapper.scrollTop = 0;
    }
  }, [dataSource, dataSource1]);

  useEffect(() => {
    console.log(dataSourceType)
    if (dataSourceType == 'arrival') {
      columns = Arrivalcolumns
    }
    else if (dataSourceType == 'loading') {
      columns = Loadingcolumns
    }
    else if (dataSourceType == 'departure') {
      columns = Shipmentcolumns
    } else if (dataSourceType == 'barges') {
      columns = Bargescolumns
    }
    else if (dataSourceType == 'intransit') {
      columns = Intransitcolumns
    }

  }, [dataSourceType])

  const transformDataSource = (data) => {
    return data?.map((item) => {
      const dwt = parseInt(item.dwt, 10).toLocaleString();
      const total_cargo_load = item.total_cargo_load == null ? '-' : parseInt(item.total_cargo_load, 10).toLocaleString();
      const estimated_cargo_load = item.estimated_cargo_load == null ? '-' : parseInt(item.estimated_cargo_load, 10).toLocaleString();

      return { ...item, dwt, total_cargo_load, estimated_cargo_load };
    });
  };

  useEffect(() => {

    setDataSource1(transformDataSource(dataSource));
  }, [dataSource]);

  useEffect(() => {
    let setting_btn = document?.getElementsByClassName(
      'ant-pro-table-list-toolbar-setting-items'
    )
    if (setting_btn?.length > 0) {
      let clickableDiv = setting_btn[0]

      clickableDiv.addEventListener('click', function (event) {

        console.log(clickableDiv)
        let setting = document?.getElementsByClassName('anticon-setting')
        // Check if the click was within the original element or the extended area
        if (event.clientX > clickableDiv.getBoundingClientRect().right) {
          // Action to perform on extended area click
          console.log('Extended area clicked')
          setting[0]?.click()
        } else {
          // Action to perform on original element click
          console.log('Original element clicked')
          if (clickable) {
            setting[0]?.click()
            setIsClickable(false)
          }
          else
            setIsClickable(true)
        }
        if (!actuallyDone)
          setToggle(prev => !prev)
      })
    }
  }, [])

  useEffect(() => {
    if (!actuallyDone) {
      // setTimeout(() => {
      // setToggle(prev=>!prev)
      // }, 1000);
      const popoverTitle = document.querySelector('.ant-popover-title');

      if (popoverTitle) {
        const existingPEl = popoverTitle.querySelector('.custom-p-element');

        if (!existingPEl) {
          const pElement = document.createElement('p');
          pElement.textContent = 'Drag columns to rearrange';

          pElement.className = 'custom-p-element';

          popoverTitle.appendChild(pElement);
          setActDone(true)
        }
      }
    }
  }, [toggle])

  useEffect(() => {
    setCurrentPage(current_page)
    setTotalPage(totalPage)
    setPageSize(pageSizes)
    setLoading(loadings)
  }, [current_page, pageSizes, totalPage, loadings])

  // const getfixturesData = async url => {
  //   setLoading(true)
  //   try {
  //     const response = await axios.get(url, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem('token')}`
  //       }
  //     })

  //     if (
  //       response?.data
  //       // && response?.data?.status!='failed'
  //     ) {
  //       console.log('fixtureResponse', response?.data?.meta)
  //       console.log(response?.data?.data)
  //       const data = response?.data?.data?.map(item => {
  //         let newdate = item?.ata_utc?.replace('-', '/')
  //         let date = new Date(newdate)
  //         let day =
  //           date?.getDate() < 10 ? `0${date?.getDate()}` : date?.getDate()
  //         let month =
  //           date?.getMonth() < 10 ? `0${date?.getMonth()}` : date?.getMonth()
  //         let year = date?.getFullYear()
  //         return {
  //           ...item,
  //           ata_utc: `${day}-${month}-${year}`
  //         }
  //       })
  //       setDataSource(data)
  //       setCurrentPage(response?.data?.meta?.current_page)
  //       setTotalPage(response?.data?.meta?.total)
  //       setLoading(false)
  //     } else {
  //       // alert(response?.data?.message);
  //       // setUserState({isAuth:false });
  //     }
  //   } catch (err) {
  //     alert(err)
  //   }
  // }

  // const downloaddata = () => {
  //   var url = null
  //   if (applyFilter == false) {
  //     if (dataSourceType == 'arrival') {
  //       url = `/shipData/fixtures/arrival-data-in-chunks?limit=${totalEntries}`
  //     } else {
  //       url = `/shipData/fixtures/shipment-data-in-chunks?limit=${totalEntries}`
  //     }
  //     axios
  //       .get(url, {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem('token')}`
  //         }
  //       })
  //       .then(data => {
  //         console.log(data)
  //         const filteredObj = data?.data?.data?.map(item => {
  //           filterFields.forEach(key => {
  //             delete item[key]
  //           })
  //           return item
  //         })
  //         const tempdata=transformDataSource(filteredObj)
  //         console.log(tempdata)
  //         exportToExcel(tempdata)
  //       })
  //       .catch(err => {
  //         console.log(err)
  //       })
  //   } else {
  //     let filters = null
  //     if (dataSourceType == 'arrival') {
  //       filters = shipmentFilterUpdated
  //       url = `/shipData/fixtures/filter/arrivals?limit=${totalEntries}`
  //     } else {
  //       filters = shipmentFilterUpdated
  //       url = `/shipData/fixtures/filter/shipments?limit=${totalEntries}`
  //     }

  //     try {
  //       axios
  //         .post(url, filters, {
  //           headers: {
  //             Authorization: `Bearer ${localStorage.getItem('token')}`
  //           }
  //         })
  //         .then(data => {
  //           console.log(data)
  //           const filteredObj = data?.data?.data?.map(item => {
  //             filterFields.forEach(key => {
  //               delete item[key]
  //             })
  //             return item
  //           })
  //           console.log(filteredObj)
  //           const tempdata=transformDataSource(filteredObj)
  //           console.log(filteredObj)
  //           exportToExcel(tempdata)
  //           console.log(data)
  //         })
  //         .catch(err => {
  //           console.log(err)
  //         })
  //     } catch (err) {}
  //   }
  // }

  const arrivalColumnOrder = [
    'ata_utc', 'ship_name', 'ship_type', 'size_class', 'dwt', 'move_type', 'total_cargo_load',
    'mill', 'dest_country', 'actual_dest', 'dest_berth', 'company', 'country',
    'previous_port_name', 'dep_berth', 'co_load'
  ];

  const shipmentColumnOrder = [
    'timestamp_utc', 'ship_name', 'ship_type', 'size_class', 'dwt', 'move_type', 'total_cargo_load',
    'company', 'country', 'port_name', 'dep_berth', 'co_load', 'dest_country',
    'destination', 'reported_eta', 'berthing_time', 'loading_rate'
  ];

  const loadingColumnOrder = [
    'timestamp_utc', 'ship_name', 'ship_type', 'size_class', 'dwt', 'move_type',
    'company', 'country', 'port_name', 'load_berth', 'co_load', 'berthing_time',
    'previous_country', 'previous_port_name', 'previous_leg_time'
  ];

  const bargesColumnOrder = [
    'ata_utc', 'ship_name', 'ship_type', 'move_type', 'estimated_cargo_load',
    'mill', 'actual_dest_country', 'actual_dest', 'dest_berth', 'company', 'country',
    'port_name', 'dep_berth', 'atd_utc', 'berthing_time'
  ];

  const intransitColumnOrder = [
    'time_received', 'ship_name', 'ship_type', 'size_class', 'dwt', 'move_type', 'total_cargo_load',
    'company', 'country', 'port_name', 'dep_berth', 'co_load', 'expected_buyer', 'dest_country',
    'destination', "departure_time", 'reported_eta', 'berthing_time', 'loading_rate'
  ];
  function mapKeys(data) {
    const keyMapping = dataSourceType === 'arrival' ? arrivalKeyMapping : dataSourceType === 'loading' ? liveLoadingKeyMapping : dataSourceType === 'departure' ? shipmentKeyMapping : dataSourceType === 'barges' ? bargesKeyMapping : intransitKeyMapping;

    return data.map(item => {
      const mappedItem = {};
      for (const key in item) {
        if (keyMapping.hasOwnProperty(key) && item.hasOwnProperty(key)) {
          mappedItem[keyMapping[key]] = item[key];
        }
      }
      return mappedItem;
    });
  }

  const downloaddata = async (dataa) => {
    setLoadings(true)
    let orderedColumns = [];
    if (dataSourceType === 'arrival') {
      orderedColumns = [...arrivalColumnOrder]
    }
    else if (dataSourceType === 'loading') {
      orderedColumns = [...loadingColumnOrder]
    }
    else if (dataSourceType === 'departure') {
      orderedColumns = [...shipmentColumnOrder]
    } else if (dataSourceType === 'barges') {
      orderedColumns = [...bargesColumnOrder]
    } else if (dataSourceType === 'intransit') {
      orderedColumns = [...intransitColumnOrder]

    }
    let filteredColumns = []
    orderedColumns.forEach(column => {
      if (!filterFields.find((filter) => { return filter === column })) {
        filteredColumns.push(column)
      }
    })
    orderedColumns = filteredColumns;

    // const leftFixedColumns = [];
    // const rightFixedColumns = [];

    // for (const columnName in colOrder) {
    //   if (colOrder[columnName].fixed === 'left') {
    //     leftFixedColumns.push(columnName);
    //   } else if (colOrder[columnName].fixed === 'right') {
    //     rightFixedColumns.push(columnName);
    //   }
    // }

    // console.log(leftFixedColumns)
    // console.log(rightFixedColumns)

    const orderedColOrder = {};

    orderedColumns.forEach(columnName => {
      if (colOrder[columnName] && colOrder[columnName].fixed == "right") {
        orderedColOrder[columnName] = colOrder[columnName];
      }
    });
    const reversedOrderedColumns = orderedColumns.slice().reverse();

    reversedOrderedColumns.forEach(columnName => {
      if (colOrder[columnName] && colOrder[columnName].fixed == "left") {
        orderedColOrder[columnName] = colOrder[columnName];
      }
    });


    for (const columnName in orderedColOrder) {
      console.log(columnName)
      console.log(orderedColOrder[columnName])
      if (orderedColOrder[columnName].fixed === 'left') {
        const index = orderedColumns.indexOf(columnName);
        if (index !== -1) {
          orderedColumns.splice(index, 1);
          orderedColumns.unshift(columnName);
        }
      } else if (orderedColOrder[columnName].fixed === 'right') {
        const index = orderedColumns.indexOf(columnName);
        if (index !== -1) {
          orderedColumns.splice(index, 1);
          orderedColumns.push(columnName);
        }
      }
    }

    console.log(dataa)


    const newOrderedDataSource = dataa.map((data) => {
      const newData = {};
      orderedColumns.forEach((column) => {
        newData[column] = data[column];
      });
      return newData;
    });



    const newdata = mapKeys(newOrderedDataSource)
    exportToExcel(newdata)
    setLoadings(false)
  }

  const downloaddata1 = async () => {
    setLoadings(true)

    var url = null
    if (applyFilter == false) {
      if (dataSourceType == 'arrival') {
        url = `/shipData/fixtures/arrival-data-in-chunks?limit=${totalEntries}`
      }
      else if (dataSourceType == 'loading') {
        url = `/shipData/fixtures/loading-data-in-chunks?limit=${totalEntries}`
      } else if (dataSourceType == 'departure') {
        url = `/shipData/fixtures/shipment-data-in-chunks?limit=${totalEntries}`
      } else if (dataSourceType == 'barges') {
        url = `/barges/fixtures/arrival-data-in-chunks?limit=${totalEntries}`
      } else if (dataSourceType == 'intransit') {
        url = `/intransit/fixtures/data-in-chunks?limit=${totalEntries}`
      }
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        })
        .then(data => {
          console.log(data)
          const filteredObj = data?.data?.data?.map(item => {
            filterFields.forEach(key => {
              delete item[key]
            })
            return item
          })
          console.log(filteredObj)
          const dataToDownload = transformDataSource(filteredObj)
          downloaddata(dataToDownload)
        })
        .catch(err => {
          console.log(err)
        })
    } else {
      let filters = shipmentFilterUpdated
      if (dataSourceType == 'arrival') {
        url = `/shipData/fixtures/filter/arrivals?limit=${totalEntries}`
      }
      else if (dataSourceType == 'loading') {
        url = `/shipData/fixtures/filter/loading?limit=${totalEntries}`
      } else if (dataSourceType == 'departure') {
        url = `/shipData/fixtures/filter/shipments?limit=${totalEntries}`
      } else if (dataSourceType == 'barges') {
        url = `/barges/fixtures/filter/arrivals?limit=${totalEntries}`
      } else if (dataSourceType == 'intransit') {
        url = `/intransit/fixtures/filter/data?limit=${totalEntries}`
      }
      try {
        axios
          .post(url, filters, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          })
          .then(data => {
            console.log(data)
            const filteredObj = data?.data?.data?.map(item => {
              filterFields.forEach(key => {
                delete item[key]
              })
              return item
            })
            console.log(filteredObj)
            const dataToDownload = transformDataSource(filteredObj)
            downloaddata(dataToDownload)
          })
          .catch(err => {
            console.log(err)
          })
      } catch (err) { }
    }
  }

  return (
    <div className='w-full h-auto overflow-auto '>
      <ProTable
        loading={loading}
        onColumnsStateChange={e => {
          console.log(e)
          setColOrder(e)
          let key = Object.keys(e)
          let allFields = []
          key?.map(item => {
            if (e?.[item]?.show == false) {
              allFields.push(item)
            }
          })
          setFields([...allFields])
        }}
        scroll={true}
        headerTitle={
          <>
            <div className='flex justify-start protable-header'>
              <ul className='flex flex-grow pl-3' style={{ columnGap: '35px' }}>
                <li
                  className={
                    dataSourceType == 'arrival'
                      ? 'mr-2 leading-8 active font-bold text-sm fixtures-heading' : 'mr-2 leading-8 text-sm fixtures-heading sub-menu-heading'
                  }
                >
                  <button
                    className='text-xs lg:text-sm'
                    onClick={() => {
                      const tableWrapper = document.querySelector('.ant-table-wrapper');
                      if (tableWrapper) {
                        tableWrapper.scrollLeft = 0;
                        tableWrapper.scrollTop = 0;
                      }
                      if (trailversion) {
                        setFilterApply(false)
                        setAppliedFilter(false)
                        setSetShipmentFiltersdata(null)
                        setDataSourceType('arrival')
                        getfixturesFilterDatas(
                          `/shipData/fixtures/filter/arrivals?limit=${pageSize}&page=${1}`,
                          defaultArrivalFilter
                        )
                      }
                      else {
                        setFilterApply(false)
                        setAppliedFilter(false)
                        setSetShipmentFiltersdata(null)
                        setDataSourceType('arrival')
                        getfixturesDatas(
                          `/shipData/fixtures/arrival-data-in-chunks?limit=${pageSize}&page=${1}`
                        )
                      }

                    }}
                  >
                    {<span>({(appliedFilter && dataSourceType == 'arrival') ? arrivcount : defaultArrivCount})</span>}
                    Arrivals
                  </button>
                </li>

                <li
                  className={
                    dataSourceType == 'intransit'
                      ? 'mr-2 leading-8 active font-bold text-sm fixtures-heading' : 'mr-2 leading-8 text-sm fixtures-heading sub-menu-heading'
                  }
                >
                  <button
                    className='text-xs lg:text-sm'
                    onClick={() => {
                      const tableWrapper = document.querySelector('.ant-table-wrapper');
                      if (tableWrapper) {
                        tableWrapper.scrollLeft = 0;
                        tableWrapper.scrollTop = 0;
                      }
                      if (trailversion) {
                        setFilterApply(false)
                        setAppliedFilter(false)
                        setSetShipmentFiltersdata(null)
                        setDataSourceType('intransit')
                        getfixturesFilterDatas(
                          `/intransit/fixtures/filter/data?limit=${pageSize}&page=${1}`,
                          defaultIntransitFilter
                        )
                      }
                      else {
                        setFilterApply(false)
                        setAppliedFilter(false)
                        setSetShipmentFiltersdata(null)
                        setDataSourceType('intransit')
                        getfixturesDatas(
                          `/intransit/fixtures/data-in-chunks?limit=${pageSize}&page=${1}`
                        )
                      }

                    }}
                  >
                    {<span>({(appliedFilter && dataSourceType == 'intransit') ? intransitcount : defaultIntransitcount})</span>}
                    In Transit
                  </button>
                </li>


                <li
                  className={
                    dataSourceType == 'departure'
                      ? 'leading-8 active font-bold fixtures-heading' : ' leading-8 fixtures-heading sub-menu-heading'
                  }
                >
                  <button
                    className='text-xs lg:text-sm'
                    onClick={() => {
                      const tableWrapper = document.querySelector('.ant-table-wrapper');
                      if (tableWrapper) {
                        tableWrapper.scrollLeft = 0;
                        tableWrapper.scrollTop = 0;
                      }
                      if (trailversion) {
                        setFilterApply(false)
                        setAppliedFilter(false)
                        setSetShipmentFiltersdata(null)
                        setDataSourceType('departure')
                        getfixturesFilterDatas(
                          `/shipData/fixtures/filter/shipments?limit=${pageSize}&page=${1}`,
                          defaultShipmentFilter
                        )
                      }
                      else {
                        setFilterApply(false)
                        setAppliedFilter(false)
                        setSetShipmentFiltersdata(null)
                        setDataSourceType('departure')
                        getfixturesDatas(
                          `/shipData/fixtures/shipment-data-in-chunks?limit=${pageSize}&page=${1}`
                        )
                      }

                    }}
                  >
                    {<span> ({(appliedFilter && dataSourceType == 'departure') ? shipcount : defaultShipCount})</span>}
                    Shipments
                  </button>
                </li>
                <li
                  className={
                    dataSourceType == 'loading'
                      ? 'leading-8 active font-bold fixtures-heading' : ' leading-8 fixtures-heading sub-menu-heading'
                  }
                >
                  <button
                    className='text-xs lg:text-sm'
                    onClick={() => {
                      const tableWrapper = document.querySelector('.ant-table-wrapper');
                      if (tableWrapper) {
                        tableWrapper.scrollLeft = 0;
                        tableWrapper.scrollTop = 0;
                      }
                      setFilterApply(false)
                      setAppliedFilter(false)
                      setSetShipmentFiltersdata(null)
                      setDataSourceType('loading')
                      if (trailversion) {
                        getfixturesFilterDatas(
                          `/shipData/fixtures/filter/loading?limit=${pageSize}&page=${1}`,
                          defaultLoadingFilter
                        )
                      }
                      else {

                        getfixturesDatas(
                          `/shipData/fixtures/loading-data-in-chunks?limit=${pageSize}&page=${1}`
                        )
                      }

                    }}
                  >
                    {<span> ({(appliedFilter && dataSourceType == 'loading') ? loadingCount : defaultLoadingCount})</span>}
                    Live Loading
                  </button>
                </li>
                {bargesAccessLevel && <li
                  className={
                    dataSourceType == 'barges'
                      ? 'leading-8 active font-bold fixtures-heading' : ' leading-8 fixtures-heading sub-menu-heading'
                  }
                >
                  <button
                    className='text-xs lg:text-sm'
                    onClick={() => {
                      const tableWrapper = document.querySelector('.ant-table-wrapper');
                      if (tableWrapper) {
                        tableWrapper.scrollLeft = 0;
                        tableWrapper.scrollTop = 0;
                      }
                      setFilterApply(false)
                      setAppliedFilter(false)
                      setSetShipmentFiltersdata(null)
                      setDataSourceType('barges')
                      if (trailversion) {
                        getfixturesFilterDatas(
                          `/barges/fixtures/filter/arrivals?limit=${pageSize}&page=${1}`,
                          defaultBargesFilter
                        )
                      }
                      else {

                        getfixturesDatas(
                          `/barges/fixtures/arrival-data-in-chunks?limit=${pageSize}&page=${1}`
                        )
                      }

                    }}
                  >
                    {<span> ({(appliedFilter && dataSourceType == 'barges') ? bargesCount : defaultBargesCount})</span>}
                    Barges
                  </button>
                </li>}
              </ul>
              {!trailversion && <Button
                type='text'
                onClick={() => {
                  downloaddata1()
                }}
                className=' flex mr-4 border-blue-200 bg-white text-xs md:text-sm'
                icon={<DownloadIcon className='mr-2 mt-0.5' />}
              >
                Download
              </Button>}
              <Button
                type='text'
                onClick={showDrawer}
                className=' flex border-blue-200 bg-white'
                icon={<FilterIcon className='mr-2' />}
              >
                Filter
              </Button>
            </div>
          </>
        }
        columns={columns}
        rowKey='index'
        pagination={false}
        onSubmit={() => {
          // alert('I am Awais Niaz')
        }}
        onSetti
        dataSource={dataSource1}
        className='text-xs'
        components={{
          body: {}
        }}
      />
      {appliedFilter == false ? (
        <Pagination
          className='p-4 text-center bg-white'
          defaultCurrent={1}
          current={currentPage}
          pageSize={pageSize}
          total={Total}
          loading={loading}
          showSizeChanger={true}
          onChange={(page, pageSize) => {
            const tableWrapper = document.querySelector('.ant-table-wrapper');
            if (tableWrapper) {
              tableWrapper.scrollLeft = 0;
            }

            setPageSize1(pageSize)
            if (trailversion) {
              if (dataSourceType == 'arrival') {
                getfixturesFilterDatas(
                  `/shipData/fixtures/filter/arrivals?limit=${pageSize}&page=${page}`,
                  defaultArrivalFilter
                )
              }
              else if (dataSourceType == 'loading') {
                getfixturesFilterDatas(
                  `/shipData/fixtures/filter/loading?limit=${pageSize}&page=${page}`,
                  defaultLoadingFilter
                )
              } else if (dataSourceType == 'departure') {
                getfixturesFilterDatas(
                  `/shipData/fixtures/filter/shipments?limit=${pageSize}&page=${page}`,
                  defaultShipmentFilter
                )
              } else if (dataSourceType == 'barges') {
                getfixturesFilterDatas(
                  `/barges/fixtures/filter/arrivals?limit=${pageSize}&page=${page}`,
                  defaultBargesFilter
                )
              } else if (dataSourceType == 'intransit') {
                getfixturesFilterDatas(
                  `/intransit/fixtures/filter/data?limit=${pageSize}&page=${page}`,
                  defaultIntransitFilter
                )
              }

            }
            else {
              if (dataSourceType == 'arrival') {
                getfixturesDatas(
                  `/shipData/fixtures/arrival-data-in-chunks?limit=${pageSize}&page=${page}`
                )
              }
              else if (dataSourceType == 'loading') {
                getfixturesDatas(
                  `/shipData/fixtures/loading-data-in-chunks?limit=${pageSize}&page=${page}`
                )
              } else if (dataSourceType == 'departure') {
                getfixturesDatas(
                  `/shipData/fixtures/shipment-data-in-chunks?limit=${pageSize}&page=${page}`
                )
              } else if (dataSourceType == 'barges') {
                getfixturesDatas(
                  `/barges/fixtures/arrival-data-in-chunks?limit=${pageSize}&page=${page}`
                )
              } else if (dataSourceType == 'intransit') {
                getfixturesDatas(
                  `/intransit/fixtures/data-in-chunks?limit=${pageSize}&page=${page}`
                )
              }
            }
          }}
          onShowSizeChange={(current, size) => {
            setCurrentPage(current) // Reset page to 1 when pageSize changes
            // setPageSize(size)
          }}
        />
      ) : (
        <Pagination
          className='p-4 text-center bg-white'
          defaultCurrent={1}
          current={currentPage}
          pageSize={pageSize}
          total={Total}
          showSizeChanger={true}
          loading={loading}
          onChange={(page, pageSize) => {
            const tableWrapper = document.querySelector('.ant-table-wrapper');
            if (tableWrapper) {
              tableWrapper.scrollLeft = 0;
            }
            setCurrentPage(page)
            setPageSize1(pageSize)
            if (trailversion && (!shipmentFilterUpdated || !shipmentFilterUpdated.date)) {
              if (dataSourceType == 'arrival' || dataSourceType == 'barges')
                shipmentFilterUpdated = { ...shipmentFilterUpdated, ata_utc_end: defaultArrivalFilter.ata_utc_end, ata_utc_start: defaultArrivalFilter.ata_utc_start, date: defaultArrivalFilter.date };
              else
                shipmentFilterUpdated = { ...shipmentFilterUpdated, timestamp_utc_start: defaultShipmentFilter.timestamp_utc_start, timestamp_utc_end: defaultShipmentFilter.timestamp_utc_end, date: defaultArrivalFilter.date };
            }
            if (dataSourceType == 'arrival') {
              getfixturesFilterDatas(
                `/shipData/fixtures/filter/arrivals?limit=${pageSize}&page=${page}`,
                shipmentFilterUpdated
              )
            }
            else if (dataSourceType == 'loading') {
              getfixturesFilterDatas(
                `/shipData/fixtures/filter/loading?limit=${pageSize}&page=${page}`,
                shipmentFilterUpdated
              )
            } else if (dataSourceType == 'departure') {
              getfixturesFilterDatas(
                `/shipData/fixtures/filter/shipments?limit=${pageSize}&page=${page}`,
                shipmentFilterUpdated
              )
            } else if (dataSourceType == 'barges') {
              getfixturesFilterDatas(
                `/barges/fixtures/filter/arrivals?limit=${pageSize}&page=${page}`,
                shipmentFilterUpdated
              )
            } else if (dataSourceType == 'intransit') {
              getfixturesFilterDatas(
                `/intransit/fixtures/filter/data?limit=${pageSize}&page=${page}`,
                shipmentFilterUpdated
              )
            }
          }}
          onShowSizeChange={(current, size) => { }}
        />
      )}
    </div>
  )
}

export default DataTable
