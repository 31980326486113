import Company from "./Company/Company";
import { useEffect, useState } from "react";
import { defaultShipmentTableColForPigIron } from "../utilities/DateToLocalUtility";
import { useLocation, useNavigate } from "react-router-dom";
import { SubMenu } from "../Components/subMenu";
import { checkAccessLevel, checkAccessLevelById, getAccessLevels, submenuAccessLevel } from "../Components/access-level";
import Comodoties from "./Comodoties";
import { WarningModal } from "../Components/Misc/WarningModal";

export const StainlessScrap = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [selectedSubMenu, setSeletectedSubMenu] = useState("");
    useEffect(() => {
        const access = checkAccessLevelById(18);
        if (!access) {
            navigate('/welcome')
            WarningModal("StainlessScrap")
        }
        else
            setSeletectedSubMenu("/fixtures");
    }, [])
    return (
        <>
            <SubMenu submenuAccessLevel={submenuAccessLevel.slice(0, -1)} accessLevels={getAccessLevels()} selectedSubMenu={selectedSubMenu} setSeletectedSubMenu={setSeletectedSubMenu} />
            {selectedSubMenu == "/fixtures" ? <Comodoties defaultColumns={defaultShipmentTableColForPigIron} comodity={'stainlessscrap'} /> :
                selectedSubMenu == "/company" ? <Company comodity={'stainlessscrap'} /> : ""}
        </>
    )
}   