import { transformDataSource } from "./utils";
import { exportToExcel } from './SaveAsCsv'
import axios from '../axiosconfiguration/axios'

const arrivalColumnOrder = [
    'ata_utc', 'ship_name', 'ship_type', 'size_class', 'dwt', 'move_type', 'total_cargo_load',
    'mill', 'dest_country', 'actual_dest', 'dest_berth', 'company', 'country',
    'previous_port_name', 'dep_berth', 'co_load'
];

const ironOreArrivalColumnOrder = [
    'ata_utc', 'ship_name', 'ship_type', 'size_class', 'dwt', 'move_type', 'arr_total_cargo_load',
    'mill', 'actual_dest_country', 'actual_dest', 'dest_berth', 'co_discharge', 'company', 'country',
    'previous_port_name', 'dep_berth', 'co_load'
];

const driHbiArrivalColumnOrder = [
    'ata_utc', 'ship_name', 'ship_type', 'size_class', 'dwt', 'move_type', 'total_cargo_load',
    'mill', 'dest_country', 'actual_dest', 'dest_berth', 'co_discharge', 'company', 'country',
    'previous_port_name', 'dep_berth', 'co_load'
];

const shipmentColumnOrder = [
    'timestamp_utc', 'ship_name', 'ship_type', 'size_class', 'dwt', 'move_type', 'total_cargo_load',
    'company', 'country', 'port_name', 'dep_berth', 'co_load', 'dest_country',
    'destination', 'reported_eta', 'berthing_time', 'loading_rate'
];

const loadingColumnOrder = [
    'timestamp_utc', 'ship_name', 'ship_type', 'size_class', 'dwt', 'move_type',
    'company', 'country', 'port_name', 'load_berth', 'co_load', 'berthing_time',
    'previous_country', 'previous_port_name', 'previous_leg_time'
];

const intransitColumnOrder = [
    'time_received', 'ship_name', 'ship_type', 'size_class', 'dwt', 'move_type', 'total_cargo_load',
    'company', 'country', 'port_name', 'dep_berth', 'co_load', 'expected_buyer', 'dest_country',
    'destination', 'departure_time', 'reported_eta', 'berthing_time', 'loading_rate'
];

const stainlessScrapIntransitColumnOrder = [
    'time_received', 'ship_name', 'ship_type', 'size_class', 'dwt', 'move_type', 'total_cargo_load',
    'company', 'country', 'port_name', 'dep_berth', 'co_load', 'expected_buyer',
    'dest_country', 'destination', 'departure_time', 'reported_eta', 'berthing_time', 'loading_rate'
];

const bargesColumnOrder = [
    'ata_utc', 'ship_name', 'ship_type', 'move_type', 'estimated_cargo_load',
    'mill', 'actual_dest_country', 'actual_dest', 'dest_berth', 'company', 'country',
    'port_name', 'dep_berth', 'atd_utc', 'berthing_time'
];

const bargesKeyMapping = {
    ata_utc: 'Timestamp UTC',
    ship_name: 'Ship Name',
    ship_type: 'Ship Type',
    move_type: 'Move Type',
    estimated_cargo_load: 'Estimated Cargo Load (t)',
    mill: 'Buyer',
    actual_dest_country: 'Arrival Country',
    actual_dest: 'Arrival Port',
    dest_berth: 'Arrival Berth',
    company: 'Supplier',
    country: 'Origin Country',
    previous_port_name: 'Origin Port',
    dep_berth: 'Origin Berth',
    atd_utc: 'Dep Timestamp UTC',
    berthing_time: 'Berthing Time'
};
const arrivalKeyMapping = {
    ata_utc: 'Timestamp UTC',
    ship_name: 'Ship Name',
    ship_type: 'Ship Type',
    size_class: 'Vessel Class',
    dwt: 'DWT',
    move_type: 'Move Type',
    total_cargo_load: 'Estimated Cargo Load (t)',
    mill: 'Buyer',
    dest_country: 'Arrival Country',
    actual_dest: 'Arrival Port',
    dest_berth: 'Arrival Berth',
    company: 'Supplier',
    country: 'Origin Country',
    previous_port_name: 'Origin Port',
    dep_berth: 'Origin Berth',
    co_load: 'Co Loading Port',
};

const ironOreArrivalKeyMapping = {
    ata_utc: 'Timestamp UTC',
    ship_name: 'Ship Name',
    ship_type: 'Ship Type',
    size_class: 'Vessel Class',
    dwt: 'DWT',
    move_type: 'Move Type',
    arr_total_cargo_load: 'Estimated Cargo Load (t)',
    mill: 'Buyer',
    actual_dest_country: 'Arrival Country',
    actual_dest: 'Arrival Port',
    dest_berth: 'Arrival Berth',
    co_discharge: 'Co Discharging Port',
    company: 'Supplier',
    country: 'Origin Country',
    previous_port_name: 'Origin Port',
    dep_berth: 'Origin Berth',
    co_load: 'Co Loading Port',
};

const driHbiArrivalKeyMapping = {
    ata_utc: 'Timestamp UTC',
    ship_name: 'Ship Name',
    ship_type: 'Ship Type',
    size_class: 'Vessel Class',
    dwt: 'DWT',
    move_type: 'Move Type',
    total_cargo_load: 'Estimated Cargo Load (t)',
    mill: 'Buyer',
    dest_country: 'Arrival Country',
    actual_dest: 'Arrival Port',
    dest_berth: 'Arrival Berth',
    co_discharge: 'Co Discharging Port',
    company: 'Supplier',
    country: 'Origin Country',
    previous_port_name: 'Origin Port',
    dep_berth: 'Origin Berth',
    co_load: 'Co Loading Port',
};

const shipmentKeyMapping = {
    timestamp_utc: 'Timestamp UTC',
    ship_name: 'Ship Name',
    ship_type: 'Ship Type',
    size_class: 'Vessel Class',
    dwt: 'DWT',
    move_type: 'Move Type',
    total_cargo_load: 'Estimated Cargo Load (t)',
    company: 'Supplier',
    country: 'Departure Country',
    port_name: 'Departure Port',
    dep_berth: 'Departure Berth',
    co_load: 'Co Loading Port',
    dest_country: 'Destination Country',
    destination: 'Destination Port',
    reported_eta: 'Reported ETA',
    berthing_time: 'Berthing Time',
    loading_rate: 'Loading Rate (t/hr)',
};

const liveLoadingKeyMapping = {
    timestamp_utc: 'Arrival Time',
    ship_name: 'Ship Name',
    ship_type: 'Ship Type',
    size_class: 'Vessel Class',
    dwt: 'DWT',
    move_type: 'Move Type',
    company: 'Supplier',
    country: 'Load Country',
    port_name: 'Load Port',
    load_berth: 'Load Berth',
    co_load: 'Co Loading Port',
    berthing_time: 'Berthing Time',
    previous_country: 'Prev Country',
    previous_port_name: 'Prev Port',
    previous_leg_time: 'Prev Leg Time',
};

const intransitKeyMapping = {
    time_received: 'Timestamp UTC',
    ship_name: 'Ship Name',
    ship_type: 'Ship Type',
    size_class: 'Vessel Class',
    dwt: 'DWT',
    move_type: 'Move Type',
    total_cargo_load: 'Estimated Cargo Load (t)',
    company: 'Supplier',
    country: 'Departure Country',
    port_name: 'Departure Port',
    dep_berth: 'Departure Berth',
    co_load: 'Co Loading Port',
    expected_buyer: 'Expected Buyer',
    dest_country: 'Destination Country',
    destination: 'Destination Port',
    reported_eta: 'Reported ETA',
    departure_time: 'ATD UTC',
    berthing_time: 'Berthing Time',
    loading_rate: 'Loading Rate (t/hr)',
};

const stainlessScrapIntransitKeyMapping = {
    time_received: 'Timestamp UTC',
    ship_name: 'Ship Name',
    ship_type: 'Ship Type',
    size_class: 'Vessel Class',
    dwt: 'DWT',
    move_type: 'Move Type',
    total_cargo_load: 'Estimated Cargo Load (t)',
    company: 'Supplier',
    country: 'Departure Country',
    port_name: 'Departure Port',
    dep_berth: 'Departure Berth',
    co_load: 'Co Loading Port',
    expected_buyer: 'Expected Buyer',
    dest_country: 'Destination Country',
    destination: 'Destination Port',
    reported_eta: 'Reported ETA',
    berthing_time: 'Berthing Time',
    loading_rate: 'Loading Rate (t/hr)',
};

function mapKeys(comodity, data, dataSourceType) {
    let keyMapping = dataSourceType === 'arrival' ? arrivalKeyMapping : dataSourceType === 'loading' ? liveLoadingKeyMapping : dataSourceType === 'intransit' ? intransitKeyMapping : shipmentKeyMapping;

    if (comodity == 'drihbi')
        keyMapping = dataSourceType === 'arrival' ? driHbiArrivalKeyMapping : keyMapping;

    if (comodity == 'ironore')
        keyMapping = dataSourceType === 'barges' ? bargesKeyMapping : dataSourceType === 'arrival' ? ironOreArrivalKeyMapping : keyMapping;

    if (comodity == 'stainlessscrap')
        keyMapping = dataSourceType === 'barges' ? bargesKeyMapping : dataSourceType === 'intransit' ? stainlessScrapIntransitKeyMapping : keyMapping;



    return data.map(item => {
        const mappedItem = {};
        for (const key in item) {
            if (keyMapping.hasOwnProperty(key) && item.hasOwnProperty(key)) {
                mappedItem[keyMapping[key]] = item[key];
            }
        }
        return mappedItem;
    });
}

const downloadData = async (comodity, dataToDownload, colOrder, dataSourceType, filterFields) => {
    let orderedColumns = [];
    if (comodity === 'drihbi' && dataSourceType === 'arrival') {
        orderedColumns = [...driHbiArrivalColumnOrder]
    } else if (comodity === 'ironore' && dataSourceType === 'arrival') {
        orderedColumns = [...ironOreArrivalColumnOrder]
    } else if (dataSourceType === 'arrival') {
        orderedColumns = [...arrivalColumnOrder]
    }
    else if (dataSourceType === 'loading') {
        orderedColumns = [...loadingColumnOrder]
    }
    else if (dataSourceType === 'intransit') {
        if (comodity == 'stainlessscrap')
            orderedColumns = [...stainlessScrapIntransitColumnOrder]
        else
            orderedColumns = [...intransitColumnOrder]
    } else if (dataSourceType === 'barges') {
        orderedColumns = [...bargesColumnOrder]
    } else {

        orderedColumns = [...shipmentColumnOrder]
    }
    let filteredColumns = []
    orderedColumns.forEach(column => {
        if (!filterFields.find((filter) => { return filter === column })) {
            filteredColumns.push(column)
        }
    })
    orderedColumns = filteredColumns;
    const orderedColOrder = {};

    orderedColumns.forEach(columnName => {
        if (colOrder[columnName] && colOrder[columnName].fixed == "right") {
            orderedColOrder[columnName] = colOrder[columnName];
        }
    });
    const reversedOrderedColumns = orderedColumns.slice().reverse();

    reversedOrderedColumns.forEach(columnName => {
        if (colOrder[columnName] && colOrder[columnName].fixed == "left") {
            orderedColOrder[columnName] = colOrder[columnName];
        }
    });


    for (const columnName in orderedColOrder) {
        console.log(columnName)
        console.log(orderedColOrder[columnName])
        if (orderedColOrder[columnName].fixed === 'left') {
            const index = orderedColumns.indexOf(columnName);
            if (index !== -1) {
                orderedColumns.splice(index, 1);
                orderedColumns.unshift(columnName);
            }
        } else if (orderedColOrder[columnName].fixed === 'right') {
            const index = orderedColumns.indexOf(columnName);
            if (index !== -1) {
                orderedColumns.splice(index, 1);
                orderedColumns.push(columnName);
            }
        }
    }

    console.log(dataToDownload)


    const newOrderedDataSource = dataToDownload.map((data) => {
        const newData = {};
        orderedColumns.forEach((column) => {
            newData[column] = data[column];
        });
        return newData;
    });



    const newdata = mapKeys(comodity, newOrderedDataSource, dataSourceType)
    exportToExcel(newdata);
    return true;
}

export const downloadToCsv = async (comodity, url, colOrder, dataSourceType, filterFields, onComplete, filters) => {

    if (!filters) {
        axios
            .get(url, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
            .then(data => {
                console.log(data)
                const filteredObj = data?.data?.data?.map(item => {
                    filterFields.forEach(key => {
                        delete item[key]
                    })
                    return item
                })
                console.log(filteredObj)
                const dataToDownload = transformDataSource(filteredObj)
                downloadData(comodity, dataToDownload, colOrder, dataSourceType, filterFields)
                onComplete();
            })
            .catch(err => {
                console.log(err)
                onComplete();
            })
    } else {
        try {
            axios
                .post(url, filters, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                })
                .then(data => {
                    console.log(data)
                    const filteredObj = data?.data?.data?.map(item => {
                        filterFields.forEach(key => {
                            delete item[key]
                        })
                        return item
                    })
                    console.log(filteredObj)
                    const dataToDownload = transformDataSource(filteredObj)
                    downloadData(comodity, dataToDownload, colOrder, dataSourceType, filterFields)
                    onComplete();
                })
                .catch(err => {
                    console.log(err)
                    onComplete();
                })
        } catch (err) { }
    }
}