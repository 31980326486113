import React, { useState, useEffect } from 'react'
import axios from '../axiosconfiguration/axios'

import {
  Button,
  Drawer,
  Form,
  Input,
  DatePicker,
  Select,
  InputNumber,
  Spin
} from 'antd'
import dayjs from 'dayjs'
import { ReactComponent as FilterIcon } from '../Assets/images/filter-icon.svg'
import { ReactComponent as SignOutIcon } from '../Assets/images/sign-out-right.svg'
import { ReactComponent as LeftArrow } from '../Assets/images/icons/leftArrow.svg'
import { ReactComponent as SaveSetting } from '../Assets/images/icons/save-setting.svg'

import isBetween from 'dayjs/plugin/isBetween'
const { RangePicker } = DatePicker
import { LoadingOutlined } from '@ant-design/icons';
import { SavedFilter } from './Filters/SavedFilter'
import { SavedFilterMapping, mapFilterObject } from '../utilities/filtersUtil'
import ModalDialog from './common/ModalDialog'
import { ManageSearches } from './Filters/ManageSearches'
let filterdata = {};
const FiltersDrawer = ({
  placement,
  closable,
  onClose,
  open,
  setSetShipmentFiltersdata,
  setAppliedFilter,
  dataSourceType,
  trailversion,
  getfixturesData,
  getfixturesFilterDatas,
  shipmentFilterUpdated,
  setFilterApply,
  pageSizes,
  defaultShipmentFilter,
  defaultArrivalFilter,
  defaultLoadingFilter,
  defaultBargesFilter,
  defaultIntransitFilter,
  comodity,
  dataSource = { dataSource },
  loadings,
  saveFilterCall,
  isSavedFilterCall,
  setIsSavedFilterCall,
}) => {
  const [form] = Form.useForm()
  const [shipform] = Form.useForm()

  const [error, setError] = useState(null)
  const acesstoken = localStorage.getItem('token')
  // const [filterdata, setFilterdata] = useState({})
  const [loading, setLoading] = useState(false)
  const [oldFilter, setOldFilter] = useState({})
  const [savedSearches, SetSavedSearches] = useState([])
  const [fetchUniquevalues, setFetchUniqueValues] = useState(true);
  const [openManageSearch, setOpenManageSearch] = useState(false);
  const [selectedSavedSearch, setSelectedSavedSearch] = useState({});

  console.log(filterdata)

  useEffect(() => {
    if (isSavedFilterCall) {
      SetSavedSearches([])
    }
  }, [isSavedFilterCall])
  // useEffect(() => {
  //   //arrival filters populate
  //   getArrivalUniqueValues({})
  //   //shipment filters populate
  //   getShipmentUniqueValues({})
  //   return () => {}
  // }, [])

  useEffect(() => {
    console.log(filterdata)
    let difference = {};
    for (const key in shipmentFilterUpdated) {
      if (oldFilter) {
        if (shipmentFilterUpdated.hasOwnProperty(key) && !oldFilter.hasOwnProperty(key)) {
          difference[key] = shipmentFilterUpdated[key];
        }
      }
      else {
        difference = shipmentFilterUpdated
      }
    }
    if (Object.keys(selectedSavedSearch).length !== 0 && selectedSavedSearch.isFromSavedFilter) {
      selectedSavedSearch.isFromSavedFilter = false;
      setSelectedSavedSearch(selectedSavedSearch);
    }
    else {
      if (dataSourceType == 'arrival') {
        if ((difference.hasOwnProperty('dest_country') || (oldFilter?.hasOwnProperty('dest_country') && shipmentFilterUpdated?.hasOwnProperty('dest_country') && oldFilter.dest_country != shipmentFilterUpdated.dest_country))) {
          if (filterdata[dataSourceType].hasOwnProperty('actual_dest'))
            delete filterdata[dataSourceType].actual_dest;
          if (filterdata[dataSourceType].hasOwnProperty('mill'))
            delete filterdata[dataSourceType].mill;
          if (shipmentFilterUpdated.hasOwnProperty('actual_dest'))
            delete shipmentFilterUpdated.actual_dest
          if (shipmentFilterUpdated.hasOwnProperty('mill'))
            delete shipmentFilterUpdated.mill
        }
        else if ((difference.hasOwnProperty('actual_dest') || (oldFilter?.hasOwnProperty('actual_dest') && shipmentFilterUpdated?.hasOwnProperty('actual_dest') && oldFilter.actual_dest != shipmentFilterUpdated.actual_dest))) {
          if (filterdata[dataSourceType].hasOwnProperty('mill'))
            delete filterdata[dataSourceType].mill;
          if (shipmentFilterUpdated.hasOwnProperty('mill'))
            delete shipmentFilterUpdated.mill
        }
        else if ((difference.hasOwnProperty('country') || (oldFilter?.hasOwnProperty('country') && shipmentFilterUpdated?.hasOwnProperty('country') && oldFilter.country != shipmentFilterUpdated.country))) {
          if (filterdata[dataSourceType].hasOwnProperty('previous_port_name'))
            delete filterdata[dataSourceType].previous_port_name;
          if (filterdata[dataSourceType].hasOwnProperty('company'))
            delete filterdata[dataSourceType].company;
          if (shipmentFilterUpdated.hasOwnProperty('previous_port_name'))
            delete shipmentFilterUpdated.previous_port_name
          if (shipmentFilterUpdated.hasOwnProperty('company'))
            delete shipmentFilterUpdated.company
        }
        else if ((difference.hasOwnProperty('previous_port_name') || ((oldFilter?.hasOwnProperty('previous_port_name') && shipmentFilterUpdated?.hasOwnProperty('previous_port_name') && oldFilter.previous_port_name != shipmentFilterUpdated.previous_port_name)))) {
          if (filterdata[dataSourceType].hasOwnProperty('company'))
            delete filterdata[dataSourceType].company;
          if (shipmentFilterUpdated.hasOwnProperty('company'))
            delete shipmentFilterUpdated.company
        }
      } else if (dataSourceType == 'barges') {
        if ((difference.hasOwnProperty('actual_dest_country') || (oldFilter?.hasOwnProperty('actual_dest_country') && shipmentFilterUpdated?.hasOwnProperty('actual_dest_country') && oldFilter.actual_dest_country != shipmentFilterUpdated.actual_dest_country))) {
          if (filterdata[dataSourceType].hasOwnProperty('actual_dest'))
            delete filterdata[dataSourceType].actual_dest;
          if (filterdata[dataSourceType].hasOwnProperty('mill'))
            delete filterdata[dataSourceType].mill;
          if (shipmentFilterUpdated.hasOwnProperty('actual_dest'))
            delete shipmentFilterUpdated.actual_dest
          if (shipmentFilterUpdated.hasOwnProperty('mill'))
            delete shipmentFilterUpdated.mill
        }
        else if ((difference.hasOwnProperty('actual_dest') || (oldFilter?.hasOwnProperty('actual_dest') && shipmentFilterUpdated?.hasOwnProperty('actual_dest') && oldFilter.actual_dest != shipmentFilterUpdated.actual_dest))) {
          if (filterdata[dataSourceType].hasOwnProperty('mill'))
            delete filterdata[dataSourceType].mill;
          if (shipmentFilterUpdated.hasOwnProperty('mill'))
            delete shipmentFilterUpdated.mill
        }
        else if ((difference.hasOwnProperty('country') || (oldFilter?.hasOwnProperty('country') && shipmentFilterUpdated?.hasOwnProperty('country') && oldFilter.country != shipmentFilterUpdated.country))) {
          if (filterdata[dataSourceType].hasOwnProperty('port_name'))
            delete filterdata[dataSourceType].port_name;
          if (filterdata[dataSourceType].hasOwnProperty('company'))
            delete filterdata[dataSourceType].company;
          if (shipmentFilterUpdated.hasOwnProperty('port_name'))
            delete shipmentFilterUpdated.port_name
          if (shipmentFilterUpdated.hasOwnProperty('company'))
            delete shipmentFilterUpdated.company
        }
        else if ((difference.hasOwnProperty('port_name') || ((oldFilter?.hasOwnProperty('port_name') && shipmentFilterUpdated?.hasOwnProperty('port_name') && oldFilter.port_name != shipmentFilterUpdated.port_name)))) {
          if (filterdata[dataSourceType].hasOwnProperty('company'))
            delete filterdata[dataSourceType].company;
          if (shipmentFilterUpdated.hasOwnProperty('company'))
            delete shipmentFilterUpdated.company
        }
      } else if (dataSourceType == 'loading') {
        if ((difference.hasOwnProperty('country') || (oldFilter?.hasOwnProperty('country') && shipmentFilterUpdated?.hasOwnProperty('country') && oldFilter.country != shipmentFilterUpdated.country))) {
          if (filterdata[dataSourceType].hasOwnProperty('port_name'))
            delete filterdata[dataSourceType].port_name;
          if (filterdata[dataSourceType].hasOwnProperty('company'))
            delete filterdata[dataSourceType].company;
          if (filterdata[dataSourceType].hasOwnProperty('load_berth'))
            delete filterdata[dataSourceType].load_berth;
          if (shipmentFilterUpdated.hasOwnProperty('port_name'))
            delete shipmentFilterUpdated.port_name
          if (shipmentFilterUpdated.hasOwnProperty('company'))
            delete shipmentFilterUpdated.company
          if (shipmentFilterUpdated.hasOwnProperty('load_berth'))
            delete shipmentFilterUpdated.load_berth
        }
        if ((difference.hasOwnProperty('previous_country') || (oldFilter?.hasOwnProperty('previous_country') && shipmentFilterUpdated?.hasOwnProperty('previous_country') && oldFilter.previous_country != shipmentFilterUpdated.previous_country))) {
          if (filterdata[dataSourceType].hasOwnProperty('previous_port_name'))
            delete filterdata[dataSourceType].previous_port_name;
          // if (filterdata[datasourcetype].hasOwnProperty('company'))
          //   delete filterdata[datasourcetype].company;
          if (shipmentFilterUpdated.hasOwnProperty('previous_port_name'))
            delete shipmentFilterUpdated.previous_port_name;
          // if (shipmentFilterUpdated.hasOwnProperty('company'))
          //   delete shipmentFilterUpdated.company
        }
        else if ((difference.hasOwnProperty('port_name') || (oldFilter?.hasOwnProperty('port_name') && shipmentFilterUpdated?.hasOwnProperty('port_name') && oldFilter.port_name != shipmentFilterUpdated.port_name))) {
          if (filterdata[dataSourceType].hasOwnProperty('company'))
            delete filterdata[dataSourceType].company;
          if (filterdata[dataSourceType].hasOwnProperty('load_berth'))
            delete filterdata[dataSourceType].load_berth;
          if (shipmentFilterUpdated.hasOwnProperty('company'))
            delete shipmentFilterUpdated.company
          if (shipmentFilterUpdated.hasOwnProperty('load_berth'))
            delete shipmentFilterUpdated.load_berth
        }
        else if ((difference.hasOwnProperty('dest_country') || (oldFilter?.hasOwnProperty('dest_country') && shipmentFilterUpdated?.hasOwnProperty('dest_country') && oldFilter.dest_country != shipmentFilterUpdated.dest_country))) {
          if (filterdata[dataSourceType].hasOwnProperty('destination'))
            delete filterdata[dataSourceType].destination;
          if (shipmentFilterUpdated.hasOwnProperty('destination'))
            delete shipmentFilterUpdated.destination
        }
      } else {
        if ((difference.hasOwnProperty('country') || (oldFilter?.hasOwnProperty('country') && shipmentFilterUpdated?.hasOwnProperty('country') && oldFilter.country != shipmentFilterUpdated.country))) {
          if (filterdata[dataSourceType].hasOwnProperty('port_name'))
            delete filterdata[dataSourceType].port_name;
          if (filterdata[dataSourceType].hasOwnProperty('company'))
            delete filterdata[dataSourceType].company;
          if (shipmentFilterUpdated.hasOwnProperty('port_name'))
            delete shipmentFilterUpdated.port_name
          if (shipmentFilterUpdated.hasOwnProperty('company'))
            delete shipmentFilterUpdated.company
        }
        else if ((difference.hasOwnProperty('port_name') || (oldFilter?.hasOwnProperty('port_name') && shipmentFilterUpdated?.hasOwnProperty('port_name') && oldFilter.port_name != shipmentFilterUpdated.port_name))) {
          if (filterdata[dataSourceType].hasOwnProperty('company'))
            delete filterdata[dataSourceType].company;
          if (shipmentFilterUpdated.hasOwnProperty('company'))
            delete shipmentFilterUpdated.company
        }
        else if ((difference.hasOwnProperty('dest_country') || (oldFilter?.hasOwnProperty('dest_country') && shipmentFilterUpdated?.hasOwnProperty('dest_country') && oldFilter.dest_country != shipmentFilterUpdated.dest_country))) {
          if (filterdata[dataSourceType].hasOwnProperty('destination'))
            delete filterdata[dataSourceType].destination;
          if (shipmentFilterUpdated.hasOwnProperty('destination'))
            delete shipmentFilterUpdated.destination
        }
      }
    }


    console.log(difference)
    if (fetchUniquevalues) {
      dataSourceType == 'arrival' ? getArrivalUniqueValues(shipmentFilterUpdated)
        : dataSourceType == 'loading' ? getLoadingUniqueValues(shipmentFilterUpdated)
          : (dataSourceType == 'departure' || dataSourceType == 'shipment') ? getShipmentUniqueValues(shipmentFilterUpdated)
            : dataSourceType == 'intransit' ? getIntransitUniqueValues(shipmentFilterUpdated)
              : getBargesUniqueValues(shipmentFilterUpdated)


      setOldFilter(shipmentFilterUpdated)
    }

  }, [shipmentFilterUpdated, dataSourceType, fetchUniquevalues])
  useEffect(() => {
    // setFilterdata({});
    setSelectedSavedSearch({})
    setFetchUniqueValues(true);
  }, [dataSourceType])

  const getArrivalUniqueValues = async filters => {
    try {
      setLoading(true)
      const response = await axios.post(
        `${comodity}/fixtures/arrival/unique`,
        filters,
        { headers: { Authorization: `Bearer ${acesstoken}` } }
      )
      if (response?.data) {
        console.log(response?.data)
        filterdata = {
          ...filterdata,
          [dataSourceType]: response?.data?.data
        }
        // setFilterdata({
        //   ...filterdata,
        //   [dataSourceType]: response?.data?.data
        // })
      }
    } catch (err) {
      setError(err)
    }
    finally {
      setLoading(false)
    }
  }
  const getLoadingUniqueValues = async filters => {
    try {
      setLoading(true)
      const response = await axios.post(
        `${comodity}/fixtures/loading/unique`,
        filters,
        { headers: { Authorization: `Bearer ${acesstoken}` } }
      )
      if (response?.data) {
        console.log(response?.data)
        filterdata = {
          ...filterdata,
          [dataSourceType]: response?.data?.data
        }
        // setFilterdata({
        //   ...filterdata,
        //   [dataSourceType]: response?.data?.data
        // })
      }
      setLoading(false)
    } catch (err) {
      setError(err)
      setLoading(false)
    }
  }
  const getBargesUniqueValues = async filters => {
    try {
      setLoading(true)
      const response = await axios.post(
        `${comodity == 'shipData' ? '' : comodity}/barges/fixtures/arrival/unique`,
        filters,
        { headers: { Authorization: `Bearer ${acesstoken}` } }
      )
      if (response?.data) {
        console.log(response?.data)
        filterdata = {
          ...filterdata,
          [dataSourceType]: response?.data?.data
        }
        // setFilterdata({
        //   ...filterdata,
        //   [dataSourceType]: response?.data?.data
        // })
      }
      setLoading(false)
    } catch (err) {
      setError(err)
      setLoading(false)
    }
  }

  const getIntransitUniqueValues = async filters => {
    try {
      setLoading(true)
      const response = await axios.post(
        `${comodity == 'shipData' ? '' : comodity}/intransit/fixtures/data/unique`,
        filters,
        { headers: { Authorization: `Bearer ${acesstoken}` } }
      )
      if (response?.data) {
        console.log(response?.data)
        filterdata = {
          ...filterdata,
          [dataSourceType]: response?.data?.data
        }
        // setFilterdata({
        //   ...filterdata,
        //   [dataSourceType]: response?.data?.data
        // })
      }
      setLoading(false)
    } catch (err) {
      setError(err)
      setLoading(false)
    }
  }
  const calculateDefaultDate = () => {
    const end = new Date()

    const start = new Date()
    start.setDate(start.getDate() - 29)
    end.setDate(end.getDate())
    let ata_utc_start = `${start?.getFullYear()}-${start?.getMonth() + 1 < 10
      ? '0' + (start?.getMonth() + 1)
      : start?.getMonth() + 1
      }-${start?.getDate() < 10 ? '0' + start?.getDate() : start?.getDate()}`
    let ata_utc_end = `${end?.getFullYear()}-${end?.getMonth() + 1 < 10 ? '0' + (end.getMonth() + 1) : end.getMonth() + 1
      }-${end?.getDate() < 10 ? '0' + end?.getDate() : end?.getDate()}`
    return [ata_utc_start, ata_utc_end]
  }
  const getShipmentUniqueValues = async filters => {
    try {
      setLoading(true)
      const response = await axios.post(
        `${comodity}/fixtures/shipment/unique`,
        filters,
        { headers: { Authorization: `Bearer ${acesstoken}` } }
      )
      if (response?.data) {
        console.log(response?.data)
        filterdata = {
          ...filterdata,
          [dataSourceType]: response?.data?.data
        }
        // setFilterdata({
        //   ...filterdata,
        //   [dataSourceType]: response?.data?.data
        // })
      }
    } catch (err) {
      setError(err)
    }
    finally {
      setLoading(false)
    }
  }
  const disabledDate = current => {
    const today = dayjs();
    const oneMonthAgo = today.subtract(30, 'days');


    // Define the disabled date function
    // Disable dates that are not within the allowed range
    if (trailversion) {
      if (current > today || current < oneMonthAgo) {
        return true
      }
      return false
    }
    else {
      if (current > today) {
        return true
      }
      return false
    }
  }

  const arrivalFilter = [
    {
      title: 'Timestamp UTC',
      type: 'date',
      key: 'date',
      key1: 'ata_utc_start',
      key2: 'ata_utc_end'
    },
    {
      title: 'Ship Name',
      type: 'select',
      key: 'ship_name'
    },
    {
      title: 'Ship Type',
      type: 'select',
      key: 'ship_type'
    },
    {
      title: 'Vessel Class',
      type: 'select',
      key: 'size_class'
    },
    {
      title: 'DWT',
      type: 'children',
      children: [
        {
          title: 'Min',
          type: 'text',
          key: 'dwt_start'
        },
        {
          title: 'Max',
          type: 'text',
          key: 'dwt_end'
        }
      ]
    },
    {
      title: 'Estimated Cargo Load',
      type: 'children',
      children: [
        {
          title: 'Min',
          type: 'text',
          key: 'total_cargo_load_start'
        },
        {
          title: 'Max',
          type: 'text',
          key: 'total_cargo_load_end'
        }
      ]
    },
    {
      title: 'Buyer',
      type: 'select',
      key: 'mill'
    },
    {
      title: 'Arrival Country',
      type: 'select',
      key: 'dest_country'
    },
    {
      title: 'Arrival Port',
      type: 'select',
      key: 'actual_dest'
    },
    {
      title: 'Supplier',
      type: 'select',
      key: 'company'
    },
    {
      title: 'Origin Country',
      type: 'select',
      key: 'country'
    },
    {
      title: 'Origin Port',
      type: 'select',
      key: 'previous_port_name'
    },

    {
      title: 'Co Loading Port',
      type: 'select',
      key: 'co_load'
    }
  ]

  const ironOreArrivalFilter = [
    {
      title: 'Timestamp UTC',
      type: 'date',
      key: 'date',
      key1: 'ata_utc_start',
      key2: 'ata_utc_end'
    },
    {
      title: 'Ship Name',
      type: 'select',
      key: 'ship_name'
    },
    {
      title: 'Ship Type',
      type: 'select',
      key: 'ship_type'
    },
    {
      title: 'Vessel Class',
      type: 'select',
      key: 'size_class'
    },
    {
      title: 'DWT',
      type: 'children',
      children: [
        {
          title: 'Min',
          type: 'text',
          key: 'dwt_start'
        },
        {
          title: 'Max',
          type: 'text',
          key: 'dwt_end'
        }
      ]
    },
    {
      title: 'Estimated Cargo Load',
      type: 'children',
      children: [
        {
          title: 'Min',
          type: 'text',
          key: 'arr_total_cargo_load_start'
        },
        {
          title: 'Max',
          type: 'text',
          key: 'arr_total_cargo_load_end'
        }
      ]
    },
    {
      title: 'Buyer',
      type: 'select',
      key: 'mill'
    },
    {
      title: 'Arrival Country',
      type: 'select',
      key: 'actual_dest_country'
    },
    {
      title: 'Arrival Port',
      type: 'select',
      key: 'actual_dest'
    },
    {
      title: 'Co Discharging Port',
      type: 'select',
      key: 'co_discharge'
    },
    {
      title: 'Supplier',
      type: 'select',
      key: 'company'
    },
    {
      title: 'Origin Country',
      type: 'select',
      key: 'country'
    },
    {
      title: 'Origin Port',
      type: 'select',
      key: 'previous_port_name'
    },

    {
      title: 'Co Loading Port',
      type: 'select',
      key: 'co_load'
    }
  ]

  const driHbiArrivalFilter = [
    {
      title: 'Timestamp UTC',
      type: 'date',
      key: 'date',
      key1: 'ata_utc_start',
      key2: 'ata_utc_end'
    },
    {
      title: 'Ship Name',
      type: 'select',
      key: 'ship_name'
    },
    {
      title: 'Ship Type',
      type: 'select',
      key: 'ship_type'
    },
    {
      title: 'Vessel Class',
      type: 'select',
      key: 'size_class'
    },
    {
      title: 'DWT',
      type: 'children',
      children: [
        {
          title: 'Min',
          type: 'text',
          key: 'dwt_start'
        },
        {
          title: 'Max',
          type: 'text',
          key: 'dwt_end'
        }
      ]
    },
    {
      title: 'Estimated Cargo Load',
      type: 'children',
      children: [
        {
          title: 'Min',
          type: 'text',
          key: 'total_cargo_load_start'
        },
        {
          title: 'Max',
          type: 'text',
          key: 'total_cargo_load_end'
        }
      ]
    },
    {
      title: 'Buyer',
      type: 'select',
      key: 'mill'
    },
    {
      title: 'Arrival Country',
      type: 'select',
      key: 'dest_country'
    },
    {
      title: 'Arrival Port',
      type: 'select',
      key: 'actual_dest'
    },
    {
      title: 'Co Discharging Port',
      type: 'select',
      key: 'co_discharge'
    },
    {
      title: 'Supplier',
      type: 'select',
      key: 'company'
    },
    {
      title: 'Origin Country',
      type: 'select',
      key: 'country'
    },
    {
      title: 'Origin Port',
      type: 'select',
      key: 'previous_port_name'
    },

    {
      title: 'Co Loading Port',
      type: 'select',
      key: 'co_load'
    }
  ]
  const shipmentFilter = [
    {
      title: 'Timestamp UTC',
      type: 'date',
      key: 'date',
      key1: 'timestamp_utc_start',
      key2: 'timestamp_utc_end'
    },
    {
      title: 'Ship Name',
      type: 'select',
      key: 'ship_name'
    },
    {
      title: 'Ship Type',
      type: 'select',
      key: 'ship_type'
    },
    {
      title: 'Vessel Class',
      type: 'select',
      key: 'size_class'
    },
    {
      title: 'DWT',
      type: 'children',
      children: [
        {
          title: 'Min',
          type: 'text',
          key: 'dwt_start'
        },
        {
          title: 'Max',
          type: 'text',
          key: 'dwt_end'
        }
      ]
    },
    {
      title: 'Estimated Cargo Load',
      type: 'children',
      children: [
        {
          title: 'Min',
          type: 'text',
          key: 'total_cargo_load_start'
        },
        {
          title: 'Max',
          type: 'text',
          key: 'total_cargo_load_end'
        }
      ]
    },
    {
      title: 'Supplier',
      type: 'select',
      key: 'company'
    },
    {
      title: 'Departure Country',
      type: 'select',
      key: 'country'
    },
    {
      title: 'Departure Port',
      type: 'select',
      key: 'port_name'
    },
    // {
    //   title: 'Reported ETA',
    //   type: 'date',
    //   key: 'reporteddate',
    //   key1: 'reported_eta_start',
    //   key2: 'reported_eta_end'
    // },
    {
      title: 'Co Loading Port',
      type: 'select',
      key: 'co_load'
    },
    {
      title: 'Destination Country',
      type: 'select',
      key: 'dest_country'
    },
    {
      title: 'Destination Port',
      type: 'select',
      key: 'destination'
    },
    {
      title: 'Loading Rate (t/hr)',
      type: 'children',
      children: [
        {
          title: 'Min',
          type: 'text',
          key: 'loading_rate_start'
        },
        {
          title: 'Max',
          type: 'text',
          key: 'loading_rate_end'
        }
      ]
    }
  ]

  const loadingFilter = [
    {
      title: 'Arrival Time',
      type: 'date',
      key: 'date',
      key1: 'timestamp_utc_start',
      key2: 'timestamp_utc_end'
    },
    {
      title: 'Ship Name',
      type: 'select',
      key: 'ship_name'
    },
    {
      title: 'Ship Type',
      type: 'select',
      key: 'ship_type'
    },
    {
      title: 'Vessel Class',
      type: 'select',
      key: 'size_class'
    },
    {
      title: 'DWT',
      type: 'children',
      children: [
        {
          title: 'Min',
          type: 'text',
          key: 'dwt_start'
        },
        {
          title: 'Max',
          type: 'text',
          key: 'dwt_end'
        }
      ]
    },
    {
      title: 'Supplier',
      type: 'select',
      key: 'company'
    },
    {
      title: 'Load Country',
      type: 'select',
      key: 'country'
    },
    {
      title: 'Load Port',
      type: 'select',
      key: 'port_name'
    },
    {
      title: 'Co Loading Port',
      type: 'select',
      key: 'co_load'
    },
  ]

  const bargesFilter = [
    {
      title: 'Timestamp UTC',
      type: 'date',
      key: 'date',
      key1: 'ata_utc_start',
      key2: 'ata_utc_end'
    },
    {
      title: 'Dep Timestamp UTC',
      type: 'date',
      key: 'atd_utc',
      key1: 'atd_utc_start',
      key2: 'atd_utc_end'
    },
    {
      title: 'Ship Name',
      type: 'select',
      key: 'ship_name'
    },
    {
      title: 'Ship Type',
      type: 'select',
      key: 'ship_type'
    },
    {
      title: 'Estimated Cargo Load',
      type: 'children',
      children: [
        {
          title: 'Min',
          type: 'text',
          key: 'total_cargo_load_start'
        },
        {
          title: 'Max',
          type: 'text',
          key: 'total_cargo_load_end'
        }
      ]
    },
    {
      title: 'Buyer',
      type: 'select',
      key: 'mill'
    },
    {
      title: 'Arrival Country',
      type: 'select',
      key: 'actual_dest_country'
    },
    {
      title: 'Arrival Port',
      type: 'select',
      key: 'actual_dest'
    },
    {
      title: 'Supplier',
      type: 'select',
      key: 'company'
    },
    {
      title: 'Origin Country',
      type: 'select',
      key: 'country'
    },
    {
      title: 'Origin Port',
      type: 'select',
      key: 'port_name'
    }
  ]

  const intransitFilter = [
    {
      title: 'Timestamp UTC',
      type: 'date',
      key: 'date',
      key1: 'timestamp_utc_start',
      key2: 'timestamp_utc_end'
    },
    {
      title: 'Ship Name',
      type: 'select',
      key: 'ship_name'
    },
    {
      title: 'Ship Type',
      type: 'select',
      key: 'ship_type'
    },
    {
      title: 'Vessel Class',
      type: 'select',
      key: 'size_class'
    },
    {
      title: 'DWT',
      type: 'children',
      children: [
        {
          title: 'Min',
          type: 'text',
          key: 'dwt_start'
        },
        {
          title: 'Max',
          type: 'text',
          key: 'dwt_end'
        }
      ]
    },
    {
      title: 'Estimated Cargo Load',
      type: 'children',
      children: [
        {
          title: 'Min',
          type: 'text',
          key: 'total_cargo_load_start'
        },
        {
          title: 'Max',
          type: 'text',
          key: 'total_cargo_load_end'
        }
      ]
    },
    {
      title: 'Supplier',
      type: 'select',
      key: 'company'
    },
    {
      title: 'Departure Country',
      type: 'select',
      key: 'country'
    },
    {
      title: 'Departure Port',
      type: 'select',
      key: 'port_name'
    },
    // {
    //   title: 'Reported ETA',
    //   type: 'date',
    //   key: 'reporteddate',
    //   key1: 'reported_eta_start',
    //   key2: 'reported_eta_end'
    // },
    {
      title: 'Co Loading Port',
      type: 'select',
      key: 'co_load'
    },
    {
      title: 'Expected Buyer',
      type: 'select',
      key: 'expected_buyer'
    },
    {
      title: 'Destination Country',
      type: 'select',
      key: 'dest_country'
    },
    {
      title: 'Destination Port',
      type: 'select',
      key: 'destination'
    },
    {
      title: 'Loading Rate (t/hr)',
      type: 'children',
      children: [
        {
          title: 'Min',
          type: 'text',
          key: 'loading_rate_start'
        },
        {
          title: 'Max',
          type: 'text',
          key: 'loading_rate_end'
        }
      ]
    }
  ]
  let filtersdata =
    dataSourceType == 'arrival' ? comodity == 'drihbi' ? driHbiArrivalFilter : comodity == 'ironore' ? ironOreArrivalFilter : arrivalFilter : dataSourceType == 'loading' ? loadingFilter : (dataSourceType == 'departure' || dataSourceType == 'shipment') ? shipmentFilter : dataSourceType == 'barges' ? bargesFilter : intransitFilter

  useEffect(() => {
    getSavedSearches()
  }, [comodity, dataSourceType, dataSource, isSavedFilterCall])

  const getSavedSearches = async () => {
    try {
      const response = await axios.post(
        `/get-saved-searches`,
        SavedFilterMapping(comodity, "fixture", dataSourceType)
        ,
        { headers: { Authorization: `Bearer ${acesstoken}` } }
      )
      if (response?.data) {
        console.log(response?.data)
        SetSavedSearches(response?.data.data);
        if (isSavedFilterCall)
          setOpenManageSearch(true);
      }
    } catch (err) {
      console.log(err);
    }
    finally {
    }
  }

  const onSearchClick = (searchId) => {
    const searchObj = savedSearches.filter(s => { return s.id === searchId });
    if (searchObj.length > 0) {
      setFetchUniqueValues(true);
      searchObj[0]['isFromSavedFilter'] = true
      setSelectedSavedSearch(searchObj[0]);
      const applySavedfilter = mapFilterObject(filtersdata, searchObj[0]);
      setSetShipmentFiltersdata(applySavedfilter);
    }
  }
  const onDeleteAllSearches = async (search_type) => {
    try {
      const response = await axios.post(
        `/search/delete/all`,
        { search_type: search_type, type: SavedFilterMapping(comodity, "fixture", dataSourceType).type },
        { headers: { Authorization: `Bearer ${acesstoken}` } }
      )
      if (response?.data) {
        console.log(response?.data)
        getSavedSearches();
      }
    } catch (err) {
      console.log(err);
    }
    finally {
    }
  }
  const onSearchMenuClick = async (key, searchObj) => {
    // Save Operation
    if (key == 1) {
      try {
        const response = await axios.post(
          `/search/rename`,
          { id: searchObj.id, title: searchObj.title },
          { headers: { Authorization: `Bearer ${acesstoken}` } }
        )
        if (response?.data) {
          console.log(response?.data)
        }
      } catch (err) {
        console.log(err);
      }
      finally {
      }
      try {
        const response = await axios.post(
          `/search/save`,
          { id: searchObj.id },
          { headers: { Authorization: `Bearer ${acesstoken}` } }
        )
        if (response?.data) {
          console.log(response?.data)
          getSavedSearches();
        }
      } catch (err) {
        console.log(err);
      }
      finally {
      }
    }
    // Delete Operation
    if (key == 2) {
      try {
        const response = await axios.post(
          `/search/delete`,
          { id: searchObj.id },
          { headers: { Authorization: `Bearer ${acesstoken}` } }
        )
        if (response?.data) {
          console.log(response?.data)
          getSavedSearches();
        }
      } catch (err) {
        console.log(err);
      }
      finally {
      }
    }
  }
  console.log(shipmentFilterUpdated)

  const parsedSelectData = (data) => {
    if (data == undefined || data == [])
      return []
    const dataArray = data.split(",");
    for (let index = 0; index < dataArray.length; index++) {
      dataArray[index] = dataArray[index].toString().replace(new RegExp('_', 'g'), ",");
    }
    return dataArray;
  }

  const updateDoubleValueIfNotSet = () => {
    shipmentFilterUpdated["save_search"] = 1;
    if (trailversion && !shipmentFilterUpdated.date) {
      if (dataSourceType == 'arrival')
        shipmentFilterUpdated = { ...shipmentFilterUpdated, ata_utc_end: defaultArrivalFilter.ata_utc_end, ata_utc_start: defaultArrivalFilter.ata_utc_start, date: defaultArrivalFilter.date };
      else if (dataSourceType == 'loading')
        shipmentFilterUpdated = { ...shipmentFilterUpdated, timestamp_utc_start: defaultLoadingFilter.timestamp_utc_start, timestamp_utc_end: defaultLoadingFilter.timestamp_utc_end, date: defaultArrivalFilter.date };
      else if (dataSourceType == 'departure' || dataSourceType == 'shipment')
        shipmentFilterUpdated = { ...shipmentFilterUpdated, timestamp_utc_start: defaultShipmentFilter.timestamp_utc_start, timestamp_utc_end: defaultShipmentFilter.timestamp_utc_end, date: defaultArrivalFilter.date };
      else if (dataSourceType == 'barges')
        shipmentFilterUpdated = { ...shipmentFilterUpdated, ata_utc_end: defaultBargesFilter.ata_utc_end, ata_utc_start: defaultBargesFilter.ata_utc_start, date: defaultBargesFilter.date };
      else if (dataSourceType == 'intransit')
        shipmentFilterUpdated = { ...shipmentFilterUpdated, timestamp_utc_start: defaultIntransitFilter.timestamp_utc_start, timestamp_utc_end: defaultIntransitFilter.timestamp_utc_end, date: defaultIntransitFilter.date };

    }

    if (shipmentFilterUpdated["dwt_start"] === null && shipmentFilterUpdated["dwt_end"] === null) {
      delete shipmentFilterUpdated["dwt_start"];
      delete shipmentFilterUpdated["dwt_end"];
    }
    else if ("dwt_start" in shipmentFilterUpdated && (!("dwt_end" in shipmentFilterUpdated) || (shipmentFilterUpdated["dwt_end"] == null))) {
      shipmentFilterUpdated["dwt_end"] = 1000000
    }
    else if ((!("dwt_start" in shipmentFilterUpdated) || (shipmentFilterUpdated["dwt_start"] == null)) && ("dwt_end" in shipmentFilterUpdated)) {
      shipmentFilterUpdated["dwt_start"] = 1
    }
    if (shipmentFilterUpdated["total_cargo_load_start"] === null && shipmentFilterUpdated["total_cargo_load_end"] === null) {
      delete shipmentFilterUpdated["total_cargo_load_start"];
      delete shipmentFilterUpdated["total_cargo_load_end"];
    }
    else if ("total_cargo_load_start" in shipmentFilterUpdated && (!("total_cargo_load_end" in shipmentFilterUpdated) || (shipmentFilterUpdated["total_cargo_load_end"] == null))) {
      shipmentFilterUpdated["total_cargo_load_end"] = 1000000
    }
    else if ((!("total_cargo_load_start" in shipmentFilterUpdated) || (shipmentFilterUpdated["total_cargo_load_start"] == null)) && ("total_cargo_load_end" in shipmentFilterUpdated)) {
      shipmentFilterUpdated["total_cargo_load_start"] = 1
    }
    // For iron ore total cargo load 
    if (shipmentFilterUpdated["arr_total_cargo_load_start"] === null && shipmentFilterUpdated["arr_total_cargo_load_end"] === null) {
      delete shipmentFilterUpdated["arr_total_cargo_load_start"];
      delete shipmentFilterUpdated["arr_total_cargo_load_end"];
    }
    else if ("arr_total_cargo_load_start" in shipmentFilterUpdated && (!("arr_total_cargo_load_end" in shipmentFilterUpdated) || (shipmentFilterUpdated["arr_total_cargo_load_end"] == null))) {
      shipmentFilterUpdated["arr_total_cargo_load_end"] = 1000000
    }
    else if ((!("arr_total_cargo_load_start" in shipmentFilterUpdated) || (shipmentFilterUpdated["arr_total_cargo_load_start"] == null)) && ("arr_total_cargo_load_end" in shipmentFilterUpdated)) {
      shipmentFilterUpdated["arr_total_cargo_load_start"] = 1
    }

    if (shipmentFilterUpdated["loading_rate_start"] === null && shipmentFilterUpdated["loading_rate_end"] === null) {
      delete shipmentFilterUpdated["loading_rate_start"];
      delete shipmentFilterUpdated["loading_rate_end"];
    }
    else if ("loading_rate_start" in shipmentFilterUpdated && (!("loading_rate_end" in shipmentFilterUpdated) || (shipmentFilterUpdated["loading_rate_end"] == null))) {
      shipmentFilterUpdated["loading_rate_end"] = 1000000
    }
    else if ((!("loading_rate_start" in shipmentFilterUpdated) || (shipmentFilterUpdated["loading_rate_start"] == null)) && ("loading_rate_end" in shipmentFilterUpdated)) {
      shipmentFilterUpdated["loading_rate_start"] = 1
    }
  }
  return (
    <>
      <Drawer
        placement={placement}
        closable={closable}
        onClose={onClose}
        open={loadings == true ? false : open}
        className='antd-cutom-drawer'
      >
        {loading == true ? <div className='loader-container'>
          <Spin />
        </div> : null}
        <div className='border-b border-blue-200 pt-4 pb-4 ps-5 pe-5  flex justify-between'>
          {
            placement == "right" ?
              <div
                className='bg-white absolute w-11 transform rotate-180 top-2/4 -left-11  h-12  flex justify-center items-center rounded-tr rounded-br cursor-pointer arrows'
                onClick={onClose}
              >
                <LeftArrow />
              </div>
              :
              <div
                className='bg-white absolute w-11 transform top-2/4 -right-11  h-12  flex justify-center items-center rounded-tr rounded-br cursor-pointer arrows'
                onClick={onClose}
              >
                <LeftArrow />
              </div>
          }
          <div className='filter-header'>
            <div className='flex'> <FilterIcon />
              <p className='font-medium text-base leading-5 ml-1'>Filters</p>
            </div>
            <div className='manage-search'>
              <div className='manage-search-icon'><SaveSetting /></div>
              <Button type='link' className='manage-searches-button' onClick={() => { setOpenManageSearch(true) }} >Manage Searches</Button>
            </div>
          </div>
        </div>
        <div className='apply-searches antd-custom-drawer-form-wrapper p-7 filter-data '>
          <label className='font-semibold'>{"Apply Saved Searches"} </label>
          <Select
            //showSearch
            // name='ship-name'
            // notFoundContent={loading ? <LoadingOutlined style={{ fontSize: 24,color:'#0071c1',marginLeft:'47%' }} spin /> : null}
            value={{ value: selectedSavedSearch?.id, label: selectedSavedSearch?.title }}
            notFoundContent={<label style={{ textAlign: "center", display: "block" }}  >{"No data exists"}</label>}
            onChange={value => {
              onSearchClick(value);
            }}
            style={{
              color: "black"
            }}
            variant="filled"
            options={savedSearches.filter(s => {
              return s.save_serach == 1;
            }).map(filterSearch => {
              return { value: filterSearch.id, label: filterSearch.title }
            })}
          />
        </div>
        <div className='antd-custom-drawer-form-wrapper p-7 '>
          <ManageSearches setIsSavedFilterCall={setIsSavedFilterCall} isSavedFilterCall={isSavedFilterCall} openManageSearch={openManageSearch} filtersdata={filtersdata} savedSearches={savedSearches} onSearchMenuClick={onSearchMenuClick} onDeleteAllSearches={onDeleteAllSearches} onClose={() => { setOpenManageSearch(false) }} />
          <div className='filter-data'>
            {filtersdata?.map(item => {
              if (item?.type == 'select') {
                return (
                  <>
                    <label className='font-semibold'>{item?.title} </label>
                    <Select
                      //showSearch
                      // name='ship-name'
                      value={
                        parsedSelectData(shipmentFilterUpdated?.[item?.key])
                      }
                      // notFoundContent={loading ? <LoadingOutlined style={{ fontSize: 24,color:'#0071c1',marginLeft:'47%' }} spin /> : null}
                      notFoundContent={loading ? <Spin style={{ color: '#0071c1', marginLeft: '47%' }} /> : <label style={{ textAlign: "center", display: "block" }}  >{"No data exists"}</label>}
                      placeholder="Please select"
                      onChange={value => {
                        console.log(shipmentFilterUpdated)
                        for (let index = 0; index < value.length; index++) {
                          value[index] = value[index].toString().replace(new RegExp(',', 'g'), "_");
                        }
                        setFetchUniqueValues(false);
                        setSetShipmentFiltersdata({
                          ...shipmentFilterUpdated,
                          [item?.key]: value.toString()
                        })
                      }}
                      onBlur={v => {
                        if (item?.key !== 'ship_name' && item?.key !== 'ship_type' && item?.key !== 'mill' && item?.key !== 'company' && item?.key !== 'co_load' && item?.key !== 'load_berth')
                          setFetchUniqueValues(true)
                      }}
                      style={{
                        color: "black"
                      }}
                      variant="filled"
                      mode='multiple'
                      options={filterdata[dataSourceType]?.[item?.key]?.map(i => {
                        return { value: i, label: i }
                      })}
                    />
                  </>
                )
              } else if (item?.type == 'children') {
                return (
                  <div key={item.key}>
                    <label className='font-semibold'>{item?.title}</label>
                    <div className='flex justify-between'>
                      {item?.children?.map(item => {
                        return (
                          <>
                            <label>{item?.title}</label>
                            <InputNumber
                              value={shipmentFilterUpdated?.[item?.key]}
                              onChange={value => {
                                setFetchUniqueValues(false);
                                setSetShipmentFiltersdata({
                                  ...shipmentFilterUpdated,
                                  [item?.key]: value
                                })
                              }
                              }
                            />
                          </>
                        )
                      })}
                    </div>
                  </div>
                )
              } else if (item?.type == 'date') {
                return (
                  <>
                    {' '}
                    <label className='font-semibold'>{item?.title}</label>
                    {trailversion == false ? (
                      <RangePicker
                        name='date-range'
                        className='w-full'
                        // value={shipmentFilterUpdated?.[item?.key]}
                        value={
                          shipmentFilterUpdated?.[item?.key1] == undefined
                            ? null
                            : [
                              dayjs(
                                shipmentFilterUpdated?.[item?.key1] ||
                                calculateDefaultDate()?.[0],
                                'YYYY-MM-DD'
                              ),
                              dayjs(
                                shipmentFilterUpdated?.[item?.key2] ||
                                calculateDefaultDate()?.[1],
                                'YYYY-MM-DD'
                              )
                            ]
                        }
                        format={'YYYY-MM-DD'}
                        disabledDate={disabledDate}
                        onChange={(dates, dateStrings) => {
                          console.log(dateStrings)
                          setFetchUniqueValues(false);
                          if (dateStrings[0].length == 0 && dateStrings[1].length == 0) {
                            if (dataSourceType == 'barges') {
                              const updatedFilters = shipmentFilterUpdated;
                              delete updatedFilters[item?.key];
                              delete updatedFilters[item?.key1];
                              delete updatedFilters[item?.key2];
                              setSetShipmentFiltersdata({
                                ...updatedFilters,
                              });
                            } else if (dataSourceType == 'arrival') {
                              const { ata_utc_start, ata_utc_end, date, ...updatedFilters } = shipmentFilterUpdated;
                              setSetShipmentFiltersdata({
                                ...updatedFilters,
                              });
                            }
                            else {
                              const { timestamp_utc_start, timestamp_utc_end, date, ...updatedFilters } = shipmentFilterUpdated;
                              setSetShipmentFiltersdata({
                                ...updatedFilters,
                              });
                            }
                          }
                          else {
                            setSetShipmentFiltersdata({
                              ...shipmentFilterUpdated,
                              [item?.key]: dateStrings,
                              [item?.key1]:
                                dateStrings?.[0] == undefined
                                  ? ''
                                  : dateStrings?.[0],
                              [item?.key2]:
                                dateStrings?.[0] == undefined
                                  ? ''
                                  : dateStrings?.[1]
                            })
                          }
                        }}
                      />
                    ) : (
                      <RangePicker
                        name='date-range'
                        className='w-full'
                        // value={shipmentFilterUpdated?.[item?.key]}
                        value={
                          [
                            dayjs(
                              shipmentFilterUpdated?.[item?.key1] ||
                              calculateDefaultDate()?.[0],
                              'YYYY-MM-DD'
                            ),
                            dayjs(
                              shipmentFilterUpdated?.[item?.key2] ||
                              calculateDefaultDate()?.[1],
                              'YYYY-MM-DD'
                            )
                          ]
                        }
                        format={'YYYY-MM-DD'}
                        disabledDate={disabledDate}
                        onChange={(dates, dateStrings) => {
                          console.log(dateStrings)
                          setFetchUniqueValues(false);
                          if (dateStrings[0].length == 0 && dateStrings[1].length == 0) {
                            if (dataSourceType == 'barges') {
                              const updatedFilters = shipmentFilterUpdated;
                              delete updatedFilters[item?.key];
                              delete updatedFilters[item?.key1];
                              delete updatedFilters[item?.key2];
                              setSetShipmentFiltersdata({
                                ...updatedFilters,
                              });
                            } else if (dataSourceType == 'arrival') {
                              const { ata_utc_start, ata_utc_end, date, ...updatedFilters } = shipmentFilterUpdated;
                              setSetShipmentFiltersdata({
                                ...updatedFilters,
                              });
                            }
                            else {
                              const { timestamp_utc_start, timestamp_utc_end, date, ...updatedFilters } = shipmentFilterUpdated;
                              setSetShipmentFiltersdata({
                                ...updatedFilters,
                              });
                            }
                          }
                          else {
                            setSetShipmentFiltersdata({
                              ...shipmentFilterUpdated,
                              [item?.key]: dateStrings,
                              [item?.key1]:
                                dateStrings?.[0] == undefined
                                  ? ''
                                  : dateStrings?.[0],
                              [item?.key2]:
                                dateStrings?.[0] == undefined
                                  ? ''
                                  : dateStrings?.[1]
                            })
                          }
                        }}
                      />
                    )}
                  </>
                )
              }
            })}
          </div>
        </div>
        <div className='bg-white pr-3 pt-5 pb-5 flex justify-between w-full absolute bottom-0 gap-2'>
          <Button
            type='link'
            className='font-semibold text-base'
            onClick={() => {
              // form.resetFields()
              // shipform.resetFields()
              const tableWrapper = document.querySelector('.ant-table-wrapper');
              if (tableWrapper) {
                tableWrapper.scrollLeft = 0;
              }
              setSelectedSavedSearch({});
              var url = null
              if (trailversion) {
                if (dataSourceType == 'arrival') {
                  url = `/${comodity}/fixtures/filter/arrivals?limit=${pageSizes}&page=${1}`
                }
                else if (dataSourceType == 'loading') {
                  url = `/${comodity}/fixtures/filter/loading?limit=${pageSizes}&page=${1}`
                } else if (dataSourceType == 'departure' || dataSourceType == 'shipment') {
                  url = `/${comodity}/fixtures/filter/shipments?limit=${pageSizes}&page=${1}`
                } else if (dataSourceType == 'barges') {
                  url = `${comodity == 'shipData' ? '' : comodity}/barges/fixtures/filter/arrivals?limit=${pageSizes}&page=${1}`
                } else if (dataSourceType == 'intransit') {
                  url = `${comodity == 'shipData' ? '' : comodity}/intransit/fixtures/filter/data?limit=${pageSizes}&page=${1}`
                }
                setSetShipmentFiltersdata(null)
                setFilterApply(false)
                getfixturesFilterDatas(url, dataSourceType == 'arrival' ? defaultArrivalFilter : dataSourceType == 'loading' ? defaultLoadingFilter : (dataSourceType == 'departure' || dataSourceType == 'shipment') ? defaultShipmentFilter : dataSourceType == 'barges' ? defaultBargesFilter : defaultIntransitFilter)
              }
              else {
                if (dataSourceType == 'arrival') {
                  url = `/${comodity}/fixtures/arrival-data-in-chunks?limit=${pageSizes}&page=${1}`
                }
                else if (dataSourceType == 'loading') {
                  url = `/${comodity}/fixtures/loading-data-in-chunks?limit=${pageSizes}&page=${1}`
                } else if (dataSourceType == 'departure' || dataSourceType == 'shipment') {
                  url = `/${comodity}/fixtures/shipment-data-in-chunks?limit=${pageSizes}&page=${1}`
                } else if (dataSourceType == 'barges') {
                  url = `${comodity == 'shipData' ? '' : comodity}/barges/fixtures/arrival-data-in-chunks?limit=${pageSizes}&page=${1}`
                } else if (dataSourceType == 'intransit') {
                  url = `${comodity == 'shipData' ? '' : comodity}/intransit/fixtures/data-in-chunks?limit=${pageSizes}&page=${1}`
                }
                setSetShipmentFiltersdata(null)
                setFilterApply(false)
                setAppliedFilter(false)
                getfixturesData(url)
              }
              onClose()
            }}
          >
            Clear Filters
          </Button>

          <Button
            type='primary'
            className='font-semibold text-base '
            style={{ lineHeight: 0, padding: '10px', gap: '10px' }}
            onClick={() => {
              updateDoubleValueIfNotSet();
              if (dataSourceType == 'arrival') {
                saveFilterCall(
                  `/${comodity}/fixtures/filter/arrivals?limit=10&page=${1}`,
                  {
                    ...shipmentFilterUpdated
                  }
                )
              } else if (dataSourceType == 'loading') {
                saveFilterCall(
                  `/${comodity}/fixtures/filter/loading?limit=10&page=${1}`,
                  {
                    ...shipmentFilterUpdated
                  }
                )
              } else if (dataSourceType == 'departure' || dataSourceType == 'shipment') {
                saveFilterCall(
                  `/${comodity}/fixtures/filter/shipments?limit=10&page=${1}`,
                  {
                    ...shipmentFilterUpdated
                  }
                )
              } else if (dataSourceType == 'barges') {
                saveFilterCall(
                  `${comodity == 'shipData' ? '' : comodity}/barges/fixtures/filter/arrivals?limit=10&page=${1}`,
                  {
                    ...shipmentFilterUpdated
                  }
                )
              } else if (dataSourceType == 'intransit') {
                saveFilterCall(
                  `${comodity == 'shipData' ? '' : comodity}/intransit/fixtures/filter/data?limit=10&page=${1}`,
                  {
                    ...shipmentFilterUpdated
                  }
                )
              }

            }}> Save Filter</Button>

          <Button
            type='primary'
            className='font-semibold text-base '
            style={{ lineHeight: 0, padding: '10px' }}
            onClick={() => {
              const tableWrapper = document.querySelector('.ant-table-wrapper');
              if (tableWrapper) {
                tableWrapper.scrollLeft = 0;
              }
              setFilterApply(true)
              setAppliedFilter(true)

              updateDoubleValueIfNotSet();
              setSetShipmentFiltersdata(shipmentFilterUpdated)
              if (dataSourceType == 'arrival') {
                getfixturesFilterDatas(
                  `/${comodity}/fixtures/filter/arrivals?limit=${pageSizes}&page=${1}`,
                  {
                    ...shipmentFilterUpdated
                  }
                )
              } else if (dataSourceType == 'loading') {
                setAppliedFilter(true)
                getfixturesFilterDatas(
                  `/${comodity}/fixtures/filter/loading?limit=${pageSizes}&page=${1}`,
                  {
                    ...shipmentFilterUpdated
                  }
                )
              } else if (dataSourceType == 'departure' || dataSourceType == 'shipment') {
                setAppliedFilter(true)
                getfixturesFilterDatas(
                  `/${comodity}/fixtures/filter/shipments?limit=${pageSizes}&page=${1}`,
                  {
                    ...shipmentFilterUpdated
                  }
                )
              } else if (dataSourceType == 'barges') {
                setAppliedFilter(true)
                getfixturesFilterDatas(
                  `${comodity == 'shipData' ? '' : comodity}/barges/fixtures/filter/arrivals?limit=${pageSizes}&page=${1}`,
                  {
                    ...shipmentFilterUpdated
                  }
                )
              }
              else if (dataSourceType == 'intransit') {
                setAppliedFilter(true)
                getfixturesFilterDatas(
                  `${comodity == 'shipData' ? '' : comodity}/intransit/fixtures/filter/data?limit=${pageSizes}&page=${1}`,
                  {
                    ...shipmentFilterUpdated
                  }
                )
              }
              onClose()
            }}
          >
            Apply Filters
          </Button>
        </div>
      </Drawer>
    </>
  )
}

export default FiltersDrawer
