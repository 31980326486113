import React, { useEffect, useState } from 'react'
import { Dropdown, Button, DatePicker, Select, Spin } from 'antd'
import './styles.css'
import dayjs from 'dayjs'
const { RangePicker } = DatePicker
import { LoadingOutlined } from '@ant-design/icons';


const CompanyDropdowns = React.memo(
  ({
    Filters,
    arrivalandshipment,
    selectSpecificFilter,
    applyFilter,
    resetFilter,
    trailversion,
    getCompaniesdata,
    setFilters,
    setFilterLoader,
    filterLoader,
    comodity,
    bargesAccessLevel
  }) => {

    console.log(filterLoader)
    // console.log(Filters?.applyfilter)
    const [selectedStartDate, setSelectedStartDate] = useState(null)
    const [selectedEndDate, setSelectedEndDate] = useState(null)
    const [selectedOp, setSelectedOp] = useState(0)
    useEffect(() => {

      const prevStartDate = dayjs(Filters?.applyfilter?.ata_utc_start, 'YYYY-MM-DD')
      const prevEndDate = dayjs(Filters?.applyfilter?.ata_utc_end, 'YYYY-MM-DD')
      let endDateToStartDateDiff = 1000
      let startDateToEndDateDiff = 1000

      if (selectedEndDate != null)
        endDateToStartDateDiff = Math.abs(selectedEndDate.diff(prevStartDate, 'day'));
      if (selectedStartDate != null)
        startDateToEndDateDiff = Math.abs(selectedStartDate.diff(prevEndDate, 'day'));

      if (selectedEndDate != null && selectedStartDate != null) {
        applyFilter([selectedStartDate, selectedEndDate], '', 'date')
        setSelectedEndDate(null)
        setSelectedStartDate(null)
      }
      else if (endDateToStartDateDiff < 365 && selectedEndDate > prevStartDate) {
        applyFilter([prevStartDate, selectedEndDate], '', 'date')
        setSelectedEndDate(null)
        setSelectedStartDate(null)
      }

    }, [selectedStartDate, selectedEndDate])

    // const disabledDate = current => {
    //   const currentStartDate = dayjs(Filters?.applyfilter?.ata_utc_start, 'YYYY-MM-DD')
    //   const currentEndDate = dayjs(Filters?.applyfilter?.ata_utc_end, 'YYYY-MM-DD')
    //   const today = dayjs()

    //   // console.log("selected start",selectedStartDate)
    //   // console.log("selected end",selectedEndDate)

    //   // Calculate the minimum allowed date (1 year ago from today)
    //   let minAllowedDate = today.subtract(10, 'year')
    //   if(selectedEndDate!==null){
    //     minAllowedDate = selectedEndDate.subtract(1, 'year')
    //   }
    //   else if(selectedStartDate!==null){
    //     minAllowedDate=selectedStartDate
    //   }

    //   // Calculate the maximum allowed date (today)
    //   let maxAllowedDate = today
    //   if(selectedStartDate!==null){
    //     const optionalMax=selectedStartDate.add(1,'year')
    //     if(today>optionalMax)
    //       maxAllowedDate = optionalMax
    //   }

    //   // console.log("max allowed",maxAllowedDate)
    //   // console.log("min allowed",minAllowedDate)
    //   // console.log("today",today)
    //   // Disable dates that are not within the allowed range
    //   if (today<current || current<minAllowedDate || current>maxAllowedDate) {
    //     return true
    //   }
    //   return false
    // }

    const disabledDate = current => {
      const currentStartDate = dayjs(Filters?.applyfilter?.ata_utc_start, 'YYYY-MM-DD')
      const currentEndDate = dayjs(Filters?.applyfilter?.ata_utc_end, 'YYYY-MM-DD')
      const today = dayjs();
      const activePicker = document.querySelector('.ant-picker-input-active');
      let openedPicker = activePicker?.childNodes[0].placeholder

      // console.log("selected start",selectedStartDate)
      // console.log("selected end",selectedEndDate)

      // Calculate the minimum allowed date (1 year ago from today)
      let minAllowedDate = trailversion ? today.subtract(30, 'days') : today.subtract(10, 'year')
      if (openedPicker == "End date") {
        if (selectedStartDate == null)
          minAllowedDate = currentStartDate
        else
          minAllowedDate = selectedStartDate
      }

      let maxAllowedDate = currentStartDate.add(1, 'year')
      if (selectedStartDate !== null) {
        const optionalMax = selectedStartDate.add(1, 'year')
        if (today > optionalMax)
          maxAllowedDate = optionalMax
        else
          maxAllowedDate = today
      }

      // console.log("max allowed",maxAllowedDate)
      // console.log("min allowed",minAllowedDate)
      // console.log("today",today)
      // Disable dates that are not within the allowed range
      if (today < current || current < minAllowedDate || current > maxAllowedDate) {
        return true
      }
      return false
    }

    console.log(arrivalandshipment)
    console.log(selectedOp)

    // console.log("selected start",selectedStartDate)
    // console.log("selected end",selectedEndDate)
    // console.log(Filters.applyfilter)
    return (
      <div className='company-dropdowns w-full flex flex-col md:flex-row justify-between'>
        <Select
          value={Filters?.type}
          onChange={e => {
            const start = new Date(
              new Date().setDate(new Date()?.getDate() - 29)
            )
            const end = new Date(
              new Date().setDate(new Date()?.getDate())
            )
            selectSpecificFilter(e)

            getCompaniesdata({
              ...Filters,
              applyfilter: {
                ata_utc_start: `${start?.getFullYear()}-${start?.getMonth() + 1 < 10
                  ? '0' + (start?.getMonth() + 1)
                  : start?.getMonth() + 1
                  }-${start?.getDate() < 10
                    ? '0' + start?.getDate()
                    : start?.getDate()
                  }`,
                ata_utc_end: `${end?.getFullYear()}-${end?.getMonth() + 1 < 10
                  ? '0' + (end.getMonth() + 1)
                  : end.getMonth() + 1
                  }-${end?.getDate() < 10 ? '0' + end?.getDate() : end?.getDate()
                  }`
              },
              type: e
            })
            setFilters({
              ...Filters,
              applyfilter: {
                ata_utc_start: `${start?.getFullYear()}-${start?.getMonth() + 1 < 10
                  ? '0' + (start?.getMonth() + 1)
                  : start?.getMonth() + 1
                  }-${start?.getDate() < 10
                    ? '0' + start?.getDate()
                    : start?.getDate()
                  }`,
                ata_utc_end: `${end?.getFullYear()}-${end?.getMonth() + 1 < 10
                  ? '0' + (end.getMonth() + 1)
                  : end.getMonth() + 1
                  }-${end?.getDate() < 10 ? '0' + end?.getDate() : end?.getDate()
                  }`
              },
              type: e
            })
            // resetFilter()
          }}
          options={
            (comodity == 'shipData' && bargesAccessLevel) ? [
              { value: 'arrivals', label: 'Arrivals' },
              { value: 'intransit', label: 'In Transit' },
              { value: 'Shipments', label: 'Shipments' },
              { value: 'barges', label: 'Barges' }

            ] : ((comodity == 'drihbi' || comodity == 'merchantiron')) ? [
              { value: 'arrivals', label: 'Arrivals' },
              { value: 'intransit', label: 'In Transit' },
              { value: 'Shipments', label: 'Shipments' },

            ] : ((comodity == 'stainlessscrap')) ? [
              { value: 'arrivals', label: 'Arrivals' },
              { value: 'intransit', label: 'In Transit' },
              { value: 'Shipments', label: 'Shipments' },
              { value: 'barges', label: 'Barges' }

            ] :
              [
                { value: 'arrivals', label: 'Arrivals' },
                { value: 'intransit', label: 'In Transit' },
                { value: 'Shipments', label: 'Shipments' },
                { value: 'barges', label: 'Barges' }

              ]}
        ></Select>
        {Object.keys(Filters?.currentFilter)?.map((dd_item, index) => {
          console.log(Filters)
          let option = [];
          Object.keys(arrivalandshipment)?.length > 0
            ? arrivalandshipment?.[Filters?.type] == undefined
              ? []
              : arrivalandshipment?.[Filters?.type]?.[
                Filters?.currentFilter[dd_item]
              ]?.map(item => {
                option.push({ value: item, label: item });
              })
            : (option = []);

          // Add the loading indicator if options.length is 1
          console.log(Filters)
          console.log(dd_item)
          if (filterLoader && option.length < 2) {
            option.push({
              value: 'loading',
              label: <Spin style={{ color: '#0071c1', marginLeft: '47%' }} />,
            });
          }
          else if (option[option.length - 1]?.value === 'loading') {
            option.pop();
          }
          console.log(Filters?.currentFilter[dd_item])

          // Remove the loading indicator if options.length becomes greater than 1
          // if (option.length > 1 && option[option.length - 1].value === 'loading') {
          //   option.pop();
          // }

          option = [
            {
              value: '',
              label: ` ${dd_item}`,
            },
            ...option,
          ];

          return (
            <Select
              //showSearch
              // style={{ width: 120 }}
              placeholder={` ${dd_item}`}
              onChange={e => {
                //getCompaniesdata()
                applyFilter(Filters?.currentFilter[dd_item], e);

              }}
              value={
                Filters?.applyfilter?.[Filters?.currentFilter[dd_item]] !=
                  undefined &&
                  arrivalandshipment?.[Filters?.type]?.[
                    Filters?.currentFilter[dd_item]
                  ]?.includes(
                    Filters?.applyfilter?.[Filters?.currentFilter[dd_item]]
                  )
                  ? Filters?.applyfilter?.[Filters?.currentFilter[dd_item]]
                  : ''
              }
              options={option}
            ></Select>
          );
        })}

        {trailversion == false ? (
          <RangePicker

            onCalendarChange={(props) => {
              const start = dayjs(Filters?.applyfilter?.ata_utc_start, 'YYYY-MM-DD')
              const end = dayjs(Filters?.applyfilter?.ata_utc_end, 'YYYY-MM-DD')

              // console.log(start)
              // console.log(props[0])
              // console.log("meh")
              // console.log(end)
              // console.log(props[1])
              const activePicker = document.querySelector('.ant-picker-input-active');
              let openedPicker = activePicker?.childNodes[0].placeholder

              if (props[1] !== null && openedPicker == "End date") {
                setSelectedEndDate(props[1])
              }
              if (props[0] !== null && props[0].toString() != start.toString()) {
                setSelectedStartDate(props[0])
              }
            }}
            defaultValue={[
              dayjs(Filters?.applyfilter?.ata_utc_start, 'YYYY-MM-DD'),
              dayjs(Filters?.applyfilter?.ata_utc_end, 'YYYY-MM-DD')
            ]}
            disabledDate={disabledDate}
            format={'YYYY-MM-DD'}
          />
        ) : (
          <RangePicker

            onCalendarChange={(props) => {
              const start = dayjs(Filters?.applyfilter?.ata_utc_start, 'YYYY-MM-DD')
              const end = dayjs(Filters?.applyfilter?.ata_utc_end, 'YYYY-MM-DD')

              // console.log(start)
              // console.log(props[0])
              // console.log("meh")
              // console.log(end)
              // console.log(props[1])
              const activePicker = document.querySelector('.ant-picker-input-active');
              let openedPicker = activePicker?.childNodes[0].placeholder

              if (props[1] !== null && openedPicker == "End date") {
                setSelectedEndDate(props[1])
              }
              if (props[0] !== null && props[0].toString() != start.toString()) {
                setSelectedStartDate(props[0])
              }
            }}
            defaultValue={[
              dayjs(Filters?.applyfilter?.ata_utc_start, 'YYYY-MM-DD'),
              dayjs(Filters?.applyfilter?.ata_utc_end, 'YYYY-MM-DD')
            ]}
            disabledDate={disabledDate}
            format={'YYYY-MM-DD'}
          />
        )}
        <Button
          type='primary'
          className='company-reset font-medium text-sm'
          onClick={() => {
            resetFilter()
            ////getCompaniesdata()
          }}
        >
          Reset Filter
        </Button>
      </div>
    )
  }
)

export default CompanyDropdowns
