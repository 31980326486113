import { EditOutlined } from "@ant-design/icons";
import PopoverDialog from "../Components/Misc/PopoverDialog";
import ChartPopOverDialog from "../Components/Misc/ChartPopOverDialog";

let flag = false;
export const transformDataSource = (data) => {
    return data?.map((item) => {
        if (item.expected_buyer) {

        }
        const dwt = parseInt(item.dwt, 10).toLocaleString();
        const total_cargo_load = item.total_cargo_load == null ? '-' : parseInt(item.total_cargo_load, 10).toLocaleString();
        const arr_total_cargo_load = item.arr_total_cargo_load == null ? '-' : parseInt(item.arr_total_cargo_load, 10).toLocaleString();
        const estimated_cargo_load = item.estimated_cargo_load == null ? '-' : parseInt(item.estimated_cargo_load, 10).toLocaleString();

        return { ...item, dwt, total_cargo_load, arr_total_cargo_load, estimated_cargo_load };
    });
};

export const MerchantDataColumns = {
    Arrivalcolumns: [
        {
            title: <p className='text-xs font-bold'>Timestamp UTC</p>,
            dataIndex: 'ata_utc'
        },
        {
            title: <p className='text-xs font-bold'>Ship Name</p>,
            dataIndex: 'ship_name'
        },
        {
            title: <p className='text-xs font-bold'>Ship Type</p>,
            dataIndex: 'ship_type'
        },
        {
            title: <p className='text-xs font-bold'>Vessel Class</p>,
            dataIndex: 'size_class'
        },
        {
            title: <p className='text-xs font-bold'>DWT</p>,
            dataIndex: 'dwt'
        },
        {
            title: <p className='text-xs font-bold'>Move Type</p>,
            dataIndex: 'move_type'
        },
        {
            title: <p className='text-xs font-bold'>Estimated Cargo Load (t)</p>,
            dataIndex: 'total_cargo_load'
        },
        {
            title: <p className='text-xs font-bold'>Buyer</p>,
            dataIndex: 'mill'
        },
        {
            title: <p className='text-xs font-bold'>Arrival Country</p>,
            dataIndex: 'dest_country'
        },
        {
            title: <p className='text-xs font-bold'>Arrival Port</p>,
            dataIndex: 'actual_dest'
        },
        {
            title: <p className='text-xs font-bold'>Arrival Berth</p>,
            dataIndex: 'dest_berth'
        },

        {
            title: <p className='text-xs font-bold'>Supplier</p>,
            dataIndex: 'company'
        },
        {
            title: <p className='text-xs font-bold'>Origin Country</p>,
            dataIndex: 'country'
        },
        {
            title: <p className='text-xs font-bold'>Origin Port</p>,
            dataIndex: 'previous_port_name'
        },
        {
            title: <p className='text-xs font-bold'>Origin Berth</p>,
            dataIndex: 'dep_berth'
        },
        {
            title: <p className='text-xs font-bold'>Co Loading Port</p>,
            dataIndex: 'co_load'
        }
    ],
    Shipmentcolumns: [
        {
            title: <p className='text-xs font-bold'>Timestamp UTC</p>,
            dataIndex: 'timestamp_utc'
        },
        {
            title: <p className='text-xs font-bold'>Ship Name</p>,
            dataIndex: 'ship_name'
        },
        {
            title: <p className='text-xs font-bold'>Ship Type</p>,
            dataIndex: 'ship_type'
        },
        {
            title: <p className='text-xs font-bold'>Vessel Class</p>,
            dataIndex: 'size_class'
        },
        {
            title: <p className='text-xs font-bold'>DWT</p>,
            dataIndex: 'dwt'
        },
        {
            title: <p className='text-xs font-bold'>Move Type</p>,
            dataIndex: 'move_type'
        },
        {
            title: <p className='text-xs font-bold'>Estimated Cargo Load (t)</p>,
            dataIndex: 'total_cargo_load'
        },
        {
            title: <p className='text-xs font-bold'>Supplier</p>,
            dataIndex: 'company'
        },
        {
            title: <p className='text-xs font-bold'>Departure Country</p>,
            dataIndex: 'country'
        },
        {
            title: <p className='text-xs font-bold'>Departure Port</p>,
            dataIndex: 'port_name'
        },
        {
            title: <p className='text-xs font-bold'>Departure Berth</p>,
            dataIndex: 'dep_berth'
        },
        {
            title: <p className='text-xs font-bold'>Co Loading Port</p>,
            dataIndex: 'co_load'
        },

        {
            title: <p className='text-xs font-bold'>Destination Country</p>,
            dataIndex: 'dest_country'
        },
        {
            title: <p className='text-xs font-bold'>Destination Port</p>,
            dataIndex: 'destination'
        },
        {
            title: <p className='text-xs font-bold'>Reported ETA</p>,
            dataIndex: 'reported_eta'
        },
        {
            title: <p className='text-xs font-bold'>Berthing Time</p>,
            dataIndex: 'berthing_time'
        },
        {
            title: <p className='text-xs font-bold'>Loading Rate (t/hr)</p>,
            dataIndex: 'loading_rate'
        }
    ],
    Intransitcolumns: [
        {
            title: <p className='text-xs font-bold'>Timestamp UTC</p>,
            dataIndex: 'time_received'
        },
        {
            title: <p className='text-xs font-bold'>Ship Name</p>,
            dataIndex: 'ship_name'
        },
        {
            title: <p className='text-xs font-bold'>Ship Type</p>,
            dataIndex: 'ship_type'
        },
        {
            title: <p className='text-xs font-bold'>Vessel Class</p>,
            dataIndex: 'size_class'
        },
        {
            title: <p className='text-xs font-bold'>DWT</p>,
            dataIndex: 'dwt'
        },
        {
            title: <p className='text-xs font-bold'>Move Type</p>,
            dataIndex: 'move_type'
        },
        {
            title: <p className='text-xs font-bold'>Estimated Cargo Load (t)</p>,
            dataIndex: 'total_cargo_load'
        },
        {
            title: <p className='text-xs font-bold'>Supplier</p>,
            dataIndex: 'company'
        },
        {
            title: <p className='text-xs font-bold'>Departure Country</p>,
            dataIndex: 'country'
        },
        {
            title: <p className='text-xs font-bold'>Departure Port</p>,
            dataIndex: 'port_name'
        },
        {
            title: <p className='text-xs font-bold'>Departure Berth</p>,
            dataIndex: 'dep_berth'
        },
        {
            title: <p className='text-xs font-bold'>Co Loading Port</p>,
            dataIndex: 'co_load'
        },

        {
            title: <p className='text-xs font-bold'>Destination Country</p>,
            dataIndex: 'dest_country'
        },
        {
            title: <p className='text-xs font-bold'>Destination Port</p>,
            dataIndex: 'destination'
        },
        {
            title: <p className='text-xs font-bold'>ATD UTC</p>,
            dataIndex: 'departure_time'
        },
        {
            title: <p className='text-xs font-bold'>Reported ETA</p>,
            dataIndex: 'reported_eta'
        },
        {
            title: <p className='text-xs font-bold'>Berthing Time</p>,
            dataIndex: 'berthing_time'
        },
        {
            title: <p className='text-xs font-bold'>Loading Rate (t/hr)</p>,
            dataIndex: 'loading_rate'
        }
    ],
}

export const DRIHBIDataColumns = {
    Arrivalcolumns: [
        {
            title: <p className='text-xs font-bold'>Timestamp UTC</p>,
            dataIndex: 'ata_utc'
        },
        {
            title: <p className='text-xs font-bold'>Ship Name</p>,
            dataIndex: 'ship_name'
        },
        {
            title: <p className='text-xs font-bold'>Ship Type</p>,
            dataIndex: 'ship_type'
        },
        {
            title: <p className='text-xs font-bold'>Vessel Class</p>,
            dataIndex: 'size_class'
        },
        {
            title: <p className='text-xs font-bold'>DWT</p>,
            dataIndex: 'dwt'
        },
        {
            title: <p className='text-xs font-bold'>Move Type</p>,
            dataIndex: 'move_type'
        },
        {
            title: <p className='text-xs font-bold'>Estimated Cargo Load (t)</p>,
            dataIndex: 'total_cargo_load'
        },
        {
            title: <p className='text-xs font-bold'>Buyer</p>,
            dataIndex: 'mill'
        },
        {
            title: <p className='text-xs font-bold'>Arrival Country</p>,
            dataIndex: 'dest_country'
        },
        {
            title: <p className='text-xs font-bold'>Arrival Port</p>,
            dataIndex: 'actual_dest'
        },
        {
            title: <p className='text-xs font-bold'>Arrival Berth</p>,
            dataIndex: 'dest_berth'
        },
        {
            title: <p className='text-xs font-bold'>Co Discharging Port</p>,
            dataIndex: 'co_discharge'
        },
        {
            title: <p className='text-xs font-bold'>Supplier</p>,
            dataIndex: 'company'
        },
        {
            title: <p className='text-xs font-bold'>Origin Country</p>,
            dataIndex: 'country'
        },
        {
            title: <p className='text-xs font-bold'>Origin Port</p>,
            dataIndex: 'previous_port_name'
        },
        {
            title: <p className='text-xs font-bold'>Origin Berth</p>,
            dataIndex: 'dep_berth'
        },
        {
            title: <p className='text-xs font-bold'>Co Loading Port</p>,
            dataIndex: 'co_load'
        }
    ],
    Shipmentcolumns: [
        {
            title: <p className='text-xs font-bold'>Timestamp UTC</p>,
            dataIndex: 'timestamp_utc'
        },
        {
            title: <p className='text-xs font-bold'>Ship Name</p>,
            dataIndex: 'ship_name'
        },
        {
            title: <p className='text-xs font-bold'>Ship Type</p>,
            dataIndex: 'ship_type'
        },
        {
            title: <p className='text-xs font-bold'>Vessel Class</p>,
            dataIndex: 'size_class'
        },
        {
            title: <p className='text-xs font-bold'>DWT</p>,
            dataIndex: 'dwt'
        },
        {
            title: <p className='text-xs font-bold'>Move Type</p>,
            dataIndex: 'move_type'
        },
        {
            title: <p className='text-xs font-bold'>Estimated Cargo Load (t)</p>,
            dataIndex: 'total_cargo_load'
        },
        {
            title: <p className='text-xs font-bold'>Supplier</p>,
            dataIndex: 'company'
        },
        {
            title: <p className='text-xs font-bold'>Departure Country</p>,
            dataIndex: 'country'
        },
        {
            title: <p className='text-xs font-bold'>Departure Port</p>,
            dataIndex: 'port_name'
        },
        {
            title: <p className='text-xs font-bold'>Departure Berth</p>,
            dataIndex: 'dep_berth'
        },
        {
            title: <p className='text-xs font-bold'>Co Loading Port</p>,
            dataIndex: 'co_load'
        },

        {
            title: <p className='text-xs font-bold'>Destination Country</p>,
            dataIndex: 'dest_country'
        },
        {
            title: <p className='text-xs font-bold'>Destination Port</p>,
            dataIndex: 'destination'
        },
        {
            title: <p className='text-xs font-bold'>Reported ETA</p>,
            dataIndex: 'reported_eta'
        },
        {
            title: <p className='text-xs font-bold'>Berthing Time</p>,
            dataIndex: 'berthing_time'
        },
        {
            title: <p className='text-xs font-bold'>Loading Rate (t/hr)</p>,
            dataIndex: 'loading_rate'
        }
    ],
    Intransitcolumns: [
        {
            title: <p className='text-xs font-bold'>Timestamp UTC</p>,
            dataIndex: 'time_received'
        },
        {
            title: <p className='text-xs font-bold'>Ship Name</p>,
            dataIndex: 'ship_name'
        },
        {
            title: <p className='text-xs font-bold'>Ship Type</p>,
            dataIndex: 'ship_type'
        },
        {
            title: <p className='text-xs font-bold'>Vessel Class</p>,
            dataIndex: 'size_class'
        },
        {
            title: <p className='text-xs font-bold'>DWT</p>,
            dataIndex: 'dwt'
        },
        {
            title: <p className='text-xs font-bold'>Move Type</p>,
            dataIndex: 'move_type'
        },
        {
            title: <p className='text-xs font-bold'>Estimated Cargo Load (t)</p>,
            dataIndex: 'total_cargo_load'
        },
        {
            title: <p className='text-xs font-bold'>Supplier</p>,
            dataIndex: 'company'
        },
        {
            title: <p className='text-xs font-bold'>Departure Country</p>,
            dataIndex: 'country'
        },
        {
            title: <p className='text-xs font-bold'>Departure Port</p>,
            dataIndex: 'port_name'
        },
        {
            title: <p className='text-xs font-bold'>Departure Berth</p>,
            dataIndex: 'dep_berth'
        },
        {
            title: <p className='text-xs font-bold'>Co Loading Port</p>,
            dataIndex: 'co_load'
        },

        {
            title: <p className='text-xs font-bold'>Destination Country</p>,
            dataIndex: 'dest_country'
        },
        {
            title: <p className='text-xs font-bold'>Destination Port</p>,
            dataIndex: 'destination'
        },
        {
            title: <p className='text-xs font-bold'>ATD UTC</p>,
            dataIndex: 'departure_time'
        },
        {
            title: <p className='text-xs font-bold'>Reported ETA</p>,
            dataIndex: 'reported_eta'
        },
        {
            title: <p className='text-xs font-bold'>Berthing Time</p>,
            dataIndex: 'berthing_time'
        },
        {
            title: <p className='text-xs font-bold'>Loading Rate (t/hr)</p>,
            dataIndex: 'loading_rate'
        }
    ],
}

export const IronOreDataColumns = {
    Arrivalcolumns: [
        {
            title: <p className='text-xs font-bold'>Timestamp UTC</p>,
            dataIndex: 'ata_utc'
        },
        {
            title: <p className='text-xs font-bold'>Ship Name</p>,
            dataIndex: 'ship_name'
        },
        {
            title: <p className='text-xs font-bold'>Ship Type</p>,
            dataIndex: 'ship_type'
        },
        {
            title: <p className='text-xs font-bold'>Vessel Class</p>,
            dataIndex: 'size_class'
        },
        {
            title: <p className='text-xs font-bold'>DWT</p>,
            dataIndex: 'dwt'
        },
        {
            title: <p className='text-xs font-bold'>Move Type</p>,
            dataIndex: 'move_type'
        },
        {
            title: <p className='text-xs font-bold'>Estimated Cargo Load (t)</p>,
            dataIndex: 'arr_total_cargo_load'
        },
        {
            title: <p className='text-xs font-bold'>Buyer</p>,
            dataIndex: 'mill'
        },
        {
            title: <p className='text-xs font-bold'>Arrival Country</p>,
            dataIndex: 'actual_dest_country'
        },
        {
            title: <p className='text-xs font-bold'>Arrival Port</p>,
            dataIndex: 'actual_dest'
        },
        {
            title: <p className='text-xs font-bold'>Arrival Berth</p>,
            dataIndex: 'dest_berth'
        },
        {
            title: <p className='text-xs font-bold'>Co Discharging Port</p>,
            dataIndex: 'co_discharge'
        },
        {
            title: <p className='text-xs font-bold'>Supplier</p>,
            dataIndex: 'company'
        },
        {
            title: <p className='text-xs font-bold'>Origin Country</p>,
            dataIndex: 'country'
        },
        {
            title: <p className='text-xs font-bold'>Origin Port</p>,
            dataIndex: 'previous_port_name'
        },
        {
            title: <p className='text-xs font-bold'>Origin Berth</p>,
            dataIndex: 'dep_berth'
        },
        {
            title: <p className='text-xs font-bold'>Co Loading Port</p>,
            dataIndex: 'co_load'
        }
    ],
    Shipmentcolumns: [
        {
            title: <p className='text-xs font-bold'>Timestamp UTC</p>,
            dataIndex: 'timestamp_utc'
        },
        {
            title: <p className='text-xs font-bold'>Ship Name</p>,
            dataIndex: 'ship_name'
        },
        {
            title: <p className='text-xs font-bold'>Ship Type</p>,
            dataIndex: 'ship_type'
        },
        {
            title: <p className='text-xs font-bold'>Vessel Class</p>,
            dataIndex: 'size_class'
        },
        {
            title: <p className='text-xs font-bold'>DWT</p>,
            dataIndex: 'dwt'
        },
        {
            title: <p className='text-xs font-bold'>Move Type</p>,
            dataIndex: 'move_type'
        },
        {
            title: <p className='text-xs font-bold'>Estimated Cargo Load (t)</p>,
            dataIndex: 'total_cargo_load'
        },
        {
            title: <p className='text-xs font-bold'>Supplier</p>,
            dataIndex: 'company'
        },
        {
            title: <p className='text-xs font-bold'>Departure Country</p>,
            dataIndex: 'country'
        },
        {
            title: <p className='text-xs font-bold'>Departure Port</p>,
            dataIndex: 'port_name'
        },
        {
            title: <p className='text-xs font-bold'>Departure Berth</p>,
            dataIndex: 'dep_berth'
        },
        {
            title: <p className='text-xs font-bold'>Co Loading Port</p>,
            dataIndex: 'co_load'
        },

        {
            title: <p className='text-xs font-bold'>Destination Country</p>,
            dataIndex: 'dest_country'
        },
        {
            title: <p className='text-xs font-bold'>Destination Port</p>,
            dataIndex: 'destination'
        },
        {
            title: <p className='text-xs font-bold'>Reported ETA</p>,
            dataIndex: 'reported_eta'
        },
        {
            title: <p className='text-xs font-bold'>Berthing Time</p>,
            dataIndex: 'berthing_time'
        },
        {
            title: <p className='text-xs font-bold'>Loading Rate (t/hr)</p>,
            dataIndex: 'loading_rate'
        }
    ],
    Intransitcolumns: [
        {
            title: <p className='text-xs font-bold'>Timestamp UTC</p>,
            dataIndex: 'time_received'
        },
        {
            title: <p className='text-xs font-bold'>Ship Name</p>,
            dataIndex: 'ship_name'
        },
        {
            title: <p className='text-xs font-bold'>Ship Type</p>,
            dataIndex: 'ship_type'
        },
        {
            title: <p className='text-xs font-bold'>Vessel Class</p>,
            dataIndex: 'size_class'
        },
        {
            title: <p className='text-xs font-bold'>DWT</p>,
            dataIndex: 'dwt'
        },
        {
            title: <p className='text-xs font-bold'>Move Type</p>,
            dataIndex: 'move_type'
        },
        {
            title: <p className='text-xs font-bold'>Estimated Cargo Load (t)</p>,
            dataIndex: 'total_cargo_load'
        },
        {
            title: <p className='text-xs font-bold'>Supplier</p>,
            dataIndex: 'company'
        },
        {
            title: <p className='text-xs font-bold'>Departure Country</p>,
            dataIndex: 'country'
        },
        {
            title: <p className='text-xs font-bold'>Departure Port</p>,
            dataIndex: 'port_name'
        },
        {
            title: <p className='text-xs font-bold'>Departure Berth</p>,
            dataIndex: 'dep_berth'
        },
        {
            title: <p className='text-xs font-bold'>Co Loading Port</p>,
            dataIndex: 'co_load'
        },
        {
            title: <p className='text-xs font-bold'>Expected Buyer</p>,
            dataIndex: 'expected_buyer',
            render: (text, record) => {
                return <ChartPopOverDialog key={record.id} record={record} />
            },
        },
        {
            title: <p className='text-xs font-bold'>Destination Country</p>,
            dataIndex: 'dest_country'
        },
        {
            title: <p className='text-xs font-bold'>Destination Port</p>,
            dataIndex: 'destination'
        },
        {
            title: <p className='text-xs font-bold'>ATD UTC</p>,
            dataIndex: 'departure_time'
        },
        {
            title: <p className='text-xs font-bold'>Reported ETA</p>,
            dataIndex: 'reported_eta'
        },
        {
            title: <p className='text-xs font-bold'>Berthing Time</p>,
            dataIndex: 'berthing_time'
        },
        {
            title: <p className='text-xs font-bold'>Loading Rate (t/hr)</p>,
            dataIndex: 'loading_rate'
        }
    ],
    Loadingcolumns: [
        {
            title: <p className='text-xs font-bold'>Arrival Time</p>,
            dataIndex: 'timestamp_utc'
        },
        {
            title: <p className='text-xs font-bold'>Ship Name</p>,
            dataIndex: 'ship_name'
        },
        {
            title: <p className='text-xs font-bold'>Ship Type</p>,
            dataIndex: 'ship_type'
        },
        {
            title: <p className='text-xs font-bold'>Vessel Class</p>,
            dataIndex: 'size_class'
        },
        {
            title: <p className='text-xs font-bold'>DWT</p>,
            dataIndex: 'dwt'
        },
        {
            title: <p className='text-xs font-bold'>Move Type</p>,
            dataIndex: 'move_type'
        },
        {
            title: <p className='text-xs font-bold'>Supplier</p>,
            dataIndex: 'company',
            render: (text, record) => (
                <PopoverDialog record={record} content={`Probability of Loading Iron Ore: ${record.confidence_rate ? record.confidence_rate + "%" : '-'}`} />
            )
        },
        {
            title: <p className='text-xs font-bold'>Load Country</p>,
            dataIndex: 'country'
        },
        {
            title: <p className='text-xs font-bold'>Load Port</p>,
            dataIndex: 'port_name'
        },

        {
            title: <p className='text-xs font-bold'>Load Berth</p>,
            dataIndex: 'load_berth'
        },
        {
            title: <p className='text-xs font-bold'>Co Loading Port</p>,
            dataIndex: 'co_load'
        },

        {
            title: <p className='text-xs font-bold'>Berthing Time</p>,
            dataIndex: 'berthing_time'
        },
        {
            title: <p className='text-xs font-bold'>Prev Country</p>,
            dataIndex: 'previous_country'
        },
        {
            title: <p className='text-xs font-bold'>Prev Port</p>,
            dataIndex: 'previous_port_name'
        },
        {
            title: <p className='text-xs font-bold'>Prev Leg Time</p>,
            dataIndex: 'previous_leg_time'
        }
    ],
    Bargescolumns: [
        {
            title: <p className='text-xs font-bold'>Timestamp UTC</p>,
            dataIndex: 'ata_utc'
        },
        {
            title: <p className='text-xs font-bold'>Ship Name</p>,
            dataIndex: 'ship_name'
        },
        {
            title: <p className='text-xs font-bold'>Ship Type</p>,
            dataIndex: 'ship_type'
        },
        {
            title: <p className='text-xs font-bold'>Move Type</p>,
            dataIndex: 'move_type'
        },
        {
            title: <p className='text-xs font-bold'>Estimated Cargo Load (t)</p>,
            dataIndex: 'estimated_cargo_load'
        },
        {
            title: <p className='text-xs font-bold'>Buyer</p>,
            dataIndex: 'mill'
        },
        {
            title: <p className='text-xs font-bold'>Arrival Country</p>,
            dataIndex: 'actual_dest_country'
        },
        {
            title: <p className='text-xs font-bold'>Arrival Port</p>,
            dataIndex: 'actual_dest'
        },
        {
            title: <p className='text-xs font-bold'>Arrival Berth</p>,
            dataIndex: 'dest_berth'
        },
        {
            title: <p className='text-xs font-bold'>Supplier</p>,
            dataIndex: 'company'
        },
        {
            title: <p className='text-xs font-bold'>Origin Country</p>,
            dataIndex: 'country'
        },
        {
            title: <p className='text-xs font-bold'>Origin Port</p>,
            dataIndex: 'port_name'
        },
        {
            title: <p className='text-xs font-bold'>Origin Berth</p>,
            dataIndex: 'dep_berth'
        },
        {
            title: <p className='text-xs font-bold'>Dep Timestamp UTC</p>,
            dataIndex: 'atd_utc'
        },
        {
            title: <p className='text-xs font-bold'>Berthing Time</p>,
            dataIndex: 'berthing_time'
        },
    ]
}

export const StainlessScrapDataColumns = {
    Arrivalcolumns: [
        {
            title: <p className='text-xs font-bold'>Timestamp UTC</p>,
            dataIndex: 'ata_utc'
        },
        {
            title: <p className='text-xs font-bold'>Ship Name</p>,
            dataIndex: 'ship_name'
        },
        {
            title: <p className='text-xs font-bold'>Ship Type</p>,
            dataIndex: 'ship_type'
        },
        {
            title: <p className='text-xs font-bold'>Vessel Class</p>,
            dataIndex: 'size_class'
        },
        {
            title: <p className='text-xs font-bold'>DWT</p>,
            dataIndex: 'dwt'
        },
        {
            title: <p className='text-xs font-bold'>Move Type</p>,
            dataIndex: 'move_type'
        },
        {
            title: <p className='text-xs font-bold'>Estimated Cargo Load (t)</p>,
            dataIndex: 'total_cargo_load'
        },
        {
            title: <p className='text-xs font-bold'>Buyer</p>,
            dataIndex: 'mill'
        },
        {
            title: <p className='text-xs font-bold'>Arrival Country</p>,
            dataIndex: 'dest_country'
        },
        {
            title: <p className='text-xs font-bold'>Arrival Port</p>,
            dataIndex: 'actual_dest'
        },
        {
            title: <p className='text-xs font-bold'>Arrival Berth</p>,
            dataIndex: 'dest_berth'
        },
        {
            title: <p className='text-xs font-bold'>Supplier</p>,
            dataIndex: 'company'
        },
        {
            title: <p className='text-xs font-bold'>Origin Country</p>,
            dataIndex: 'country'
        },
        {
            title: <p className='text-xs font-bold'>Origin Port</p>,
            dataIndex: 'previous_port_name'
        },
        {
            title: <p className='text-xs font-bold'>Origin Berth</p>,
            dataIndex: 'dep_berth'
        },
        {
            title: <p className='text-xs font-bold'>Co Loading Port</p>,
            dataIndex: 'co_load'
        }
    ],
    Shipmentcolumns: [
        {
            title: <p className='text-xs font-bold'>Timestamp UTC</p>,
            dataIndex: 'timestamp_utc'
        },
        {
            title: <p className='text-xs font-bold'>Ship Name</p>,
            dataIndex: 'ship_name'
        },
        {
            title: <p className='text-xs font-bold'>Ship Type</p>,
            dataIndex: 'ship_type'
        },
        {
            title: <p className='text-xs font-bold'>Vessel Class</p>,
            dataIndex: 'size_class'
        },
        {
            title: <p className='text-xs font-bold'>DWT</p>,
            dataIndex: 'dwt'
        },
        {
            title: <p className='text-xs font-bold'>Move Type</p>,
            dataIndex: 'move_type'
        },
        {
            title: <p className='text-xs font-bold'>Estimated Cargo Load (t)</p>,
            dataIndex: 'total_cargo_load'
        },
        {
            title: <p className='text-xs font-bold'>Supplier</p>,
            dataIndex: 'company'
        },
        {
            title: <p className='text-xs font-bold'>Departure Country</p>,
            dataIndex: 'country'
        },
        {
            title: <p className='text-xs font-bold'>Departure Port</p>,
            dataIndex: 'port_name'
        },
        {
            title: <p className='text-xs font-bold'>Departure Berth</p>,
            dataIndex: 'dep_berth'
        },
        {
            title: <p className='text-xs font-bold'>Co Loading Port</p>,
            dataIndex: 'co_load'
        },

        {
            title: <p className='text-xs font-bold'>Destination Country</p>,
            dataIndex: 'dest_country'
        },
        {
            title: <p className='text-xs font-bold'>Destination Port</p>,
            dataIndex: 'destination'
        },
        {
            title: <p className='text-xs font-bold'>Reported ETA</p>,
            dataIndex: 'reported_eta'
        },
        {
            title: <p className='text-xs font-bold'>Berthing Time</p>,
            dataIndex: 'berthing_time'
        },
        {
            title: <p className='text-xs font-bold'>Loading Rate (t/hr)</p>,
            dataIndex: 'loading_rate'
        }
    ],
    Intransitcolumns: [
        {
            title: <p className='text-xs font-bold'>Timestamp UTC</p>,
            dataIndex: 'time_received'
        },
        {
            title: <p className='text-xs font-bold'>Ship Name</p>,
            dataIndex: 'ship_name'
        },
        {
            title: <p className='text-xs font-bold'>Ship Type</p>,
            dataIndex: 'ship_type'
        },
        {
            title: <p className='text-xs font-bold'>Vessel Class</p>,
            dataIndex: 'size_class'
        },
        {
            title: <p className='text-xs font-bold'>DWT</p>,
            dataIndex: 'dwt'
        },
        {
            title: <p className='text-xs font-bold'>Move Type</p>,
            dataIndex: 'move_type'
        },
        {
            title: <p className='text-xs font-bold'>Estimated Cargo Load (t)</p>,
            dataIndex: 'total_cargo_load'
        },
        {
            title: <p className='text-xs font-bold'>Supplier</p>,
            dataIndex: 'company'
        },
        {
            title: <p className='text-xs font-bold'>Departure Country</p>,
            dataIndex: 'country'
        },
        {
            title: <p className='text-xs font-bold'>Departure Port</p>,
            dataIndex: 'port_name'
        },
        {
            title: <p className='text-xs font-bold'>Departure Berth</p>,
            dataIndex: 'dep_berth'
        },
        {
            title: <p className='text-xs font-bold'>Co Loading Port</p>,
            dataIndex: 'co_load'
        },
        {
            title: <p className='text-xs font-bold'>Expected Buyer</p>,
            dataIndex: 'expected_buyer',
            render: (text, record) => {
                return <ChartPopOverDialog key={record.id} record={record} />
            },
        },
        {
            title: <p className='text-xs font-bold'>Destination Country</p>,
            dataIndex: 'dest_country'
        },
        {
            title: <p className='text-xs font-bold'>Destination Port</p>,
            dataIndex: 'destination'
        },
        {
            title: <p className='text-xs font-bold'>Reported ETA</p>,
            dataIndex: 'reported_eta'
        },
        {
            title: <p className='text-xs font-bold'>Berthing Time</p>,
            dataIndex: 'berthing_time'
        },
        {
            title: <p className='text-xs font-bold'>Loading Rate (t/hr)</p>,
            dataIndex: 'loading_rate'
        }
    ],
    Bargescolumns: [
        {
            title: <p className='text-xs font-bold'>Timestamp UTC</p>,
            dataIndex: 'ata_utc'
        },
        {
            title: <p className='text-xs font-bold'>Ship Name</p>,
            dataIndex: 'ship_name'
        },
        {
            title: <p className='text-xs font-bold'>Ship Type</p>,
            dataIndex: 'ship_type'
        },
        {
            title: <p className='text-xs font-bold'>Move Type</p>,
            dataIndex: 'move_type'
        },
        {
            title: <p className='text-xs font-bold'>Estimated Cargo Load (t)</p>,
            dataIndex: 'estimated_cargo_load'
        },
        {
            title: <p className='text-xs font-bold'>Buyer</p>,
            dataIndex: 'mill'
        },
        {
            title: <p className='text-xs font-bold'>Arrival Country</p>,
            dataIndex: 'actual_dest_country'
        },
        {
            title: <p className='text-xs font-bold'>Arrival Port</p>,
            dataIndex: 'actual_dest'
        },
        {
            title: <p className='text-xs font-bold'>Arrival Berth</p>,
            dataIndex: 'dest_berth'
        },
        {
            title: <p className='text-xs font-bold'>Supplier</p>,
            dataIndex: 'company'
        },
        {
            title: <p className='text-xs font-bold'>Origin Country</p>,
            dataIndex: 'country'
        },
        {
            title: <p className='text-xs font-bold'>Origin Port</p>,
            dataIndex: 'port_name'
        },
        {
            title: <p className='text-xs font-bold'>Origin Berth</p>,
            dataIndex: 'dep_berth'
        },
        {
            title: <p className='text-xs font-bold'>Dep Timestamp UTC</p>,
            dataIndex: 'atd_utc'
        },
        {
            title: <p className='text-xs font-bold'>Berthing Time</p>,
            dataIndex: 'berthing_time'
        },
    ]
}
export const getUrlToDownloadData = (comodity, headerType, applyFilter, totalEntries) => {

    var url = null
    if (applyFilter == false) {
        if (headerType == 'arrival') {
            url = `/${comodity}/fixtures/arrival-data-in-chunks?limit=${totalEntries}`
        }
        else if (headerType == 'intransit') {
            url = `/${comodity}/intransit/fixtures/data-in-chunks?limit=${totalEntries}`
        }
        else if (headerType == 'loading') {
            url = `/${comodity}/fixtures/loading-data-in-chunks?limit=${totalEntries}`
        }
        else if (headerType == 'barges') {
            url = `/${comodity}/barges/fixtures/arrival-data-in-chunks?limit=${totalEntries}`
        }
        else {
            url = `/${comodity}/fixtures/shipment-data-in-chunks?limit=${totalEntries}`
        }
    } else {

        if (headerType == 'arrival') {
            url = `/${comodity}/fixtures/filter/arrivals?limit=${totalEntries}`
        }
        else if (headerType == 'intransit') {
            url = `/${comodity}/intransit/fixtures/filter/data?limit=${totalEntries}`
        }
        else if (headerType == 'loading') {
            url = `/${comodity}/fixtures/filter/loading?limit=${totalEntries}`
        }
        else if (headerType == 'barges') {
            url = `/${comodity}/barges/fixtures/filter/arrivals?limit=${totalEntries}`
        }
        else {

            url = `/${comodity}/fixtures/filter/shipments?limit=${totalEntries}`
        }
    }
    return url;
}

export const getUrlToshowHeader = (comodity, headerType, trial, pageSize) => {
    var url = null
    if (trial) {
        if (headerType == 'arrival') {
            url = `/${comodity}/fixtures/filter/arrivals?limit=${pageSize}&page=${1}`
        }
        else if (headerType == 'intransit') {
            url = `/${comodity}/intransit/fixtures/filter/data?limit=${pageSize}&page=${1}`
        }
        else if (headerType == 'loading') {
            url = `/${comodity}/fixtures/filter/loading?limit=${pageSize}&page=${1}`
        }
        else if (headerType == 'barges') {
            url = `/${comodity}/barges/fixtures/filter/arrivals?limit=${pageSize}&page=${1}`
        }
        else {
            url = `/${comodity}/fixtures/filter/shipments?limit=${pageSize}&page=${1}`
        }
    } else {
        if (headerType == 'arrival') {
            url = `/${comodity}/fixtures/arrival-data-in-chunks?limit=${pageSize}&page=${1}`
        }
        else if (headerType == 'intransit') {
            url = `/${comodity}/intransit/fixtures/data-in-chunks?limit=${pageSize}&page=${1}`
        }
        else if (headerType == 'loading') {
            url = `/${comodity}/fixtures/loading-data-in-chunks?limit=${pageSize}&page=${1}`
        }
        else if (headerType == 'barges') {
            url = `/${comodity}/barges/fixtures/arrival-data-in-chunks?limit=${pageSize}&page=${1}`
        }
        else {
            url = `/${comodity}/fixtures/shipment-data-in-chunks?limit=${pageSize}&page=${1}`
        }
    }
    return url;
}

export const checkBargesAccessLevel = () => {
    const accessLevel = localStorage.getItem('AccessLevelData') == undefined ? [] : JSON.parse(localStorage.getItem('AccessLevelData'))

    const ListId = localStorage.getItem('ListIds') == undefined ? [] : JSON.parse(localStorage.getItem('ListIds'))
    if (accessLevel && ListId) {
        const list = accessLevel.find(a => { return a.id == 16 });
        const result = list.lists.some(item => ListId.includes(item))
        return result;
    }
    return false;
}