import React, { useState } from 'react';
import { Popover } from 'antd';
import { ReactComponent as Red } from '../../Assets/images/icons/red-small.svg'
import { ReactComponent as Green } from '../../Assets/images/icons/green-small.svg'
import { ReactComponent as Yellow } from '../../Assets/images/icons/yellow-small.svg'
import RedBanner from '../../Assets/images/icons/red-banner-signal.png'
import GreenBanner from '../../Assets/images/icons/green-banner-signal.png'
import YellowBanner from '../../Assets/images/icons/yellow-banner-signal.png'
import './style.css'

const PopoverDialog = ({ record, comodity }) => {
    const [isOpen, setIsOpen] = useState(false);

    const getBanner = () => {
        if (record?.colour === 'red') {
            return <img src={RedBanner} />;
        } else if (record?.colour === 'green') {
            return <img src={GreenBanner} />;
        } else if (record?.colour === 'orange') {
            return <img src={YellowBanner} />;
        }
        return <></>;
    };
    const loadingElement = () => (
        <div style={{ display: 'flex', gap: 10, justifyContent: "space-between", alignItems: "center", width: '100%' }}>
            <p style={{ display: "flex", flexDirection: "column", rowGap: "1px" }} >
                <span style={{ color: record?.colour == 'red' ? "#CB3C36" : record?.colour == 'green' ? "#3CCC7E" : record?.colour == 'orange' ? "#FFB248" : "" }} className='confidence'>{record.confidence_rate + "%"}</span>
                <span className='probability'>Probability of Loading</span>
                <span className='comodity'>{comodity}</span>
            </p>
            {getBanner()}
        </div>
    )
    return (

        <Popover key={record.id} content={loadingElement} open={record?.colour !== null && isOpen} >
            <span onMouseLeave={() => { setIsOpen(false) }} onMouseEnter={() => { setIsOpen(true) }} style={{ display: 'flex', gap: 5 }}>
                <div style={{ display: 'flex', gap: 5, justifyContent: "space-between", alignItems: "center", width: '100%' }}>
                    <p>{record?.company ?? ""}</p>
                    <p className={record?.colour !== null && isOpen ? 'signal-popover' : "signal-popover-unselected"}>{record?.colour == 'red' ? <Red className='signal-hover-icon' /> : record?.colour == 'green' ? <Green className='signal-hover-icon' /> : record?.colour == 'orange' ? <Yellow className='signal-hover-icon' /> : ""}</p>
                </div>
            </span>
        </Popover>
    );
};
export default PopoverDialog;
