import React, { useContext, useEffect, useMemo } from 'react'
import { ReactComponent as Logo } from '../Assets/images/NC_logo.svg'
// antd components
import Avatar from 'antd/es/avatar/avatar'
import { DownOutlined } from '@ant-design/icons'
import { Dropdown, Space } from 'antd'
// svg
import { useParams } from 'react-router-dom'
import { ReactComponent as MenuGrid } from '../Assets/images/MenuGrid.svg'

// reactrouter
import { useLocation, Link, NavLink, useNavigate } from 'react-router-dom'
import UserContext from '../store/UserContext'
import { useState } from 'react'
import axios from '../axiosconfiguration/axios'
import {
  access_levels,
  allAccessLevel,
  checkAccessLevelById,
  submenuAccessLevel
} from './access-level'
import HamburgerMenu from './Misc/HamburgerMenu'
import './style.css'
import { SubMenu } from './subMenu'
import { NavigateMarineCategories } from '../utilities/DateToLocalUtility'
import { WarningModal } from './Misc/WarningModal'
import UseFetchAxios from '../Hooks/UseFetchAxios'

const Header = () => {
  let location = useLocation()
  const parameters = useParams()
  const navigate = useNavigate()
  let ListIdstring = localStorage.getItem('ListIds')
  let ListId = JSON.parse(ListIdstring)
  const { userState, setUserState } = useContext(UserContext)
  const [firstName, setFirstName] = useState(null)
  const [lastName, setLastName] = useState(null)
  const [accessLevels, setAccessLevels] = useState(null)
  const [loading, setLoading] = useState(false);
  const useFetch = UseFetchAxios();
  const [selectedMarineMenuItem, setSelectedmarineMenuItem] = useState("");
  console.log(parameters)

  useEffect(() => {
    const loggedUserFirstName = localStorage.getItem('firstName')
    const loggedUserLastName = localStorage.getItem('lastName')
    setFirstName(loggedUserFirstName)
    setLastName(loggedUserLastName)
  }, [userState?.isAuth, userState?.isUpdated])

  useEffect(() => {
    if (location.pathname != '/welcome') {
      setLoading(true)
      getAccessLevels()
      NavigateMarineCategories.map(category => {
        if (location.pathname && location.pathname.includes(category.value))
          setSelectedmarineMenuItem(category.value)
      })

    }
    // getAccessLevels()
  }, [location?.pathname])

  const userValidation = data => {
    let location = window?.location?.pathname
    console.log(location)
    const pattern = /^\/market-compass\/.*$/
    // console.log(parameters)
    console.log(parameters)
    let unprotectedRoute = ['/', '/welcome', '/account', '/reset-password']
    if (unprotectedRoute?.includes(location) || pattern.test(location)) {
      return
    }
    const accessLevels =
      localStorage.getItem('AccessLevel') == undefined
        ? data
        : JSON.parse(localStorage.getItem('AccessLevel'))
    const currentPath = allAccessLevel?.find(
      item => item?.path == window?.location?.pathname
    )
    const currentItem = accessLevels?.find(i => currentPath?.id == i?.id)
    if (
      (accessLevels &&
        // List Array
        ListId?.some(ai =>
          currentItem?.lists?.map(it => JSON.parse(it))?.includes(ai)
        )) ||
      (accessLevels && currentItem?.lists?.length < 1)
    ) {
    } else {
      // WarningModal(currentPath?.text)
      console.log(" Need to update once access cases handled by backend")
      // navigate('/welcome') Need to update for no access cases
    }
  }
  const getAccessLevels = async () => {
    try {
      const response = await axios.get('/access-levels', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })
      if (response?.data) {
        console.log('acess levels resp', response?.data)
        console.log(accessLevels)

        setAccessLevels(response?.data?.data)
        userValidation(response?.data?.data)
        setLoading(false);
      }
    } catch (error) { }
  }
  // item?.lists.map(it=>JSON.parse(it)
  // if(accessLevels)
  // {
  //   console.log('accesslevel header', accessLevels[1]?.lists?.map(it=>JSON.parse(it)))

  // }8
  const menuItem = access_levels.filter(item => item.id !== 1).map(item => {

    let itemids;
    if (item?.id == 2 && accessLevels) {
      const currentItemList = accessLevels?.filter(i => (i?.id == 2 || i?.id == 14 || i?.id == 15 || i?.id == 17))
      itemids = [];
      currentItemList.map((ci) => {
        const marineObj = {}
        const marineIds = (ci?.lists?.map(it => JSON.parse(it)));
        marineObj.id = ci.id
        marineObj.comodityIdList = marineIds;
        itemids.push(marineObj);
      })
    }
    return {
      label: (
        <span
          onClick={e => {
            console.log(item)
            console.log(accessLevels)
            if (item.path == '/ferrousScrap') {
              const accessId = itemids.map((itemId) => {
                if (ListId?.some(ai => itemId.comodityIdList.includes(ai)))
                  return itemId?.id;
              })
              if (accessId[0] == 2) {
                navigate("/ferrousScrap", { state: { accessLevels: accessLevels } })
              } else if (accessId[2] == 15) {
                navigate("/merchantPigIron", { state: { accessLevels: accessLevels } })
              } else if (accessId[1] == 14) {
                navigate("/driHbi", { state: { accessLevels: accessLevels } })
              } else if (accessId[3] == 17) {
                navigate("/ironOre", { state: { accessLevels: accessLevels } })
              } else if (accessId[4] == 17) {
                navigate("/stainlessScrap", { state: { accessLevels: accessLevels } })
              }
              else {
                navigate("/stainlessScrap", { state: { accessLevels: accessLevels } })
                // WarningModal(item?.text)
              }
            } else {
              const currentItem = accessLevels?.find(i => item?.id == i?.id)
              console.log(currentItem)
                ; (accessLevels &&
                  // List Array
                  ListId?.some(ai =>
                    currentItem?.lists?.map(it => JSON.parse(it))?.includes(ai)
                  )) ||
                  (accessLevels && currentItem?.lists?.lists?.length < 1)
                  ?
                  item.text == "Navigate Earth" ?
                    window.open(
                      'https://navigate.gains.earthi.world/',
                      '_blank'
                    ) :
                    navigate(item?.path)
                  :
                  WarningModal(item?.text)
            }
          }}
        >
          <div className='flex pt-2 pb-2 '>
            <div className='w-7 h-7 bg-white rounded flex justify-center items-center mr-2 menugrid-svg-wrapper-shadow'>
              {item?.icon}
            </div>
            <p className='leading-7 font-medium text-sm'>{item?.text}</p>
          </div>
        </span>
      ),
      key: item.key
    }
  })
  const MenuItems = [
    {
      label: <p className='text-sm font-semibold pt-2 pb-2'>Switch to</p>,
      key: '0'
    },

    ...menuItem
  ]
  const items = [
    {
      label: <Link to='/account'>My Account</Link>,
      key: '0'
    },
    {
      type: 'divider'
    },
    {
      label: (
        <Link
          to='/'
          onClick={async () => {
            await useFetch.apiCallPost('/logout', { token: localStorage.getItem('token') });
            localStorage.removeItem('token')
            localStorage.removeItem('email')
            localStorage.removeItem('firstName')
            localStorage.removeItem('lastName')
            localStorage.removeItem('ListIds')

            setUserState({
              ...userState,
              isAuth: false,
              token: null,
              email: null,
              firstName: null,
              lastName: null
            })
          }}
        >
          Logout
        </Link>
      ),
      key: '2'
    }
  ]
  return (
    <>
      <div className={`header-overlay ${loading ? 'overlay-active' : ''}`}></div>
      <div className='header-wrapper flex w-full  justify-between bg-white sticky top-0'>
        <div className='Navigate-logo-wrapper flex'>
          <Link to={'/welcome'}>
            <Logo />
          </Link>

          {location.pathname === '/account' ||
            // location.pathname === '/fixtures' ||
            location.pathname === '/ferrousScrap' ||
            location.pathname === '/merchantPigIron' ||
            location.pathname === '/driHbi' ||
            location.pathname === '/ironOre' ||
            location.pathname === '/stainlessScrap' ||


            // location.pathname === '/company' ||
            // location.pathname === '/fifa' ||
            location.pathname === '/trade' ||
            location.pathname === '/inter-dir' ||
            location.pathname === '/market-compass' ||
            location.pathname === '/iron-ore' ||
            location.pathname === '/ferrous-scrap' ||
            location.pathname === '/semis-steel' ||
            location.pathname === '/coal-coke' ||
            location.pathname.includes('market-compass') ? (
            <div className='antd-custom-menu-dropdown'>
              {accessLevels &&
                <Dropdown
                  menu={{
                    items: MenuItems
                  }}
                  overlayClassName='antd-custom-menu-dropdown'
                  trigger={['click']}
                >
                  <a onClick={e => e.preventDefault()}>
                    <Space className='menugrid-wrapper mr-6 w-8 h-8 flex justify-center cursor-pointer'>
                      <MenuGrid />
                    </Space>
                  </a>
                </Dropdown>}
            </div>
          ) : null}
          {/* breadcrumbs sort of */}
          {location.pathname === '/inter-dir' ? (
            <p className='font-semibold text-sm leading-9 hidden sm:block'>
              Interactive Directory
            </p>
          ) : ['/fixtures', '/company', '/trade', '/fifa', '/ferrousScrap', '/merchantPigIron', '/stainlessScrap', '/driHbi', '/ironOre']?.includes(
            location.pathname
          ) ? (
            <p className='font-semibold text-sm leading-9 hidden sm:block'>
              Navigate Marine
            </p>
          ) : location.pathname === '/market-compass' ||
            location.pathname.includes('market-compass') ? (
            <p className='font-semibold text-sm leading-9 hidden sm:block'>
              Market Compass
            </p>
          ) : location.pathname === '/iron-ore' ? (
            <p className='font-semibold text-sm leading-9 hidden sm:block'>
              Iron Ore
            </p>
          ) : location.pathname === '/ferrous-scrap' ? (
            <p className='font-semibold text-sm leading-9 hidden sm:block'>
              Ferrous Scrap & Metallics
            </p>
          ) : location.pathname === '/semis-steel' ? (
            <p className='font-semibold text-sm leading-9 hidden sm:block'>
              Semis & Finished Steel
            </p>
          ) : location.pathname === '/coal-coke' ? (
            <p className='font-semibold text-sm leading-9 hidden sm:block'>
              Metallurgical Coal & Coke
            </p>
          ) : null}
        </div>

        <div
          style={{ height: "103%", width: "50%", borderBottom: "none" }}
          className={`Menu-wrapper  w-1/3 ${location.pathname === '/account' ||
            location.pathname === '/ferrousScrap' ||
            location.pathname === '/merchantPigIron' ||
            location.pathname === '/stainlessScrap' ||
            location.pathname === '/driHbi' ||
            location.pathname === '/ironOre'
            ? 'block '
            : 'hidden'
            }`}
        >
          <div className='block md:hidden'>
            <HamburgerMenu />
          </div>
          <ul
            className=' hidden md:flex justify-between font-semibold cursor-pointer text-sm'
            style={{ background: "transparent", height: "100%", paddingTop: "5px", }}
          >
            {NavigateMarineCategories?.map(item => {
              let isCurrentItem = selectedMarineMenuItem === item.value
              isCurrentItem = location.pathname === '/account' ? false : isCurrentItem;
              // if (checkAccessLevelById(item.id)) {
              return (
                <li
                  key={item.id}
                  style={{ color: isCurrentItem ? "" : "rgba(86, 86, 86, 1)", backgroundColor: isCurrentItem ? "rgba(246, 249, 255, 1)" : "", border: isCurrentItem ? "1px solid rgba(213, 231, 243, 1)" : "", borderBottom: isCurrentItem ? "2px solid rgba(246, 249, 255, 1)" : "", borderRadius: isCurrentItem ? "4px 4px 0px 0px" : "", display: "flex", justifyContent: "center", zIndex: 999 }}
                  className={isCurrentItem ? "active-menu" : "non-active-header-item"}
                  onClick={e => {
                    const access = checkAccessLevelById(item.id);
                    if (!access) {
                      WarningModal(item.label)
                    } else {
                      setSelectedmarineMenuItem(item.value);
                      navigate(item.value);
                    }
                  }}
                >
                  <p style={{ textAlign: "center", paddingTop: "5px", paddingBottom: "5px" }} className={isCurrentItem ? 'header-menu-active-item-text' : "header-menu-nonactive-item-text"}> {item?.label} </p>
                </li>
              )
              // }
            })}
          </ul>
        </div>

        <div className='Avatar-wrapper flex '>
          <Avatar className='mr-2' style={{ background: '#0071C1' }}>
            {firstName !== null &&
              firstName !== undefined &&
              lastName !== null &&
              lastName !== undefined
              ? `${firstName?.substring(0, 1)}${lastName?.substring(0, 1)}`
              : `${'Navigate'.substring(0, 1)}${'User'.substring(0, 1)}`}
          </Avatar>
          <Dropdown
            menu={{
              items
            }}
            trigger={['click']}
          >
            <a onClick={e => e.preventDefault()} className='cursor-pointer'>
              <Space>
                <p className='text-xs lg:text-sm lg:font-semibold  leading-4 lg:leading-6' style={{ marginTop: '4px' }}>
                  {firstName !== null && lastName !== null
                    ? `${firstName} ${lastName}`
                    : 'Navigate user'}
                </p>
                <DownOutlined
                  style={{ lineHeight: '3', fontSize: '8px' }}
                  className='font-bold cursor-pointer'
                />
              </Space>
            </a>
          </Dropdown>
        </div>
      </div>
    </>
  )
}

export default Header
