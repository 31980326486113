import React, { useEffect, useState } from 'react';
import { Modal, Popover } from 'antd';
import { ReactComponent as Red } from '../../Assets/images/icons/red-small.svg'
import { ReactComponent as Green } from '../../Assets/images/icons/green-small.svg'
import { ReactComponent as Yellow } from '../../Assets/images/icons/yellow-small.svg'
import ProbabilityPopOver from '../common/ProbabilityPopOver';
import GooglePieChart from './CustomChart';

const ChartPopOverDialog = ({ record, isVoyges = true }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isClick, setIsClick] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        try {
            if (record.details) {
                let result = [];
                const jsonObj = JSON.parse(record.details)
                Object.entries(jsonObj).map(([key, value]) => {
                    const temp = { type: key, value: value }
                    result.push(temp);
                });
                console.log("Parsing Obj", result);
                setData(result);
            }
        } catch (error) {
            setData([]);
            console.log("Parsing error");
        }
    }, [record])

    function generateLightGrayVariants(data) {
        const grays = [];
        const minGray = 150; // Start from a light gray
        const maxGray = 230;
        const stepSize = Math.floor((maxGray - minGray) / (data.length - 1)); // Increment for each shade

        const color = record?.colour == 'red' ? "#CB3C36" : record?.colour == 'green' ? "#3CCC7E" : record?.colour == 'orange' ? "#FFB248" : "";

        for (let i = 0; i < data.length; i++) {
            const grayValue = minGray + i * stepSize; // Calculate gray intensity
            const limitedGrayValue = Math.min(grayValue, maxGray);
            const hexGray = `#${limitedGrayValue.toString(16).padStart(2, '0').repeat(3)}`; // Convert to hex
            if (data[i].type == record?.expected_buyer) {
                if (color)
                    grays.push(color);
            } else
                grays.push(hexGray);
        }

        return grays;
    }

    let data2 = [['Task', 'Hours per Day']]
    const tmep = data.map(d => [d.type, d.value]);
    data2 = data2.concat(tmep);

    const options = {
        title: "Probability of Expected Buyer",
        titlePosition: 'none',
        is3D: false, // Set to true for a 3D pie chart
        chartArea: { bottom: 0, top: 0, left: 5, width: "90%", height: "90%" },
        colors: generateLightGrayVariants(data),
        tooltip: {
            text: 'percentage',
            trigger: 'selection', // Stabilizes the hover behavior
            textStyle: {
                color: 'black',
                fontSize: 12,
            },
        },
        enableInteractivity: true,
        legend: 'none',
    };
    return (
        <>
            <Modal
                className="custom-modal"
                style={{ padding: 0 }}
                title={<p style={{ display: 'flex', justifyContent: 'start', marginLeft: '23px' }}>{"Probability of Expected Buyer"}</p>}
                open={isVoyges && isClick}
                bodyStyle={{ padding: 0 }}
                width={500}
                height={300}
                onCancel={() => setIsClick(false)}
                footer={null}
            >
                {data2.length > 0 && <GooglePieChart record={record} options={options} data={data} data2={data2} />}
            </Modal>

            <Popover content={<ProbabilityPopOver title={record?.expected_buyer ?? ""} />} open={isVoyges && isOpen}>
                <span onMouseLeave={() => { setIsOpen(false) }} onClick={() => { record?.colour && setIsClick(true) }} onMouseEnter={() => { record?.colour && setIsOpen(true) }} style={{ display: 'flex', gap: 5 }}>
                    <div style={{ display: 'flex', gap: 5, justifyContent: "space-between", alignItems: "center", width: '100%' }}>
                        <p>{record?.expected_buyer ?? ""}</p>
                        <div className={isVoyges && isOpen ? 'signal-popover' : isVoyges ? "signal-popover-unselected" : 'signal-popover-unselected-voyge'}>{record?.colour == 'red' ? <Red className='signal-hover-icon' /> : record?.colour == 'green' ? <Green className='signal-hover-icon' /> : record?.colour == 'orange' ? <Yellow className='signal-hover-icon' /> : ""}</div>
                    </div>
                </span>
            </Popover>
        </>
    );
};
export default ChartPopOverDialog;