export const allData = {
    ferrous: {
        shipment: undefined,
        arrival: undefined,
        barges: undefined
    },
    merchantiron: {
        shipment: undefined,
        arrival: undefined
    },
    drihbi: {
        shipment: undefined,
        arrival: undefined
    },
    ironore: {
        shipment: undefined,
        arrival: undefined,
        barges: undefined
    },
    stainlessscrap: {
        shipment: undefined,
        arrival: undefined,
        barges: undefined
    }

}
let reset = [];
export function ResetProp() {
    reset = []
}

export function setResetValue(val) {
    if (!reset.includes(val)) {
        reset.push(val)
        return true;
    }
    return false;
}

export const monthArray = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const getWeekNumber = date => {
    const d = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    )
    const dayNum = d.getUTCDay() || 7
    d.setUTCDate(d.getUTCDate() + 4 - dayNum)
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1))
    return Math.ceil(((d - yearStart) / 86400000 + 1) / 7)
}
export const getMonthNumber = (value) => {
    let i = -1;
    for (let index = 0; index < monthArray.length; index++) {
        const element = monthArray[index];
        if (element.toLowerCase().startsWith(value.toLowerCase()))
            return index;
    }
    return -1;
}
export function getMonth(type, val1, val2, index) {
    if (type == 'weekly')
        return getDateOfWeek(val1.substring(1), val2, index);
    if (type == 'monthly')
        return val1;
    if (type == 'daily')
        return monthArray[val1.split('-')[0] - 1]
}
function getDateOfWeek(w, y, index) {
    if (index == 0)
        return;
    w = w - 2;
    w = w > 17 ? w - 1 : w;
    w = w > 27 ? w + 1 : w;
    w = w > 36 ? w + 1 : w;
    w = w > 39 ? w + 1 : w;
    w = w > 43 ? w + 2 : w;
    // w = w == 17 ? 18 : w == 21 ? 22 : w == 25 ? 26 : w == 29 ? 31 : w == 33 ? 35 : w == 37 ? 39 : w == 41 ? 45 : w == 45 ? 48 : w;
    var d = (1 + (w * 7)); // 1st of January + 7 days for each week

    return new Date(y, 0, d).toLocaleString(undefined, { month: "long" });

}

export const dataForGraph = (data, company, graphData, dataSourceType, comodity) => {
    if (data?.data?.data.length == 0)
        return [];
    const type = data?.data?.data[0].move_type;
    console.log(data?.data?.data)
    let coloaddata = data?.data?.data?.map(item => {
        let findRoutes = company?.coloadRoute?.find(
            route => route?.id == item?.unique_coload_id
        )
        if (findRoutes != null && findRoutes != undefined) {
            return { ...item, coloadRoutes: findRoutes?.coords }
        } else {
            return { ...item, coloadRoutes: [] }
        }
    })
    console.log(coloaddata)

    let numberOfVesselsYealy = []
    let numberOfVesselsmonthly = { numberOfVesselsmonthly: [], sesonalityData: [] }
    let numberOfVesselsWeekly = { numberOfVesselsWeekly: [], sesonalityData: [] }
    let numberOfVesselsdaily = { numberOfVesselsdaily: [], sesonalityData: [] }
    let cargoLoadYearly = []
    let cargoLoadMonthly = { cargoLoadMonthly: [], sesonalityData: [] }
    let cargoLoadDaily = { cargoLoadDaily: [], sesonalityData: [] }
    let cargoLoadWeekly = { cargoLoadWeekly: [], sesonalityData: [] }

    let loadKey = 'total_cargo_load';
    if (comodity == 'ironore') {
        loadKey = dataSourceType == 'arrival' ? 'arr_total_cargo_load' : 'total_cargo_load';
    }

    loadKey = dataSourceType == 'barges' ? 'estimated_cargo_load' : loadKey;

    data?.data?.data?.forEach(item => {
        let data = new Date(
            item?.ata_utc || item?.timestamp_utc
        ).getFullYear()
        let index = numberOfVesselsYealy?.findIndex(i => data == i.annually)
        let loadIndex = cargoLoadYearly?.findIndex(i => data == i.annually)
        let cargoElement = cargoLoadYearly?.find(
            item => item?.annually == data
        )
        let element = numberOfVesselsYealy?.find(
            item => item?.annually == data
        )
        if (index != -1) {
            numberOfVesselsYealy[index] = {
                ...element,
                noOfVessel: element?.noOfVessel + 1
            }
        } else {
            numberOfVesselsYealy.push({
                noOfVessel: 1,
                annually: data
            })
        }
        if (loadIndex != -1) {
            cargoLoadYearly[index] = {
                ...cargoElement,
                load: cargoElement?.load + Number(item?.[loadKey])
            }
        } else {
            cargoLoadYearly.push({
                load: Number(item?.[loadKey]),
                annually: data
            })
        }
    })

    data?.data?.data?.forEach(item => {
        let year = new Date(
            item?.ata_utc || item?.timestamp_utc
        ).getFullYear()
        let month = new Date(
            item?.ata_utc || item?.timestamp_utc
        )?.getMonth()
        let key = `${year}-${month + 1}`
        let index = numberOfVesselsmonthly.numberOfVesselsmonthly?.findIndex(i => key == i.monthly)
        let element = numberOfVesselsmonthly.numberOfVesselsmonthly?.find(
            item => item?.monthly == key
        )

        let cargoIndex = cargoLoadMonthly.cargoLoadMonthly?.findIndex(i => key == i.monthly)
        let cargoelement = cargoLoadMonthly.cargoLoadMonthly?.find(
            item => item?.monthly == key
        )
        if (cargoIndex != -1) {
            cargoLoadMonthly.sesonalityData[cargoIndex].load = cargoelement?.load + Number(item?.[loadKey])
            cargoLoadMonthly.cargoLoadMonthly[cargoIndex] = {
                ...cargoelement,
                load: cargoelement?.load + Number(item?.[loadKey])
            }
        } else {
            cargoLoadMonthly.cargoLoadMonthly.push({
                load: Number(item?.[loadKey]),
                monthly: key
            })
            const monthlyKey = key.split('-');
            cargoLoadMonthly.sesonalityData.push({ monthly: monthArray[Number(monthlyKey[1]) - 1], year: monthlyKey[0], load: Number(item?.[loadKey]) })
        }
        if (index != -1) {
            numberOfVesselsmonthly.sesonalityData[index].noOfVessel = element?.noOfVessel + 1
            numberOfVesselsmonthly.numberOfVesselsmonthly[index] = {
                ...element,
                noOfVessel: element?.noOfVessel + 1
            }
        } else {
            numberOfVesselsmonthly.numberOfVesselsmonthly.push({
                noOfVessel: 1,
                monthly: key,
            })
            const monthlyKey = key.split('-');
            numberOfVesselsmonthly.sesonalityData.push({ monthly: monthArray[Number(monthlyKey[1]) - 1], year: monthlyKey[0], noOfVessel: 1 })
        }
    })

    data?.data?.data?.forEach(item => {
        let year = new Date(
            item?.ata_utc || item?.timestamp_utc
        ).getFullYear()
        let month = new Date(
            item?.ata_utc || item?.timestamp_utc
        )?.getMonth()
        let date = new Date(item?.ata_utc || item?.timestamp_utc)?.getDate()
        let key = `${year}-${month + 1}-${date}`
        let index = numberOfVesselsdaily.numberOfVesselsdaily?.findIndex(i => key == i.daily)
        let element = numberOfVesselsdaily.numberOfVesselsdaily?.find(item => item?.daily == key)
        if (index != -1) {
            numberOfVesselsdaily.sesonalityData[index].noOfVessel = element?.noOfVessel + 1;
            numberOfVesselsdaily.numberOfVesselsdaily[index] = {
                ...element,
                noOfVessel: element?.noOfVessel + 1
            }
        } else {
            numberOfVesselsdaily.numberOfVesselsdaily.push({
                noOfVessel: 1,
                daily: key
            })

            numberOfVesselsdaily.sesonalityData.push({ daily: `${month + 1}-${date}`, year: year.toString(), noOfVessel: 1, date: key })
        }
        let cargoindex = cargoLoadDaily.cargoLoadDaily?.findIndex(i => key == i.daily)
        let cargoelement = cargoLoadDaily.cargoLoadDaily?.find(item => item?.daily == key)
        if (cargoindex != -1) {
            cargoLoadDaily.sesonalityData[cargoindex].load = cargoelement?.load + Number(item?.[loadKey]);
            cargoLoadDaily.cargoLoadDaily[cargoindex] = {
                ...cargoelement,
                load: cargoelement?.load + Number(item?.[loadKey])
            }
        } else {
            cargoLoadDaily.cargoLoadDaily.push({
                load: Number(item?.[loadKey]),
                daily: key
            })

            cargoLoadDaily.sesonalityData.push({ daily: `${month + 1}-${date}`, year: year.toString(), load: Number(item?.[loadKey]), date: key })
        }
    })

    data?.data?.data?.forEach(item => {
        let date = new Date(item?.ata_utc || item?.timestamp_utc)
        let year = date.getFullYear()
        let weekNumber = getWeekNumber(date)
        let key = `${year}-W${weekNumber}`

        let index = numberOfVesselsWeekly.numberOfVesselsWeekly?.findIndex(i => key == i.weekly)
        let element = numberOfVesselsWeekly.numberOfVesselsWeekly?.find(
            item => item?.weekly == key
        )

        let cargoIndex = cargoLoadWeekly.cargoLoadWeekly?.findIndex(i => key == i.weekly)
        let cargoelement = cargoLoadWeekly.cargoLoadWeekly?.find(
            item => item?.weekly == key
        )

        if (cargoIndex != -1) {
            cargoLoadWeekly.sesonalityData[cargoIndex].load = cargoelement?.load + Number(item?.[loadKey])
            cargoLoadWeekly.cargoLoadWeekly[cargoIndex] = {
                ...cargoelement,
                load: cargoelement?.load + Number(item?.[loadKey])
            }
        } else {
            cargoLoadWeekly.cargoLoadWeekly.push({
                load: Number(item?.[loadKey]),
                weekly: key,
            })
            const weeklyKey = key.split('-');
            cargoLoadWeekly.sesonalityData.push({ weekly: weeklyKey[1], year: weeklyKey[0], load: Number(item?.[loadKey]) })
        }

        if (index != -1) {
            numberOfVesselsWeekly.sesonalityData[index].noOfVessel = element?.noOfVessel + 1
            numberOfVesselsWeekly.numberOfVesselsWeekly[index] = {
                ...element,
                noOfVessel: element?.noOfVessel + 1
            }
        } else {
            numberOfVesselsWeekly.numberOfVesselsWeekly.push({
                noOfVessel: 1,
                weekly: key,
            })
            const weeklyKey = key.split('-');
            numberOfVesselsWeekly.sesonalityData.push({ weekly: weeklyKey[1], year: weeklyKey[0], noOfVessel: 1 })
        }
    })
    console.log(numberOfVesselsWeekly)
    console.log(cargoLoadWeekly)
    let buyer = []
    let supplier = []
    let portsdata = []
    let portsdata1 = []
    if (dataSourceType == 'barges') {
        data?.data?.data?.forEach(item => {
            const index = buyer.findIndex(i => i?.name == item?.mill)
            const index1 = supplier.findIndex(i => i?.name == item?.company)
            const buy = buyer.find(i => i?.name == item?.mill)
            const sup = supplier.find(i => i?.name == item?.company)
            if (index != -1) {
                buyer[index] = { name: buy?.name, value: buy?.value + Number(item?.estimated_cargo_load) }
            } else {
                buyer.push({ name: item?.mill, value: Number(item?.estimated_cargo_load) })
            }

            if (index1 != -1) {
                supplier[index1] = { name: sup?.name, value: sup?.value + Number(item?.estimated_cargo_load) }
            }
            else {
                supplier.push({ name: item?.company, value: Number(item?.estimated_cargo_load) })
            }

            const portindex = portsdata.findIndex(
                i => i?.name == item?.actual_dest
            )
            const port = portsdata.find(i => i?.name == item?.actual_dest)

            const portindex1 = portsdata1.findIndex(
                i => i?.name == item?.port_name
            )
            const port1 = portsdata1.find(i => i?.name == item?.port_name)

            if (portindex != -1) {
                portsdata[portindex] = { name: port?.name, value: port?.value + Number(item?.estimated_cargo_load) }
            } else {
                portsdata.push({ name: item?.actual_dest, value: Number(item?.estimated_cargo_load) })
            }

            if (portindex1 != -1) {
                portsdata1[portindex1] = { name: port1?.name, value: port1?.value + Number(item?.estimated_cargo_load) }
            } else {
                portsdata1.push({ name: item?.port_name, value: Number(item?.estimated_cargo_load) })
            }
        })
    } else if (type == "ARRIVAL") {
        data?.data?.data?.forEach(item => {
            const index = buyer.findIndex(i => i?.name == item?.mill)
            const index1 = supplier.findIndex(i => i?.name == item?.company)
            const buy = buyer.find(i => i?.name == item?.mill)
            const sup = supplier.find(i => i?.name == item?.company);
            let _cargo_load = comodity == 'ironore' ? Number(item?.arr_total_cargo_load) : Number(item?.total_cargo_load)
            if (index != -1) {
                buyer[index] = { name: buy?.name, value: buy?.value + _cargo_load }
            } else {
                buyer.push({ name: item?.mill, value: _cargo_load })
            }

            if (index1 != -1) {
                supplier[index1] = { name: sup?.name, value: sup?.value + _cargo_load }
            }
            else {
                supplier.push({ name: item?.company, value: _cargo_load })
            }

            const portindex = portsdata.findIndex(
                i => i?.name == item?.actual_dest
            )
            const port = portsdata.find(i => i?.name == item?.actual_dest)

            const portindex1 = portsdata1.findIndex(
                i => i?.name == item?.previous_port_name
            )
            const port1 = portsdata1.find(i => i?.name == item?.previous_port_name)

            if (portindex != -1) {
                portsdata[portindex] = { name: port?.name, value: port?.value + _cargo_load }
            } else {
                portsdata.push({ name: item?.actual_dest, value: _cargo_load })
            }

            if (portindex1 != -1) {
                portsdata1[portindex1] = { name: port1?.name, value: port1?.value + _cargo_load }
            } else {
                portsdata1.push({ name: item?.previous_port_name, value: _cargo_load })
            }
        })
    } else {
        data?.data?.data?.forEach(item => {
            const index = buyer.findIndex(i => i?.name == item?.company)
            const buy = buyer.find(i => i?.name == item?.company)
            if (index != -1) {
                buyer[index] = { name: buy?.name, value: buy?.value + Number(item?.total_cargo_load) }
            } else {
                buyer.push({ name: item?.company, value: Number(item?.total_cargo_load) })
            }

            const portindex = portsdata.findIndex(
                i => i?.name == item?.port_name
            )
            const port = portsdata.find(i => i?.name == item?.port_name)

            const portindex1 = portsdata1.findIndex(
                i => i?.name == item?.destination
            )
            const port1 = portsdata1.find(i => i?.name == item?.destination)

            if (portindex != -1) {
                portsdata[portindex] = { name: port?.name, value: port?.value + Number(item?.total_cargo_load) }
            } else {
                portsdata.push({ name: item?.port_name, value: Number(item?.total_cargo_load) })
            }

            if (portindex1 != -1) {
                portsdata1[portindex1] = { name: port1?.name, value: port1?.value + Number(item?.total_cargo_load) }
            } else {
                portsdata1.push({ name: item?.destination, value: Number(item?.total_cargo_load) })
            }
        })
    }
    portsdata = portsdata.filter(data => data != null)
    portsdata1 = portsdata1.filter(data => data != null)

    graphData = {
        vessel: {
            daily: { ...numberOfVesselsdaily, sesonalityData: fillEmptyValuewithNull(numberOfVesselsdaily.sesonalityData, 'daily', 'vessel') },
            annually: numberOfVesselsYealy,
            monthly: { ...numberOfVesselsmonthly, sesonalityData: fillEmptyValuewithNull(numberOfVesselsmonthly.sesonalityData, 'monthly', 'vessel') },
            weekly: { ...numberOfVesselsWeekly, sesonalityData: fillEmptyValuewithNull(numberOfVesselsWeekly.sesonalityData, 'weekly', 'vessel') },
        },
        load: {
            daily: { ...cargoLoadDaily, sesonalityData: fillEmptyValuewithNull(cargoLoadDaily.sesonalityData, 'daily') },
            annually: cargoLoadYearly,
            monthly: { ...cargoLoadMonthly, sesonalityData: fillEmptyValuewithNull(cargoLoadMonthly.sesonalityData, 'monthly') },
            weekly: { ...cargoLoadWeekly, sesonalityData: fillEmptyValuewithNull(cargoLoadWeekly.sesonalityData, 'weekly') },
        },
        mills: buyer,
        company: supplier,
        ports: portsdata,
        ports1: portsdata1
    }
    // setLoader(false)
    return graphData;
}

function applyNullIfZeroAtEndData(element, mode) {
    for (let j = 0; j < element.length; j++) {
        if (element[j].noOfVessel === 0 || element[j].load === 0) {
            mode === 'vessel' ? element[j].noOfVessel = NaN : element[j].load = NaN;
        } else {
            break;
        }

    }
}
function getDailyData(newData, mode) {
    const now = new Date(2024, 12, 31);
    const currentDate = new Date();
    let tempArr = []
    let valueToSet = []
    for (let index = 0; index < newData.length; index++) {
        valueToSet[index] = NaN;
    }
    for (let d = new Date(2024, 0, 1); d <= now; d.setDate(d.getDate() + 1)) {
        for (let index = 0; index < newData.length; index++) {
            const f = newData[index].find(f => {
                return (d.getDate() == f.daily.split('-')[1] && (d.getMonth() + 1 == f.daily.split('-')[0]))
            }
            )
            tempArr[index] = f;
        }
        for (let j = 0; j < tempArr.length; j++) {
            if (tempArr[j] == undefined) {
                // if (currentDate.getFullYear() == newData[j][0].year && d.getMonth() > currentDate.getMonth())
                //     continue;
                // if (currentDate.getFullYear() == newData[j][0].year && currentDate.getMonth() == d.getMonth() && d.getDate() > currentDate.getDate())
                //     continue;

                let obj = { daily: `${d.getMonth() + 1}-${d.getDate()}`, year: newData[j][0].year.toString(), load: valueToSet[j], date: `${d.getMonth() + 1}-${d.getDate()}-${newData[j][0].year}` };
                if (mode === 'vessel')
                    obj = { daily: `${d.getMonth() + 1}-${d.getDate()}`, year: newData[j][0].year.toString(), noOfVessel: valueToSet[j], date: `${d.getMonth() + 1}-${d.getDate()}-${newData[j][0].year}` };

                newData[j].push(obj)
            } else {
                valueToSet[j] = 0;
            }
        }
        tempArr = []
    }
    // Sort data and Add NaN if last with zero value 
    let finalArray = []
    for (let index = 0; index < newData.length; index++) {
        const element = sortData(newData[index], 'date');
        applyNullIfZeroAtEndData(element, mode);
        finalArray = finalArray.concat(element)
    }
    return finalArray;

}

function getWeeklyData(newData, mode) {
    const currentDate = new Date();
    let tempArr = []
    let valueToSet = []
    for (let index = 0; index < newData.length; index++) {
        valueToSet[index] = NaN;
    }
    for (let w = 1; w <= 52; w++) {
        for (let index = 0; index < newData.length; index++) {
            const f = newData[index].find(f => {
                return f.weekly.substring(1) == w;
            }
            )
            tempArr[index] = f;
        }
        for (let j = 0; j < tempArr.length; j++) {
            if (tempArr[j] == undefined) {
                // if (currentDate.getFullYear() == newData[j][0].year && w > getWeekNumber(currentDate))
                //     continue;

                let obj = { weekly: 'W' + w, year: newData[j][0].year, load: valueToSet[j] }
                if (mode === 'vessel')
                    obj = { weekly: 'W' + w, year: newData[j][0].year, noOfVessel: valueToSet[j] }
                newData[j].push(obj)
            } else {
                valueToSet[j] = 0;
            }
        }
        tempArr = []
    }
    let finalArray = []
    for (let index = 0; index < newData.length; index++) {
        const element = sortData(newData[index], 'weekly');
        applyNullIfZeroAtEndData(element, mode);
        finalArray = finalArray.concat(element)
    }
    return finalArray;
}
function getMonthlyData(newData, mode) {
    const currentDate = new Date();
    let tempArr = []
    let valueToSet = []
    for (let index = 0; index < newData.length; index++) {
        valueToSet[index] = NaN;
    }
    for (let w = 0; w <= 11; w++) {
        for (let index = 0; index < newData.length; index++) {
            const f = newData[index].find(f => {
                return monthArray.indexOf(f.monthly) == w;
            }
            )
            tempArr[index] = f;
        }
        for (let j = 0; j < tempArr.length; j++) {
            if (tempArr[j] == undefined) {
                // if (currentDate.getFullYear() == newData[j][0].year && w > currentDate.getMonth())
                //     continue;

                let obj = { monthly: monthArray[w], year: newData[j][0].year, load: valueToSet[j] }
                if (mode === 'vessel')
                    obj = { monthly: monthArray[w], year: newData[j][0].year, noOfVessel: valueToSet[j] }

                newData[j].push(obj)
            } else {
                valueToSet[j] = 0;
            }
        }
        tempArr = []
    }
    let finalArray = []
    for (let index = 0; index < newData.length; index++) {
        const element = sortData(newData[index], 'monthly');
        applyNullIfZeroAtEndData(element, mode);
        finalArray = finalArray.concat(element)
    }
    return finalArray;
}
export function fillEmptyValuewithNull(data, type, mode) {
    const newData = []
    data.forEach((d) => {
        if (newData.length == 0) {
            newData[0] = [];
            newData[0].push(d);
        } else {
            if (newData[newData.length - 1][0].year == d.year)
                newData[newData.length - 1].push(d);
            else {
                newData[newData.length] = [];
                newData[newData.length - 1].push(d);
            }
        }
    })
    if (type == 'daily')
        return getDailyData(newData, mode);
    if (type == 'weekly')
        return getWeeklyData(newData, mode);
    if (type == 'monthly')
        return getMonthlyData(newData, mode);

}
export function sortData(data, type) {
    return data.sort(function (a, b) {
        let value1 = 0;
        let value2 = 0;
        if (type == 'weekly') {
            value1 = a.weekly.substring(1);
            value2 = b.weekly.substring(1);
        }
        if (type == 'daily') {
            value1 = a.daily.split('-')[1];
            value2 = b.daily.split('-')[1];
        }
        if (type == 'daily2') {
            value1 = a.daily.split('-')[0];
            value2 = b.daily.split('-')[0];
        }
        if (type == 'monthly') {
            value1 = monthArray.indexOf(a.monthly);
            value2 = monthArray.indexOf(b.monthly);
        }
        if (type == 'date') {
            return new Date(b.date) - new Date(a.date);
        }
        return Number(value2 - value1)
    })
}
function containsObject(parsedData, obj) {
    let index = -1
    for (let i = 0; i < parsedData.length; i++) {
        if (parsedData[i].date === obj.date) {
            index = i;
            return index;
        }
    }

    return index;
}
export function ParseChartData(data, type) {
    const parsedData = [];
    data.map((d) => {
        const index = containsObject(parsedData, d)
        if (index !== -1) {
            if (type == "vessel") {
                parsedData[index].noOfVessel = parsedData[index].noOfVessel + d.noOfVessel;
            } else
                parsedData[index].load = parsedData[index].load + d.load;
        } else {
            parsedData.push(d);
        }
    })
    return parsedData;
}