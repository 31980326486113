import React, { useEffect, useState } from 'react';
import { Popover } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { Pie } from '@ant-design/plots';

const ChartPopOverDialog = ({ record }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        try {
            if (record.details) {
                let result = [];
                const jsonObj = JSON.parse(record.details)
                Object.entries(jsonObj).map(([key, value]) => {
                    const temp = { type: key, value: value }
                    result.push(temp);
                });
                console.log("Parsing Obj", result);
                setData(result);
            }
        } catch (error) {
            setData([]);
            console.log("Parsing error");
        }
    }, [record])
    const config = {
        appendPadding: 10,
        data: data,
        angleField: "value",
        colorField: "type",
        radius: 1,
        innerRadius: 0.5,
        label: {
            type: "inner",
            offset: "-50%",
            content: "{value}",
            style: {
                textAlign: "center",
                fontSize: 14
            }
        },
        interactions: [{ type: "element-selected" }, { type: "element-active" }],
        statistic: {
            title: false,
            content: {
                style: {
                    whiteSpace: "pre-wrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                },
                formatter: function formatter() {
                    return `Total\n${data.length}`;
                }
            }
        }
    };
    return (

        <Popover placement="rightTop" content={<Pie {...config} />} open={isOpen}>
            <span onMouseLeave={() => { setIsOpen(false) }} onMouseEnter={() => { setIsOpen(true) }} style={{ display: 'flex', gap: 5 }}>
                <p>{record?.expected_buyer + " " + record?.colour ?? ''} </p><EditOutlined />
            </span>
        </Popover>
    );
};
export default ChartPopOverDialog;