import { useEffect, useState } from "react";
import ChartPopOverDialog from "../Misc/ChartPopOverDialog";
import { values } from "lodash";
import { Pie } from "@ant-design/plots";
import { Popover } from "antd";
import { EditFilled } from "@ant-design/icons";
import { createRoot } from 'react-dom/client';

export const IntransitPopup = ({ companyRecord }) => {
    useEffect(() => {
        // Get the element by ID
        const element = document.getElementById('intransit-expected_buyer');

        if (element) {
            // Define hover in and hover out actions
            const root = createRoot(element);
            root.render(<ChartPopOverDialog record={companyRecord} isVoyges={false} />);
        }
    }, []);

    return (
        <>
        </>
    );

}