import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { company_apis } from './api'
const initialState = {
  seaRoutes: [],
  coloadRoute: []
}

var allSeaRoutes = []
var allCoload = []
const dbName = 'myDatabase'
const dbVersion = 1
let allDbData;
export const getSeaRoutes = createAsyncThunk('data/seaRoutes', async token => {
  // const response = await getAllSeaRoutes(1, token)
  const response = await getAllData(token);
  console.log("All routes added", response)
  localStorage.setItem('seaRoutes', JSON.stringify(response?.alldata))
  return response
})

export const getCoLoadRoutes = createAsyncThunk('data/coload', async token => {
  response = await getAllSeaCoload(1, token)
  console.log(response)
  return response
})

export const filterRoutesIds = (routesIds) => {
  const filterList = [];
  if (!allDbData || allDbData.length == 0)
    return routesIds;

  for (let index = 0; index < routesIds.length; index++) {
    const id = routesIds[index];
    const filterId = allDbData.some(data => data.id == id)
    if (!filterId)
      filterList.push(id);
  }

  return filterList;
}

export function getAllDataFromDB() {

  return new Promise((resolve, reject) => {
    openDB()
      .then(db => {
        const transaction = db.transaction(['myObjectStore'], 'readonly')
        const objectStore = transaction.objectStore('myObjectStore')

        const getRequest = objectStore.getAll()

        getRequest.onsuccess = function (event) {
          const dbData = event.target.result;
          if (dbData && dbData.length > 0)
            allDbData = dbData[0].allDbData
          else
            allDbData = []
          resolve(event.target.result)
        }

        getRequest.onerror = function (event) {
          reject(event.target.error)
        }
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function RemoveAllDataFromDB() {
  return new Promise((resolve, reject) => {
    openDB()
      .then(db => {
        const transaction = db.transaction(['myObjectStore'], 'readwrite')
        const objectStore = transaction.objectStore('myObjectStore')

        const getRequest = objectStore.clear();

        getRequest.onsuccess = function (event) {
          allDbData = event.target.result;
          resolve(event.target.result)
        }

        getRequest.onerror = function (event) {
          reject(event.target.error)
        }
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const getRouteData = (currentCompanyid) => {
  return allDbData.filter(route => route.id == currentCompanyid);
}
export const getRouteDataByIds = async (ids) => {
  try {
    const token = localStorage.getItem('token');
    const result = await company_apis?.getSeaRoutes(`/shipData/companies/routes/shipdata?id=${ids?.toString()}`, token)
    result?.data?.data?.forEach((data) => {
      allDbData = [...allDbData, data];
    })

    return allDbData;
  } catch (error) {
    console.log("Route not found error: ", error)
    return [];
  }
}
export const getAllData = async (token) => {
  // let result1 = await checkNewDataAvailable(token);
  if (true) return;
}

const getAllSeaCoload = async (pageNo, token) => {
  await company_apis
    ?.getSeaRoutes(`/shipData/companies/routes/coload?page=${pageNo}`, token)
    .then(data => {
      console.log(data)
      allCoload = [...allCoload, ...data?.data?.data]
      if (pageNo < data?.data?.meta?.last_page) {
        getAllSeaCoload(data?.data?.meta?.current_page + 1, token)
      } else {
        localStorage.setItem('coloads', JSON.stringify(allCoload))
        data.alldata = allCoload

        return data
      }
    })
    .catch(err => {
      return []
    })
}

export async function updateDataToDB(allDbData) {
  return new Promise((resolve, reject) => {
    openDB()
      .then(db => {
        const transaction = db.transaction(['myObjectStore'], 'readwrite')
        const objectStore = transaction.objectStore('myObjectStore')
        const addRequest = objectStore.put({ id: 0, allDbData });
        addRequest.onsuccess = function (event) {
          resolve(event.target.result)
        }

        addRequest.onerror = function (event) {
          reject(event.target.error)
        }
      })
      .catch(error => {
        reject(error)
      })
  })
}
function openDB() {
  return new Promise((resolve, reject) => {
    const openRequest = indexedDB.open(dbName, dbVersion)

    openRequest.onupgradeneeded = function (event) {
      const db = event.target.result
      if (!db.objectStoreNames.contains('myObjectStore')) {
        db.createObjectStore('myObjectStore', {
          keyPath: 'id',
          autoIncrement: false
        })
      }
    }

    openRequest.onsuccess = function (event) {
      resolve(event.target.result)
    }

    openRequest.onerror = function (event) {
      reject(event.target.error)
    }
  })
}
export const companySlice = createSlice({
  name: 'company',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getSeaRoutes.fulfilled, (state, action) => {
        state.seaRoutes = action?.payload?.data?.data
      })
      .addCase(getSeaRoutes.rejected, (state, action) => {
        state.seaRoutes = []
      })
      .addCase(getCoLoadRoutes.fulfilled, (state, action) => {
        state.coloadRoute = action?.payload?.data?.data
      })
      .addCase(getCoLoadRoutes.rejected, (state, action) => {
        state.coloadRoute = []
      })
  }
})

export default companySlice?.reducer
