import React, { useEffect, useState } from 'react'
import './company.css'

import CompanyDropdowns from '../../Components/Companies/CompanyDropdowns'
import CompanyFilterDrawer from '../../Components/GlobalFilterDrawer'
import ChartDrawer from '../../Components/Companies/ChartDrawer'
import FiltersForm from '../../Components/Companies/FiltersForm'
import { company_apis } from './api'
import { useSelector } from 'react-redux'
import { Spin } from 'antd'
import { ParseChartData, fillEmptyValuewithNull, monthArray, sortData } from '../../utilities/chartData'
import _ from 'lodash'
import { checkBargesAccessLevel } from '../../utilities/utils'
import Map from '../../Components/map/'

const Company = React.memo(({ comodity }) => {
  const company = useSelector(state => state.company)
  const start = new Date(new Date().setDate(new Date()?.getDate() - 29))
  const end = new Date(new Date().setDate(new Date().getDate()));
  const [loader, setLoader] = useState(false)
  const [filterLoader, setFilterLoader] = useState(false)
  const [open, setOpen] = useState(false)
  const [uniqueFilter, setUniqueFilter] = useState(false)
  const [trailversion, setTrail] = useState(true)
  const [chartsOpen, setChartsOpen] = useState(false)
  const [mapLoad, setMapLoad] = useState(false)

  const [mapStyle, setMapStyle] = useState(
    'mapbox://styles/mapbox/satellite-streets-v12'
  )
  const [currentMap, setCurrentMap] = useState('satelite')
  const [projection, setProjection] = useState('mercator')
  const [done, setDone] = useState(false)
  const [updateUniqeFilter, setUpdateUniqueFilter] = useState(false);
  const [dwtValue, setDWTValues] = useState({})
  const [bargesAccessLevel, setBargesAccessLevel] = useState(false);
  const [legends, setLegends] = useState([]);

  const [Filters, setFilters] = useState({
    type: 'arrivals',
    currentFilter: {},
    applyfilter: {
      ata_utc_start: `${start?.getFullYear()}-${start?.getMonth() + 1 < 10
        ? '0' + (start?.getMonth() + 1)
        : start?.getMonth() + 1
        }-${start?.getDate() < 10 ? '0' + start?.getDate() : start?.getDate()}`,
      ata_utc_end: `${end?.getFullYear()}-${end?.getMonth() + 1 < 10
        ? '0' + (end.getMonth() + 1)
        : end.getMonth() + 1
        }-${end?.getDate() < 10 ? '0' + end?.getDate() : end?.getDate()}`
    },
    applyExtraFilter: {},
    extraFilter: {},
    childFilter: {},
    dateFilter: []
  })
  const [companydata, setcompanydata] = useState([])
  const [dataforgrapg, setDataforGraph] = useState({
    load: {},
    vessel: {},
    mills: [],
    ports: []
  })
  const [arrivalandshipment, setArrivalandShipment] = useState({})

  // useEffect(() => {
  //   if (Filters.type?.toLowerCase() == "intransit") {
  //     setMapStyle('mapbox://styles/mapbox/satellite-streets-v12')
  //     setCurrentMap('satelite');
  //   } else {
  //     setMapStyle('mapbox://styles/caspiangroup/cljpfar92003e01qo2jpfc83b')
  //     setCurrentMap('outdoors');
  //   }
  // }, [Filters.type])

  useEffect(() => {
    arrivalShipmentFilters()
    // getShipmentFilter()
    getCompaniesdata(Filters)
  }, [])

  useEffect(() => {
    if (legends.length !== 0)
      setLegends([])
  }, [Filters.type, comodity])
  useEffect(() => {
    setBargesAccessLevel(checkBargesAccessLevel());
    const ListId =
      localStorage.getItem('ListIds') == undefined
        ? []
        : JSON.parse(localStorage.getItem('ListIds'))
    const accessLevel =
      localStorage.getItem('AccessLevelData') == undefined
        ? []
        : JSON.parse(localStorage.getItem('AccessLevelData'))
    console.log(accessLevel)
    const user = JSON.parse(localStorage.getItem('navigateUser'))

    const currentItem = accessLevel?.find(i => 2 == i?.id)
    if (currentItem?.lists?.length == 0) {
      return
    }
    if (
      accessLevel &&
      // List Array
      ListId?.some(ai =>
        currentItem?.lists?.map(it => JSON.parse(it))?.includes(ai)
      )
    ) {
      let currentItem = accessLevel?.find(i => 6 == i?.id)
      if (currentItem?.lists?.length == 0) {
        setTrail(false)
      }
      if (
        accessLevel &&
        // List Array
        ListId?.some(ai =>
          currentItem?.lists?.map(it => JSON.parse(it))?.includes(ai)
        )
      )
        setTrail(true)
      else
        setTrail(false)
    }
  }, [])

  useEffect(() => {
    let Item = localStorage.getItem('tableItem')
    if (Item != null && Item != undefined) {
      alert('Hello uSER')
    }
  }, [localStorage.getItem('tableItem')])

  useEffect(() => {
    arrivalShipmentFilters()
    getShipmentFilter()
    // getCompaniesdata()
  }, [Filters?.type, Filters?.applyfilter])

  useEffect(() => {
    updateUniqeFilter && getShipmentExtraFilter();
    if (!updateUniqeFilter)
      setUpdateUniqueFilter(true);
    // getCompaniesdata()
  }, [Filters?.applyExtraFilter])

  // useEffect(() => {
  //   if (projection != undefined) {
  //     getCompaniesdata(Filters)
  //     // mapLoading()
  //   }
  // }, [projection])
  // drawer functions

  const applyExtraFilter = (key, item) => {
    if (key == 'atd_utc') {
      const applyTempFilter = Filters?.applyExtraFilter;
      if (item.atd_utc_start == "" && item.atd_utc_end == "") {
        delete applyTempFilter['atd_utc_start'];
        delete applyTempFilter['atd_utc_end'];
      } else {
        applyTempFilter['atd_utc_start'] = item.atd_utc_start;
        applyTempFilter['atd_utc_end'] = item.atd_utc_end;
      }
      setFilters({
        ...Filters,
        applyExtraFilter: applyTempFilter
      })
      return;
    }

    if (!isNaN(item))
      setUpdateUniqueFilter(false);

    setFilters({
      ...Filters,
      applyExtraFilter: {
        ...Filters?.applyExtraFilter,
        [key]: item
      }
    })
  }

  const extraFilteration = (legendFilter) => {

    const tempApplyExtraFilter = legendFilter ? legendFilter : Filters?.applyExtraFilter;
    const appFilter = {
      ...Filters?.applyfilter,
      ...tempApplyExtraFilter
    }
    if (comodity === 'stainlessscrap') {
      if (Filters.type == 'barges') {
        delete appFilter['dwt_start']
        delete appFilter['dwt_end']
        delete appFilter['loading_rate_start']
        delete appFilter['loading_rate_end']
      }
      if (Filters.type == 'arrivals') {
        delete appFilter['loading_rate_start']
        delete appFilter['loading_rate_end']
      }
    } else {
      if (Filters.type !== 'intransit' && comodity !== 'ironore') {
        if (Filters.type == 'barges') {
          delete appFilter['dwt_start']
          delete appFilter['dwt_end']
        } else {
          delete appFilter['total_cargo_load_start']
          delete appFilter['total_cargo_load_end']
        }
      }
      if (Filters.type !== 'intransit' && comodity == 'ironore') {
        if (Filters.type == 'arrivals') {
          delete appFilter['loading_rate_start']
          delete appFilter['loading_rate_end']
        }
      }
      if (Filters.type !== 'arrivals' && comodity == 'ironore') {
        delete appFilter['arr_total_cargo_load_start']
        delete appFilter['arr_total_cargo_load_end']
      }
    }
    if ((!isNaN(tempApplyExtraFilter?.arr_total_cargo_load_start) || !isNaN(tempApplyExtraFilter?.arr_total_cargo_load_end))) {
      if (tempApplyExtraFilter?.arr_total_cargo_load_start !== null && (tempApplyExtraFilter?.arr_total_cargo_load_end === null || tempApplyExtraFilter?.arr_total_cargo_load_end === undefined)) {
        appFilter['arr_total_cargo_load_end'] = 1000000;
        tempApplyExtraFilter['arr_total_cargo_load_end'] = 1000000;
      }
      if ((tempApplyExtraFilter?.arr_total_cargo_load_start === null || tempApplyExtraFilter?.arr_total_cargo_load_start === undefined) && tempApplyExtraFilter?.arr_total_cargo_load_end !== null) {
        appFilter['arr_total_cargo_load_start'] = 1;
        tempApplyExtraFilter['arr_total_cargo_load_start'] = 1;
      }
    }
    if ((!isNaN(tempApplyExtraFilter?.total_cargo_load_start) || !isNaN(tempApplyExtraFilter?.total_cargo_load_end))) {
      if (tempApplyExtraFilter?.total_cargo_load_start !== null && (tempApplyExtraFilter?.total_cargo_load_end === null || tempApplyExtraFilter?.total_cargo_load_end === undefined)) {
        appFilter['total_cargo_load_end'] = 1000000;
        tempApplyExtraFilter['total_cargo_load_end'] = 1000000;
      }
      if ((tempApplyExtraFilter?.total_cargo_load_start === null || tempApplyExtraFilter?.total_cargo_load_start === undefined) && tempApplyExtraFilter?.total_cargo_load_end !== null) {
        appFilter['total_cargo_load_start'] = 1;
        tempApplyExtraFilter['total_cargo_load_start'] = 1;
      }
    }
    if (!isNaN(tempApplyExtraFilter?.dwt_start) || !isNaN(tempApplyExtraFilter?.dwt_end)) {
      if (tempApplyExtraFilter?.dwt_start !== null && (tempApplyExtraFilter?.dwt_end === null || tempApplyExtraFilter?.dwt_end === undefined)) {
        appFilter['dwt_end'] = 1000000;
        tempApplyExtraFilter['dwt_end'] = 1000000;
      }
      if ((tempApplyExtraFilter?.dwt_start === null || tempApplyExtraFilter?.dwt_start === undefined) && tempApplyExtraFilter?.dwt_end !== null) {
        appFilter['dwt_start'] = 1;
        tempApplyExtraFilter['dwt_start'] = 1;
      }
    }
    if (!isNaN(tempApplyExtraFilter?.loading_rate_start) || !isNaN(tempApplyExtraFilter?.loading_rate_end)) {
      if (tempApplyExtraFilter?.loading_rate_start !== null && (tempApplyExtraFilter?.loading_rate_end === null || tempApplyExtraFilter?.loading_rate_end === undefined)) {
        appFilter['loading_rate_end'] = 1000000;
        tempApplyExtraFilter['loading_rate_end'] = 1000000;
      }
      if ((tempApplyExtraFilter?.loading_rate_start === null || tempApplyExtraFilter?.loading_rate_start === undefined) && tempApplyExtraFilter?.loading_rate_end !== null) {
        appFilter['loading_rate_start'] = 1;
        tempApplyExtraFilter['loading_rate_start'] = 1;
      }
    }
    setLegends([])
    setFilters({
      ...Filters,
      applyfilter: appFilter,
      applyExtraFilter: tempApplyExtraFilter,
    })
    getCompaniesdata({
      ...Filters,
      applyfilter: appFilter
    })
  }

  const resetFilter = () => {
    setLegends([])
    setFilters({
      ...Filters,
      applyfilter: {
        ata_utc_start: `${start?.getFullYear()}-${start?.getMonth() + 1 < 10
          ? '0' + (start?.getMonth() + 1)
          : start?.getMonth() + 1
          }-${start?.getDate() < 10 ? '0' + start?.getDate() : start?.getDate()
          }`,
        ata_utc_end: `${end?.getFullYear()}-${end?.getMonth() + 1 < 10 ? '0' + (end.getMonth() + 1) : end.getMonth() + 1
          }-${end?.getDate() < 10 ? '0' + end.getDate() : end.getDate()}`
      },
      applyExtraFilter: {},
      extraFilter: {},
      currentFilter: {},
      childFilter: {},
      type: 'arrivals'
    })
    setDWTValues({})
    getCompaniesdata({
      ...Filters,
      applyfilter: {
        ata_utc_start: `${start?.getFullYear()}-${start?.getMonth() + 1 < 10
          ? '0' + (start?.getMonth() + 1)
          : start?.getMonth() + 1
          }-${start?.getDate() < 10 ? '0' + start?.getDate() : start?.getDate()}`,
        ata_utc_end: `${end?.getFullYear()}-${end?.getMonth() + 1 < 10 ? '0' + (end.getMonth() + 1) : end.getMonth() + 1
          }-${end?.getDate() < 10 ? '0' + end.getDate() : end.getDate()}`
      },
      applyExtraFilter: {},
      extraFilter: {},
      currentFilter: {},
      type: 'arrivals'
    })
  }

  const selectSpecificFilter = type => {
    setFilters({ ...Filters, type: type })
  }

  const applyFilter = (item, key, filter) => {
    setLegends([])
    console.log(item, key, filter)
    if (filter == 'date') {
      const valueOfInput1 = item[0].format()
      const valueOfInput2 = item[1].format()
      const startdate = new Date(valueOfInput1)
      const enddate = new Date(valueOfInput2)
      const startyear = startdate.getFullYear()
      const startMonth =
        startdate?.getMonth() + 1 < 10
          ? '0' + (startdate?.getMonth() + 1)
          : startdate?.getMonth() + 1
      const startDate =
        startdate?.getDate() < 10
          ? '0' + startdate?.getDate()
          : startdate?.getDate()

      const endyear = enddate.getFullYear()
      const endMonth =
        enddate?.getMonth() + 1 < 10
          ? '0' + (enddate?.getMonth() + 1)
          : enddate?.getMonth() + 1
      const endDate =
        enddate?.getDate() < 10 ? '0' + enddate?.getDate() : enddate?.getDate()
      setFilters({
        ...Filters,
        applyfilter: {
          ...Filters?.applyfilter,
          ata_utc_start: `${startyear}-${startMonth}-${startDate}`,
          ata_utc_end: `${endyear}-${endMonth}-${endDate}`
        }
      })
      getCompaniesdata({
        ...Filters,
        applyfilter: {
          ...Filters?.applyfilter,
          ata_utc_start: `${startyear}-${startMonth}-${startDate}`,
          ata_utc_end: `${endyear}-${endMonth}-${endDate}`
        }
      })
    } else {
      console.log(arrivalandshipment)
      let element =
        Filters.type == 'arrivals' ? comodity == 'ironore' ? ['actual_dest_country', 'actual_dest', 'mill'] : ['dest_country', 'actual_dest', 'mill'] :
          Filters.type == 'departure' || Filters.type == 'intransit' || Filters.type == 'Shipments' ? ['country', 'port_name', 'company']
            : ['actual_dest_country', 'actual_dest', 'mill']

      let selectedFilter = {}
      if (element?.includes(item)) {
        for (const i of element) {
          if (i == item) {
            selectedFilter[item] = key
            break
          } else {
            selectedFilter[i] = Filters?.applyfilter?.[i]
          }
        }
        if (Filters.type == 'arrivals') {
          if (item === "dest_country") {
            console.log(item, key)
            selectedFilter = {
              "dest_country": key
            }
            if (arrivalandshipment.arrivals.hasOwnProperty('actual_dest')) {
              arrivalandshipment.arrivals.actual_dest = []
            }
            if (arrivalandshipment.arrivals.hasOwnProperty('mill')) {
              arrivalandshipment.arrivals.mill = []
            }
          }
          else if (item === "actual_dest") {
            if (selectedFilter.hasOwnProperty('mill')) {
              delete selectedFilter.mill
            }
            if (arrivalandshipment.arrivals.hasOwnProperty('mill')) {
              arrivalandshipment.arrivals.mill = []
            }
          }
        }
        else if (Filters.type == 'departure' || Filters.type == 'shipment' || Filters.type == 'Shipments') {
          if (item === "country") {
            console.log(item, key)
            selectedFilter = {
              "country": key
            }
            if (arrivalandshipment.Shipments.hasOwnProperty('port_name')) {
              arrivalandshipment.Shipments.port_name = []
            }
            if (arrivalandshipment.Shipments.hasOwnProperty('company')) {
              arrivalandshipment.Shipments.company = []
            }
          }
          else if (item === "port_name") {
            if (selectedFilter.hasOwnProperty('company')) {
              delete selectedFilter.company
            }
            if (arrivalandshipment.Shipments.hasOwnProperty('company')) {
              arrivalandshipment.Shipments.company = []
            }
          }
        } else if (Filters.type == 'intransit') {
          if (item === "country") {
            console.log(item, key)
            selectedFilter = {
              "country": key
            }
            if (arrivalandshipment.intransit.hasOwnProperty('port_name')) {
              arrivalandshipment.intransit.port_name = []
            }
            if (arrivalandshipment.intransit.hasOwnProperty('company')) {
              arrivalandshipment.intransit.company = []
            }
          }
          else if (item === "port_name") {
            if (selectedFilter.hasOwnProperty('company')) {
              delete selectedFilter.company
            }
            if (arrivalandshipment.intransit.hasOwnProperty('company')) {
              arrivalandshipment.intransit.company = []
            }
          }
        } else {
          if (item === "actual_dest_country") {
            console.log(item, key)
            selectedFilter = {
              "actual_dest_country": key
            }
            if (arrivalandshipment.arrivals.hasOwnProperty('actual_dest')) {
              arrivalandshipment.arrivals.actual_dest = []
            }
            if (arrivalandshipment.arrivals.hasOwnProperty('mill')) {
              arrivalandshipment.arrivals.mill = []
            }
          }
          else if (item === "actual_dest") {
            if (selectedFilter.hasOwnProperty('mill')) {
              delete selectedFilter.mill
            }
            if (arrivalandshipment.arrivals.hasOwnProperty('mill')) {
              arrivalandshipment.arrivals.mill = []
            }
          }
        }

        console.log(selectedFilter)
        console.log(arrivalandshipment)
        setFilters({
          ...Filters,
          applyfilter: {
            ata_utc_start: Filters?.applyfilter?.['ata_utc_start'],
            ata_utc_end: Filters?.applyfilter?.['ata_utc_end'],
            ...selectedFilter
          }
        })
        getCompaniesdata({
          ...Filters,
          applyfilter: {
            ata_utc_start: Filters?.applyfilter?.['ata_utc_start'],
            ata_utc_end: Filters?.applyfilter?.['ata_utc_end'],
            ...selectedFilter
          }
        })
      } else {
        setFilters({
          ...Filters,
          applyfilter: {
            ...Filters?.applyfilter,
            [item]: key
          }
        })
        getCompaniesdata({
          ...Filters,
          applyfilter: {
            ...Filters?.applyfilter,
            [item]: key
          }
        })
      }
    }
  }

  const addLegend = (legend) => {
    const newlegends = [...legends, legend];
    setLegends(newlegends);
    getCompaniesdata({
      ...Filters,
      'size_class': newlegends.toLocaleString()
    }, true)
  };

  const removeLegend = (legend) => {
    const newlegends = legends.filter((leg, i) => leg !== legend);
    setLegends(newlegends);
    getCompaniesdata({
      ...Filters,
      'size_class': newlegends.toLocaleString()
    }, true)
  };

  const getWeekNumber = date => {
    const d = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    )
    const dayNum = d.getUTCDay() || 7
    d.setUTCDate(d.getUTCDate() + 4 - dayNum)
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1))
    return Math.ceil(((d - yearStart) / 86400000 + 1) / 7)
  }
  const getFormatedDate = (d) => {
    const dateObj = new Date(d);
    const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
    const date = ('0' + dateObj.getDate()).slice(-2);
    const year = dateObj.getFullYear();
    const shortDate = year + '-' + month + '-' + date;
    return shortDate;
  }
  const getCompaniesdata = (Filters, isLegnedCalled) => {
    if (companydata?.length > 0 && loader == true) {
      return
    }
    setLoader(true)
    setcompanydata([])
    console.log(Filters)
    const token = localStorage.getItem('token')
    const today = new Date(Filters?.applyfilter?.ata_utc_end)
    console.log(today)
    const beforMonth = new Date(Filters?.applyfilter?.ata_utc_start)

    const end = getFormatedDate(`${today?.getMonth() + 1
      }/${today?.getDate()}/${today.getFullYear()}`)
    const start = getFormatedDate(`${beforMonth?.getMonth() + 1
      }/${beforMonth?.getDate()}/${beforMonth.getFullYear()}`)
    let url = `/${comodity}/companies/${Filters.type?.toLowerCase()}`
    if (Filters.type?.toLowerCase() == "barges")
      url = `${comodity == 'shipData' ? '' : comodity}/barges/companies/arrivals`;
    if (Filters.type?.toLowerCase() == "intransit")
      url = `/${comodity}/intransit/companies/data`
    if (Filters.type?.toLowerCase() == "intransit" && comodity == 'shipData')
      url = '/intransit/companies/data';

    let originalObject = JSON.parse(JSON.stringify(Filters))
    delete originalObject?.applyfilter['ata_utc_start']
    delete originalObject?.applyfilter['ata_utc_end']
    console.log(url)
    if (Filters?.type == 'arrivals' || Filters?.type == 'barges') {
      originalObject = {
        ...originalObject?.applyfilter,
        ata_utc_start: start,
        ata_utc_end: end
        // dest_country: Filters?.applyfilter?.['dest_country'],
        // actual_dest: Filters?.applyfilter?.['actual_dest']
      }
    } else {
      originalObject = {
        ...originalObject?.applyfilter,
        timestamp_utc_start: start,
        timestamp_utc_end: end
        // country: Filters?.applyfilter?.['country'],
        // port_name: Filters?.applyfilter?.['port_name']
      }
    }

    if (Filters.size_class && isLegnedCalled) {
      originalObject = { ...originalObject, size_class: Filters.size_class }
    }
    company_apis.getCompaniesdata(
      url,
      { ...originalObject },
      token,
      (err, data) => {
        if (err == null) {
          let coloaddata;
          console.log(data?.data?.data)
          coloaddata = data?.data?.data?.map(item => {
            let findRoutes = company?.coloadRoute?.find(
              route => route?.id == item?.unique_coload_id
            )
            if (findRoutes != null && findRoutes != undefined) {
              return { ...item, coloadRoutes: findRoutes?.coords }
            } else {
              return { ...item, coloadRoutes: [] }
            }
          })
          console.log(coloaddata)
          setcompanydata(coloaddata)
          if (coloaddata.length === 0) {
            setDone(true)
            setLoader(false)
          }
          else {
            setDone(false)
          }
          let numberOfVesselsYealy = []
          let numberOfVesselsmonthly = { numberOfVesselsmonthly: [], sesonalityData: [] }
          let numberOfVesselsWeekly = { numberOfVesselsWeekly: [], sesonalityData: [] }
          let numberOfVesselsdaily = { numberOfVesselsdaily: [], sesonalityData: [] }
          let cargoLoadYearly = []
          let cargoLoadMonthly = { cargoLoadMonthly: [], sesonalityData: [] }
          let cargoLoadDaily = { cargoLoadDaily: [], sesonalityData: [] }
          let cargoLoadWeekly = { cargoLoadWeekly: [], sesonalityData: [] }

          let loadKey = 'total_cargo_load';
          if (comodity == 'ironore') {
            loadKey = Filters?.type == 'arrivals' ? 'arr_total_cargo_load' : loadKey;
          }
          // Filters?.type == 'arrivals'
          //   ? 'total_cargo_load'
          //   : comodity == 'shipData' ? 'Estimated Cargo Load (t)' : 'total_cargo_load';
          loadKey = Filters?.type == 'barges' ? 'estimated_cargo_load' : loadKey;
          data?.data?.data?.forEach(item => {
            let data = new Date(
              item?.ata_utc || item?.timestamp_utc
            ).getFullYear()
            let index = numberOfVesselsYealy?.findIndex(i => data == i.annually)
            let loadIndex = cargoLoadYearly?.findIndex(i => data == i.annually)
            let cargoElement = cargoLoadYearly?.find(
              item => item?.annually == data
            )
            let element = numberOfVesselsYealy?.find(
              item => item?.annually == data
            )
            if (index != -1) {
              numberOfVesselsYealy[index] = {
                ...element,
                noOfVessel: element?.noOfVessel + 1
              }
            } else {
              numberOfVesselsYealy.push({
                noOfVessel: 1,
                annually: data
              })
            }
            if (loadIndex != -1) {
              cargoLoadYearly[index] = {
                ...cargoElement,
                load: cargoElement?.load + Number(Number(item?.[loadKey]))
              }
            } else {
              cargoLoadYearly.push({
                load: Number(Number(item?.[loadKey])),
                annually: data
              })
            }
          })

          data?.data?.data?.forEach(item => {
            let year = new Date(
              item?.ata_utc || item?.timestamp_utc
            ).getFullYear()
            let month = new Date(
              item?.ata_utc || item?.timestamp_utc
            )?.getMonth()
            let key = `${year}-${month + 1}`
            let index = numberOfVesselsmonthly.numberOfVesselsmonthly?.findIndex(i => key == i.monthly)
            let element = numberOfVesselsmonthly.numberOfVesselsmonthly?.find(
              item => item?.monthly == key
            )

            let cargoIndex = cargoLoadMonthly.cargoLoadMonthly?.findIndex(i => key == i.monthly)
            let cargoelement = cargoLoadMonthly.cargoLoadMonthly?.find(
              item => item?.monthly == key
            )
            if (cargoIndex != -1) {
              cargoLoadMonthly.sesonalityData[cargoIndex].load = cargoelement?.load + Number(item?.[loadKey])
              cargoLoadMonthly.cargoLoadMonthly[cargoIndex] = {
                ...cargoelement,
                load: cargoelement?.load + Number(item?.[loadKey])
              }
            } else {
              cargoLoadMonthly.cargoLoadMonthly.push({
                load: Number(item?.[loadKey]),
                monthly: key
              })
              const monthlyKey = key.split('-');
              cargoLoadMonthly.sesonalityData.push({ monthly: monthArray[Number(monthlyKey[1]) - 1], year: monthlyKey[0], load: Number(item?.[loadKey]) })
            }
            if (index != -1) {
              numberOfVesselsmonthly.sesonalityData[index].noOfVessel = element?.noOfVessel + 1
              numberOfVesselsmonthly.numberOfVesselsmonthly[index] = {
                ...element,
                noOfVessel: element?.noOfVessel + 1
              }
            } else {
              numberOfVesselsmonthly.numberOfVesselsmonthly.push({
                noOfVessel: 1,
                monthly: key,
              })
              const monthlyKey = key.split('-');
              numberOfVesselsmonthly.sesonalityData.push({ monthly: monthArray[Number(monthlyKey[1]) - 1], year: monthlyKey[0], noOfVessel: 1 })
            }
          })

          data?.data?.data?.forEach(item => {
            let year = new Date(
              item?.ata_utc || item?.timestamp_utc
            ).getFullYear()
            let month = new Date(
              item?.ata_utc || item?.timestamp_utc
            )?.getMonth()
            let date = new Date(item?.ata_utc || item?.timestamp_utc)?.getDate()
            let key = `${year}-${month + 1}-${date}`
            let index = numberOfVesselsdaily.numberOfVesselsdaily?.findIndex(i => key == i.daily)
            let element = numberOfVesselsdaily.numberOfVesselsdaily?.find(item => item?.daily == key)
            if (index != -1) {
              numberOfVesselsdaily.sesonalityData[index].noOfVessel = element?.noOfVessel + 1;
              numberOfVesselsdaily.numberOfVesselsdaily[index] = {
                ...element,
                noOfVessel: element?.noOfVessel + 1
              }
            } else {
              numberOfVesselsdaily.numberOfVesselsdaily.push({
                noOfVessel: 1,
                daily: key
              })

              numberOfVesselsdaily.sesonalityData.push({ daily: `${month + 1}-${date}`, year: year.toString(), noOfVessel: 1, date: key })
            }
            let cargoindex = cargoLoadDaily.cargoLoadDaily?.findIndex(i => key == i.daily)
            let cargoelement = cargoLoadDaily.cargoLoadDaily?.find(item => item?.daily == key)
            if (cargoindex != -1) {
              cargoLoadDaily.sesonalityData[cargoindex].load = cargoelement?.load + Number(item?.[loadKey]);
              cargoLoadDaily.cargoLoadDaily[cargoindex] = {
                ...cargoelement,
                load: cargoelement?.load + Number(item?.[loadKey])
              }
            } else {
              cargoLoadDaily.cargoLoadDaily.push({
                load: Number(item?.[loadKey]),
                daily: key
              })
              cargoLoadDaily.sesonalityData.push({ daily: `${month + 1}-${date}`, year: year.toString(), load: Number(item?.[loadKey]), date: key })
            }
          })
          data?.data?.data?.forEach(item => {
            let date = new Date(item?.ata_utc || item?.timestamp_utc)
            let year = date.getFullYear()
            let weekNumber = getWeekNumber(date)
            let key = `${year}-W${weekNumber}`

            let index = numberOfVesselsWeekly.numberOfVesselsWeekly?.findIndex(i => key == i.weekly)
            let element = numberOfVesselsWeekly.numberOfVesselsWeekly?.find(
              item => item?.weekly == key
            )

            let cargoIndex = cargoLoadWeekly?.cargoLoadWeekly.findIndex(i => key == i.weekly)
            let cargoelement = cargoLoadWeekly?.cargoLoadWeekly.find(
              item => item?.weekly == key
            )

            if (cargoIndex != -1) {
              cargoLoadWeekly.sesonalityData[cargoIndex].load = cargoelement?.load + Number(item?.[loadKey])
              cargoLoadWeekly.cargoLoadWeekly[cargoIndex] = {
                ...cargoelement,
                load: cargoelement?.load + Number(item?.[loadKey])
              }
            } else {
              cargoLoadWeekly.cargoLoadWeekly.push({
                load: Number(item?.[loadKey]),
                weekly: key,
              })
              const weeklyKey = key.split('-');
              cargoLoadWeekly.sesonalityData.push({ weekly: weeklyKey[1], year: weeklyKey[0], load: Number(item?.[loadKey]) })
            }

            if (index != -1) {
              numberOfVesselsWeekly.sesonalityData[index].noOfVessel = element?.noOfVessel + 1
              numberOfVesselsWeekly.numberOfVesselsWeekly[index] = {
                ...element,
                noOfVessel: element?.noOfVessel + 1
              }
            } else {
              numberOfVesselsWeekly.numberOfVesselsWeekly.push({
                noOfVessel: 1,
                weekly: key,
              })
              const weeklyKey = key.split('-');
              numberOfVesselsWeekly.sesonalityData.push({ weekly: weeklyKey[1], year: weeklyKey[0], noOfVessel: 1 })
            }
          })
          console.log(numberOfVesselsWeekly)
          console.log(cargoLoadWeekly)
          let buyer = []
          let supplier = []
          let portsdata = []
          let portsdata1 = []
          if (Filters?.type == 'barges') {
            data?.data?.data?.forEach(item => {
              const index = buyer.findIndex(i => i?.name == item?.mill)
              const index1 = supplier.findIndex(i => i?.name == item?.company)
              const buy = buyer.find(i => i?.name == item?.mill)
              const sup = supplier.find(i => i?.name == item?.company)
              if (index != -1) {
                buyer[index] = { name: buy?.name, value: buy?.value + Number(item?.estimated_cargo_load) }
              } else {
                buyer.push({ name: item?.mill, value: Number(item?.estimated_cargo_load) })
              }

              if (index1 != -1) {
                supplier[index1] = { name: sup?.name, value: sup?.value + Number(item?.estimated_cargo_load) }
              }
              else {
                supplier.push({ name: item?.company, value: Number(item?.estimated_cargo_load) })
              }

              const portindex = portsdata.findIndex(
                i => i?.name == item?.actual_dest
              )
              const port = portsdata.find(i => i?.name == item?.actual_dest)

              const portindex1 = portsdata1.findIndex(
                i => i?.name == item?.port_name
              )
              const port1 = portsdata1.find(i => i?.name == item?.port_name)

              if (portindex != -1) {
                portsdata[portindex] = { name: port?.name, value: port?.value + Number(item?.estimated_cargo_load) }
              } else {
                portsdata.push({ name: item?.actual_dest, value: Number(item?.estimated_cargo_load) })
              }

              if (portindex1 != -1) {
                portsdata1[portindex1] = { name: port1?.name, value: port1?.value + Number(item?.estimated_cargo_load) }
              } else {
                portsdata1.push({ name: item?.port_name, value: Number(item?.estimated_cargo_load) })
              }
            })
          } else if (Filters?.type == 'arrivals') {
            data?.data?.data?.forEach(item => {
              const index = buyer.findIndex(i => i?.name == item?.mill)
              const index1 = supplier.findIndex(i => i?.name == item?.company)
              const buy = buyer.find(i => i?.name == item?.mill)
              const sup = supplier.find(i => i?.name == item?.company)
              let _cargo_load = comodity == 'ironore' ? Number(item?.arr_total_cargo_load) : Number(item?.total_cargo_load)
              if (index != -1) {
                buyer[index] = { name: buy?.name, value: buy?.value + _cargo_load }
              } else {
                buyer.push({ name: item?.mill, value: _cargo_load })
              }

              if (index1 != -1) {
                supplier[index1] = { name: sup?.name, value: sup?.value + _cargo_load }
              }
              else {
                supplier.push({ name: item?.company, value: _cargo_load })
              }

              const portindex = portsdata.findIndex(
                i => i?.name == item?.actual_dest
              )
              const port = portsdata.find(i => i?.name == item?.actual_dest)

              const portindex1 = portsdata1.findIndex(
                i => i?.name == item?.previous_port_name
              )
              const port1 = portsdata1.find(i => i?.name == item?.previous_port_name)

              if (portindex != -1) {
                portsdata[portindex] = { name: port?.name, value: port?.value + _cargo_load }
              } else {
                portsdata.push({ name: item?.actual_dest, value: _cargo_load })
              }

              if (portindex1 != -1) {
                portsdata1[portindex1] = { name: port1?.name, value: port1?.value + _cargo_load }
              } else {
                portsdata1.push({ name: item?.previous_port_name, value: _cargo_load })
              }
            })
          } else {
            data?.data?.data?.forEach(item => {
              const index = buyer.findIndex(i => i?.name == item?.company)
              const buy = buyer.find(i => i?.name == item?.company)
              let _cargo_load = 0;
              // if (comodity == 'shipData')
              //   _cargo_load = Number(item["Estimated Cargo Load (t)"]);
              // else
              _cargo_load = Number(item["total_cargo_load"])

              if (index != -1) {
                buyer[index] = { name: buy?.name, value: buy?.value + _cargo_load }
              } else {
                buyer.push({ name: item?.company, value: _cargo_load })
              }

              const portindex = portsdata.findIndex(
                i => i?.name == item?.port_name
              )
              const port = portsdata.find(i => i?.name == item?.port_name)

              const portindex1 = portsdata1.findIndex(
                i => i?.name == item?.destination
              )
              const port1 = portsdata1.find(i => i?.name == item?.destination)

              if (portindex != -1) {
                portsdata[portindex] = { name: port?.name, value: port?.value + _cargo_load }
              } else {
                portsdata.push({ name: item?.port_name, value: _cargo_load })
              }

              if (portindex1 != -1) {
                portsdata1[portindex1] = { name: port1?.name, value: port1?.value + _cargo_load }
              } else {
                portsdata1.push({ name: item?.destination, value: _cargo_load })
              }
            })
          }
          portsdata = portsdata.filter(data => data != null)
          portsdata1 = portsdata1.filter(data => data != null)

          setDataforGraph({
            ...dataforgrapg,
            vessel: {
              daily: { ...numberOfVesselsdaily, sesonalityData: fillEmptyValuewithNull(numberOfVesselsdaily.sesonalityData, 'daily', 'vessel') },
              annually: numberOfVesselsYealy,
              monthly: { ...numberOfVesselsmonthly, sesonalityData: fillEmptyValuewithNull(numberOfVesselsmonthly.sesonalityData, 'monthly', 'vessel') },
              weekly: { ...numberOfVesselsWeekly, sesonalityData: fillEmptyValuewithNull(numberOfVesselsWeekly.sesonalityData, 'weekly', 'vessel') },
            },
            load: {
              daily: { ...cargoLoadDaily, sesonalityData: fillEmptyValuewithNull(cargoLoadDaily.sesonalityData, 'daily') },
              annually: cargoLoadYearly,
              monthly: { ...cargoLoadMonthly, sesonalityData: fillEmptyValuewithNull(cargoLoadMonthly.sesonalityData, 'monthly') },
              weekly: { ...cargoLoadWeekly, sesonalityData: fillEmptyValuewithNull(cargoLoadWeekly.sesonalityData, 'weekly') },
            },
            mills: buyer,
            company: supplier,
            ports: portsdata,
            ports1: portsdata1
          })
          // setLoader(false)
        } else {
          setcompanydata([])
          // setLoader(false)
        }
      }
    )
  }

  const arrivalShipmentFilters = () => {
    const Dropdowns =
      Filters.type == 'arrivals'
        ? {
          'Arrival Country': comodity == 'ironore' ? 'actual_dest_country' : 'dest_country',
          'Arrival Port': 'actual_dest',
          'Buyer': 'mill'
        }
        : Filters.type == 'barges' ? {
          'Arrival Country': 'actual_dest_country',
          'Arrival Port': 'actual_dest',
          'Buyer': 'mill'
        } : {
          'Departure Country': 'country',
          'Departure Port': 'port_name',
          'Supplier': 'company'
        }
    const extraFilter =
      Filters.type == 'arrivals'
        ? (comodity == 'ironore') ? {
          'Ship Type': 'ship_type',
          'Ship Name': 'ship_name',
          'Origin Country': 'country',
          'Origin Port': 'previous_port_name',
          'Co Discharging Port': 'co_discharge',
          'Supplier': 'company',
          'Vessel Class': 'size_class',
          'Co Loading Port': 'co_load'
        } : (comodity == 'stainlessscrap') ? {
          'Ship Type': 'ship_type',
          'Ship Name': 'ship_name',
          'Origin Country': 'country',
          'Origin Port': 'previous_port_name',
          'Supplier': 'company',
          'Vessel Class': 'size_class',
          'Co Loading Port': 'co_load'
        } : {
          'Ship Type': 'ship_type',
          'Ship Name': 'ship_name',
          'Origin Country': 'country',
          'Origin Port': 'previous_port_name',
          'Supplier': 'company',
          'Vessel Class': 'size_class'
        }
        : Filters.type == 'barges' ? {
          'Ship Type': 'ship_type',
          'Ship Name': 'ship_name',
          'Origin Country': 'country',
          'Origin Port': 'port_name',
          'Supplier': 'company',

        }
          : Filters.type == 'intransit' ? {
            'Ship Type': 'ship_type',
            'Ship Name': 'ship_name',
            'Destination Country': 'dest_country',
            'Destination Port': 'destination',
            'Vessel Class': 'size_class',
            'Co Loading Port': 'co_load',
            'Expected Buyer': 'expected_buyer',
          }
            : (comodity == 'stainlessscrap' && Filters.type == 'Shipments') ?
              {
                'Ship Type': 'ship_type',
                'Ship Name': 'ship_name',
                'Destination Country': 'dest_country',
                'Destination Port': 'destination',
                'Vessel Class': 'size_class',
                'Co Loading Port': 'co_load'
              }
              : comodity == 'ironore' ? {
                'Ship Type': 'ship_type',
                'Ship Name': 'ship_name',
                'Destination Country': 'dest_country',
                'Destination Port': 'destination',
                'Vessel Class': 'size_class',
                'Co Loading Port': 'co_load'
              } : {
                'Ship Type': 'ship_type',
                'Ship Name': 'ship_name',
                'Destination Country': 'dest_country',
                'Destination Port': 'destination',
                'Vessel Class': 'size_class'
                // Company: 'company',
              }

    let childFilter =
      Filters.type == 'barges' ? [{
        title: 'Estimated Cargo Load',
        type: 'children',
        children: [
          {
            title: 'Min',
            type: 'text',
            key: 'total_cargo_load_start'
          },
          {
            title: 'Max',
            type: 'text',
            key: 'total_cargo_load_end'
          }
        ]
      }] : [{
        title: 'DWT',
        type: 'children',
        children: [
          {
            title: 'Min',
            type: 'text',
            key: 'dwt_start'
          },
          {
            title: 'Max',
            type: 'text',
            key: 'dwt_end'
          }
        ]
      }];

    if (comodity == 'stainlessscrap' && Filters.type == 'arrivals') {
      childFilter = [{
        title: 'Estimated Cargo Load',
        type: 'children',
        children: [
          {
            title: 'Min',
            type: 'text',
            key: 'total_cargo_load_start'
          },
          {
            title: 'Max',
            type: 'text',
            key: 'total_cargo_load_end'
          }
        ]
      }, {
        title: 'DWT',
        type: 'children',
        children: [
          {
            title: 'Min',
            type: 'text',
            key: 'dwt_start'
          },
          {
            title: 'Max',
            type: 'text',
            key: 'dwt_end'
          }
        ]
      }];
    }

    childFilter = Filters.type == 'intransit' || ((comodity == 'ironore' || comodity == 'stainlessscrap') && Filters.type == 'Shipments') ?
      [{
        title: 'DWT',
        type: 'children',
        children: [
          {
            title: 'Min',
            type: 'text',
            key: 'dwt_start'
          },
          {
            title: 'Max',
            type: 'text',
            key: 'dwt_end'
          }
        ]
      }, {
        title: 'Estimated Cargo Load',
        type: 'children',
        children: [
          {
            title: 'Min',
            type: 'text',
            key: 'total_cargo_load_start'
          },
          {
            title: 'Max',
            type: 'text',
            key: 'total_cargo_load_end'
          }
        ]
      }, {
        title: 'Loading Rate (t/hr)',
        type: 'children',
        children: [
          {
            title: 'Min',
            type: 'text',
            key: 'loading_rate_start'
          },
          {
            title: 'Max',
            type: 'text',
            key: 'loading_rate_end'
          }
        ]
      }] : childFilter;

    if (comodity == 'ironore' && Filters.type == 'arrivals') {
      childFilter = [{
        title: 'DWT',
        type: 'children',
        children: [
          {
            title: 'Min',
            type: 'text',
            key: 'dwt_start'
          },
          {
            title: 'Max',
            type: 'text',
            key: 'dwt_end'
          }
        ]
      }, {
        title: 'Estimated Cargo Load',
        type: 'children',
        children: [
          {
            title: 'Min',
            type: 'text',
            key: 'arr_total_cargo_load_start'
          },
          {
            title: 'Max',
            type: 'text',
            key: 'arr_total_cargo_load_end'
          }
        ]
      }]
    }
    const dateFilter = Filters.type == 'barges' ? [{
      title: 'Dep Timestamp UTC',
      type: 'date',
      key: 'atd_utc',
      key1: 'atd_utc_start',
      key2: 'atd_utc_end'
    }] : [];

    setFilters({
      ...Filters,
      currentFilter: Dropdowns,
      extraFilter: extraFilter,
      childFilter: childFilter,
      dateFilter: dateFilter
    })
  }

  const getShipmentFilter = () => {
    console.log("called")
    const token = localStorage.getItem('token')
    setFilterLoader(true)
    Filters.type == 'arrivals'
      ? company_apis?.getFilters(
        `/${comodity}/fixtures/arrival/unique`,
        {
          ...Filters?.applyfilter
        },
        token,
        (err, data) => {
          if (err == null) {
            setArrivalandShipment({
              ...arrivalandshipment,
              arrivals: data?.data?.data
            })
            // setFilterLoader(false)
            // setLoader(false)
          } else {
            // setFilterLoader(false)
            // setLoader(false)
          }
          setFilterLoader(false)
        }
      ) :
      Filters.type == 'barges'
        ? company_apis?.getFilters(
          `${comodity == 'shipData' ? '' : comodity}/barges/fixtures/arrival/unique`,
          {
            ...Filters?.applyfilter
          },
          token,
          (err, data) => {
            if (err == null) {
              setArrivalandShipment({
                ...arrivalandshipment,
                barges: data?.data?.data
              })
              // setFilterLoader(false)
              // setLoader(false)
            } else {
              // setFilterLoader(false)
              // setLoader(false)
            }
            setFilterLoader(false)
          }
        )
        :
        Filters.type == 'intransit'
          ? company_apis?.getFilters(
            `${comodity == 'shipData' ? '' : comodity}/intransit/fixtures/data/unique`,
            {
              ...Filters?.applyfilter
            },
            token,
            (err, data) => {
              if (err == null) {
                setArrivalandShipment({
                  ...arrivalandshipment,
                  intransit: data?.data?.data
                })
                // setFilterLoader(false)
                // setLoader(false)
              } else {
                // setFilterLoader(false)
                // setLoader(false)
              }
              setFilterLoader(false)
            }
          ) : company_apis?.getFilters(
            `/${comodity}/fixtures/shipment/unique`,
            {
              ...Filters?.applyfilter
            },
            token,
            (err, data) => {
              if (err == null) {
                setArrivalandShipment({
                  ...arrivalandshipment,
                  Shipments: data?.data?.data
                })
                // setLoader(false)
                // setFilterLoader(false)
              } else {
                // setFilterLoader(false)
                // setLoader(false)
              }
              setFilterLoader(false)

            }
          )
  }

  const getShipmentExtraFilter = () => {

    setUniqueFilter(true)
    const token = localStorage.getItem('token')
    setFilterLoader(true)
    Filters.type == 'arrivals'
      ? company_apis?.getFilters(
        `/${comodity}/fixtures/arrival/unique`,
        {
          ...Filters.applyExtraFilter
        },
        token,
        (err, data) => {
          if (err == null) {
            setArrivalandShipment({
              ...arrivalandshipment,
              arrivals: data?.data?.data
            })
            // setFilterLoader(false)
            setUniqueFilter(false)
          } else {
            // setFilterLoader(false)
            setUniqueFilter(false)
          }
        }
      ) :
      Filters.type == 'barges'
        ? company_apis?.getFilters(
          `${comodity == 'shipData' ? '' : comodity}/barges/fixtures/arrival/unique`,
          {
            ...Filters.applyExtraFilter
          },
          token,
          (err, data) => {
            if (err == null) {
              setArrivalandShipment({
                ...arrivalandshipment,
                barges: data?.data?.data
              })
              // setFilterLoader(false)
              setUniqueFilter(false)
            } else {
              // setFilterLoader(false)
              setUniqueFilter(false)
            }
          }
        )
        :
        Filters.type == 'intransit' ?
          company_apis?.getFilters(
            `${comodity == 'shipData' ? '' : comodity}/intransit/fixtures/data/unique`,
            {
              ...Filters.applyExtraFilter
            },
            token,
            (err, data) => {
              if (err == null) {
                setArrivalandShipment({
                  ...arrivalandshipment,
                  intransit: data?.data?.data
                })
                setUniqueFilter(false)
                // setFilterLoader(false)
              } else {
                // setFilterLoader(false)
                setUniqueFilter(false)
              }
            }
          )
          : company_apis?.getFilters(
            `/${comodity}/fixtures/shipment/unique`,
            {
              ...Filters.applyExtraFilter
            },
            token,
            (err, data) => {
              if (err == null) {
                setArrivalandShipment({
                  ...arrivalandshipment,
                  Shipments: data?.data?.data
                })
                setUniqueFilter(false)
                // setFilterLoader(false)
              } else {
                // setFilterLoader(false)
                setUniqueFilter(false)
              }
            }
          )
  }
  const showDrawer = () => {
    setOpen(true)
  }
  const onClose = () => {
    setOpen(false)
  }
  // drawer functions
  const showChartsDrawer = () => {
    setChartsOpen(true)
  }
  const onCloseChartsDrawer = () => {
    setChartsOpen(false)
  }
  const changeMapStyle = link => {
    setMapStyle(link)
    // getCompaniesdata(Filters)
  }
  const setLoading = (load) => {
    setLoader(load)
  }
  const SetDone = (d) => {
    setDone(d)
  }

  return (
    <>
      {' '}
      {loader == true ? (
        <>
          <div className='companyLoader'>
            <Spin tip='' size='large' className='mapspin'>
              <div className='content' />
            </Spin>
          </div>
          <div className='company-main-wrapper'>
            <CompanyDropdowns
              Filters={Filters}
              arrivalandshipment={arrivalandshipment}
              selectSpecificFilter={selectSpecificFilter}
              applyFilter={applyFilter}
              resetFilter={resetFilter}
              setFilters={setFilters}
              trailversion={trailversion}
              getCompaniesdata={getCompaniesdata}
              setFilterLoader={setFilterLoader}
              filterLoader={filterLoader}
              comodity={comodity}
              bargesAccessLevel={bargesAccessLevel}
            />
            {Filters.type !== 'intransit' &&
              <ChartDrawer
                placement='right'
                closable={false}
                onClose={onCloseChartsDrawer}
                open={chartsOpen}
                dataforgrapg={dataforgrapg}
                Filters={Filters}
              />}

            <CompanyFilterDrawer
              placement='left'
              Filters={Filters}
              uniqueFilter={uniqueFilter}
              closable={false}
              onClose={onClose}
              getCompaniesdata={getCompaniesdata}
              open={open}
              extraFilteration={extraFilteration}
              dwtValue={dwtValue}
            >
              <div >
                <FiltersForm
                  Filters={Filters}
                  arrivalandshipment={arrivalandshipment}
                  applyFilter={applyFilter}
                  applyExtraFilter={applyExtraFilter}
                  setDwtValues={(key, value) => { setDWTValues({ ...dwtValue, [key]: value }) }}
                  dwtValue={dwtValue}
                  trailversion={trailversion}
                />
              </div>
            </CompanyFilterDrawer>

            <Map
              Filters={Filters}
              showDrawer={showDrawer}
              showChartsDrawer={showChartsDrawer}
              projection={projection}
              companydata={companydata}
              getCompaniesdata={getCompaniesdata}
              mapType={mapStyle}
              changeMapStyles={changeMapStyle}
              currentMap={currentMap}
              setCurrentMap={setCurrentMap}
              setProjections={setProjection}
              loader={loader}
              setLoader={setLoading}
              done={done}
              setDone={SetDone}
              comodity={comodity}
            />
          </div>
        </>
      ) : (
        <>
          <div className='company-main-wrapper'>
            <CompanyDropdowns
              Filters={Filters}
              arrivalandshipment={arrivalandshipment}
              selectSpecificFilter={selectSpecificFilter}
              applyFilter={applyFilter}
              resetFilter={resetFilter}
              setFilters={setFilters}
              trailversion={trailversion}
              getCompaniesdata={getCompaniesdata}
              setFilterLoader={setFilterLoader}
              filterLoader={filterLoader}
              comodity={comodity}
              bargesAccessLevel={bargesAccessLevel}
            />

            {Filters.type !== 'intransit' && <ChartDrawer
              placement='right'
              closable={false}
              onClose={onCloseChartsDrawer}
              open={chartsOpen}
              dataforgrapg={dataforgrapg}
              Filters={Filters}
            />}

            <CompanyFilterDrawer
              placement='left'
              Filters={Filters}
              uniqueFilter={uniqueFilter}
              closable={false}
              onClose={onClose}
              getCompaniesdata={getCompaniesdata}
              open={open}
              extraFilteration={extraFilteration}
              dwtValue={dwtValue}
              arrivalandshipment={arrivalandshipment}
              getShipmentExtraFilter={getShipmentExtraFilter}
            >
              <div className='antd-custom-company-drawer-form-wrapper'>
                <FiltersForm
                  Filters={Filters}
                  arrivalandshipment={arrivalandshipment}
                  applyFilter={applyFilter}
                  applyExtraFilter={applyExtraFilter}
                  setDwtValues={(key, value) => { setDWTValues({ ...dwtValue, [key]: value }) }}
                  dwtValue={dwtValue}
                />
              </div>
            </CompanyFilterDrawer>
            <Map
              Filters={Filters}
              showDrawer={showDrawer}
              showChartsDrawer={showChartsDrawer}
              projection={projection}
              companydata={companydata}
              getCompaniesdata={getCompaniesdata}
              mapType={mapStyle}
              changeMapStyles={changeMapStyle}
              currentMap={currentMap}
              setCurrentMap={setCurrentMap}
              setProjections={setProjection}
              loader={loader}
              setLoader={setLoading}
              done={done}
              setDone={SetDone}
              comodity={comodity}
              legends={legends}
              addLegend={addLegend}
              removeLegend={removeLegend}
            />
          </div>
        </>
      )}
    </>
  )
})

export default Company
