import React, { useState } from 'react';
import { Popover } from 'antd';
import { EditOutlined } from '@ant-design/icons';

const PopoverDialog = ({ record, content }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (

        <Popover content={content} open={isOpen}>
            <span onMouseLeave={() => { setIsOpen(false) }} onMouseEnter={() => { setIsOpen(true) }} style={{ display: 'flex', gap: 5 }}>
                <p>{record?.company + " " + record?.colour} </p><EditOutlined />
            </span>
        </Popover>
    );
};
export default PopoverDialog;