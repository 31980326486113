// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.probability-title{
font-family: Inter;
width: auto;
font-size: 12px;
font-weight: 500;
line-height: 14.4px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #252525;
}`, "",{"version":3,"sources":["webpack://./src/Components/common/style.css"],"names":[],"mappings":"AAAA;AACA,kBAAkB;AAClB,WAAW;AACX,eAAe;AACf,gBAAgB;AAChB,mBAAmB;AACnB,gBAAgB;AAChB,kCAAkC;AAClC,8BAA8B;AAC9B,cAAc;AACd","sourcesContent":[".probability-title{\nfont-family: Inter;\nwidth: auto;\nfont-size: 12px;\nfont-weight: 500;\nline-height: 14.4px;\ntext-align: left;\ntext-underline-position: from-font;\ntext-decoration-skip-ink: none;\ncolor: #252525;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
