import React, { useState, useEffect, useContext } from 'react'
import { Pagination } from 'antd'
import { ProTable } from '@ant-design/pro-components'
import { Button } from 'antd'
import { ReactComponent as FilterIcon } from '../../Assets/images/filter-icon.svg'
import { ReactComponent as DownloadIcon } from '../../Assets/images/fixtures/Downloadicon.svg'
import './fixtures.css'
import axios from '../../axiosconfiguration/axios'
import UserContext from '../../store/UserContext'

import { useNavigate } from 'react-router-dom'

import { transformDataSource } from '../../utilities/utils'
import { downloadToCsv } from '../../utilities/downloadToCsv'

const DataTable = ({
  dataSource,
  dataSourceType,
  loading,
  columns,
  tableHeader,
  trailversion,
  showDrawer,
  setLoading,
  shipmentFilterUpdated,
  onSelectTableTab,
  url,
  comodity,
  defaultShipCount,
  defaultArrivCount,
  defaultIntransitCount,
  defaultLoadingCount,
  defaultBargesCount

}) => {
  const [filterFields, setFields] = useState([])
  const [colOrder, setColOrder] = useState({})
  const [tranformDataSource, setTranformDataSource] = useState();
  // const [url, setUrl] = useState(
  //   `/shipData/fixtures/arrival-data-in-chunks?limit=${pageSize}`
  // )
  const [clickable, setIsClickable] = useState(false)
  const [toggle, setToggle] = useState(false)
  const [actuallyDone, setActDone] = useState(false)
  // const navigate = useNavigate()
  // const {
  //   userState: { isAuth },
  //   setUserState
  // } = useContext(UserContext)


  useEffect(() => {
    const tableWrapper = document.querySelector('.ant-table-wrapper');
    console.log(tableWrapper)
    if (tableWrapper) {
      tableWrapper.scrollTop = 0;
    }
  }, [dataSource]);

  // useEffect(() => {
  //   console.log(dataSourceType)
  //   if (dataSourceType == 'arrival') {
  //     columns = Arrivalcolumns
  //   }
  //   else if (dataSourceType == 'loading') {
  //     columns = Loadingcolumns
  //   }
  //   else {
  //     columns = Shipmentcolumns
  //   }
  // }, [dataSourceType])



  useEffect(() => {

    setTranformDataSource(transformDataSource(dataSource));
  }, [dataSource]);

  useEffect(() => {
    let setting_btn = document?.getElementsByClassName(
      'ant-pro-table-list-toolbar-setting-items'
    )
    if (setting_btn?.length > 0) {
      let clickableDiv = setting_btn[0]

      clickableDiv.addEventListener('click', function (event) {

        console.log(clickableDiv)
        let setting = document?.getElementsByClassName('anticon-setting')
        // Check if the click was within the original element or the extended area
        if (event.clientX > clickableDiv.getBoundingClientRect().right) {
          // Action to perform on extended area click
          console.log('Extended area clicked')
          setting[0]?.click()
        } else {
          // Action to perform on original element click
          console.log('Original element clicked')
          if (clickable) {
            setting[0]?.click()
            setIsClickable(false)
          }
          else
            setIsClickable(true)
        }
        if (!actuallyDone)
          setToggle(prev => !prev)
      })
    }
  }, [])

  useEffect(() => {
    if (!actuallyDone) {
      // setTimeout(() => {
      // setToggle(prev=>!prev)
      // }, 1000);
      const popoverTitle = document.querySelector('.ant-popover-title');

      if (popoverTitle) {
        const existingPEl = popoverTitle.querySelector('.custom-p-element');

        if (!existingPEl) {
          const pElement = document.createElement('p');
          pElement.textContent = 'Drag columns to rearrange';

          pElement.className = 'custom-p-element';

          popoverTitle.appendChild(pElement);
          setActDone(true)
        }
      }
    }
  }, [toggle])

  // useEffect(() => {
  //   setCurrentPage(current_page)
  //   setTotalPage(totalPage)
  //   setPageSize(pageSizes)
  //   setLoading(loadings)
  // }, [current_page, pageSizes, totalPage, loadings])


  const downloadData = async () => {
    setLoading(true)
    const onComplete = () => {
      setLoading(false)
    }
    await downloadToCsv(comodity, url, colOrder, dataSourceType, filterFields, onComplete, shipmentFilterUpdated)
  }

  const onTabClick = (e) => {
    const tableWrapper = document.querySelector('.ant-table-wrapper');
    if (tableWrapper) {
      tableWrapper.scrollLeft = 0;
      tableWrapper.scrollTop = 0;
    }
    onSelectTableTab(e);
  }

  const getTableHeaders = () => {
    const headers = [];
    for (const key in tableHeader) {
      // if ((comodity == 'drihbi' || comodity == 'merchantiron') && key == 'intransit')
      //   continue;

      const element = tableHeader[key];
      headers.push(element);

    }


    return headers.map((header) => {
      return (<li
        key={header.name}
        className={
          header.isSelected
            ? 'mr-2 leading-8 active font-bold text-sm fixtures-heading' : 'mr-2 leading-8 text-sm fixtures-heading sub-menu-heading'
        }
      >
        <button
          className='text-xs lg:text-sm'
          onClick={() => {
            onTabClick(header.name)

          }}
        >
          {<span>({(header.isSelected && header.isFilterApplied) ? header.count : header.name == 'arrival' ? defaultArrivCount : header.name == 'intransit' ? defaultIntransitCount : header.name == 'loading' ? defaultLoadingCount : header.name == 'barges' ? defaultBargesCount : defaultShipCount})</span>}
          {header.label}
        </button>
      </li>)
    })
  }
  return (
    <div className='w-full h-auto overflow-auto '>
      <ProTable
        loading={loading}
        onColumnsStateChange={e => {
          console.log(e)
          setColOrder(e)
          let key = Object.keys(e)
          let allFields = []
          key?.map(item => {
            if (e?.[item]?.show == false) {
              allFields.push(item)
            }
          })
          setFields([...allFields])
        }}
        scroll={true}
        headerTitle={
          <>
            <div className='flex justify-start protable-header'>
              <ul className='flex flex-grow pl-3' style={{ columnGap: '35px' }}>
                {getTableHeaders()}
                {/* <li
                  className={
                    dataSourceType == 'arrival'
                      ? 'mr-2 leading-8 active font-bold text-sm fixtures-heading' : 'mr-2 leading-8 text-sm fixtures-heading'
                  }
                >
                  <button
                    className='text-xs lg:text-sm'
                    onClick={() => {
                     onTabClick("arrival")
                      // if (trailversion) {
                      //   setFilterApply(false)
                      //   setAppliedFilter(false)
                      //   setSetShipmentFiltersdata(null)
                      //   setDataSourceType('arrival')
                      //   getfixturesFilterDatas(
                      //     `/shipData/fixtures/filter/arrivals?limit=${pageSize}&page=${1}`,
                      //     defaultArrivalFilter
                      //   )
                      // }
                      // else {
                      //   setFilterApply(false)
                      //   setAppliedFilter(false)
                      //   setSetShipmentFiltersdata(null)
                      //   setDataSourceType('arrival')
                      //   getfixturesDatas(
                      //     `/shipData/fixtures/arrival-data-in-chunks?limit=${pageSize}&page=${1}`
                      //   )
                      // }

                    }}
                  >
                    {<span>({dataCount[0]})</span>}
                    Arrivals
                  </button>
                </li> */}
                {/* <li
                  className={
                    dataSourceType == 'departure'
                      ? 'leading-8 active font-bold fixtures-heading' : ' leading-8 fixtures-heading'
                  }
                >
                  <button
                    className='text-xs lg:text-sm'
                    onClick={() => {
                        onTabClick("arrival")
                      // if (trailversion) {
                      //   setFilterApply(false)
                      //   setAppliedFilter(false)
                      //   setSetShipmentFiltersdata(null)
                      //   setDataSourceType('departure')
                      //   getfixturesFilterDatas(
                      //     `/shipData/fixtures/filter/shipments?limit=${pageSize}&page=${1}`,
                      //     defaultShipmentFilter
                      //   )
                      // }
                      // else {
                      //   setFilterApply(false)
                      //   setAppliedFilter(false)
                      //   setSetShipmentFiltersdata(null)
                      //   setDataSourceType('departure')
                      //   getfixturesDatas(
                      //     `/shipData/fixtures/shipment-data-in-chunks?limit=${pageSize}&page=${1}`
                      //   )
                      // }

                    }}
                  >
                    {<span> ({dataCount[1]})</span>}
                    Shipments
                  </button>
                </li> */}

                {/* <li
                  className={
                    dataSourceType == 'loading'
                      ? 'leading-8 active font-bold fixtures-heading' : ' leading-8 fixtures-heading'
                  }
                >
                  <button
                    className='text-xs lg:text-sm'
                    onClick={() => {
                       onTabClick("arrival")
                    }}
                  >
                    {<span> ({dataCount[2]})</span>}
                    Live Loading
                  </button>
                </li> */}
              </ul>
              {!trailversion && <Button
                type='text'
                onClick={() => {
                  downloadData()
                }}
                className=' flex mr-4 border-blue-200 bg-white text-xs md:text-sm'
                icon={<DownloadIcon className='mr-2 mt-0.5' />}
              >
                Download
              </Button>}
              <Button
                type='text'
                onClick={showDrawer}
                className=' flex border-blue-200 bg-white'
                icon={<FilterIcon className='mr-2' />}
              >
                Filter
              </Button>
            </div>
          </>
        }
        columns={columns}
        rowKey='index'
        pagination={false}
        onSetti
        dataSource={tranformDataSource}
        className='text-xs'
        components={{
          body: {}
        }}
      />
    </div>
  )
}

export default DataTable
