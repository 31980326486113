import React from 'react';
import { ReactComponent as Red } from '../../Assets/images/icons/red-signal.svg'
import { ReactComponent as Green } from '../../Assets/images/icons/green-signal.svg'
import { ReactComponent as Yellow } from '../../Assets/images/icons/yellow-signal.svg'
import './style.css'
const ProbabilityPopOver = ({ title }) => {

    return (
        <div className='probability-title'>
            <p style={{ marginBottom: '10px' }}>Probability of <span style={{ fontWeight: "bold" }}>{title}</span> as the Expected Buyer</p>
            <p style={{ gap: 6, display: 'flex' }}>
                <Red /> <span>&lt;50%</span>
                <Yellow /><span>&ge;50% and &lt;75%</span>
                <Green /><span>&ge;75%</span>
            </p>
        </div>
    );
};
export default ProbabilityPopOver;