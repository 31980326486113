import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ReactComponent as VectorUp } from '../Assets/images/Vector5.svg'
import { ReactComponent as VectorDown } from '../Assets/images/Vector6.svg'
// cards data
import { ReactComponent as NavigateMarine } from '../Assets/images/NavigateMarine.svg'
import { ReactComponent as Navlogo } from '../Assets/images/NavLogo.svg'
import { ReactComponent as Fullimg1 } from '../Assets/images/fullimg.svg'


import { ReactComponent as NavigateEarth } from '../Assets/images/NavigateEarth.svg'
import { ReactComponent as NavigateCompass } from '../Assets/images/NavigateCompass.svg'
import { ReactComponent as NavigateDirectory } from '../Assets/images/NavigateDirectory.svg'
import { ReactComponent as IronOre } from '../Assets/images/ic_iron-ore.svg'
import { ReactComponent as Coal } from '../Assets/images/ic_coal.svg'
import { ReactComponent as FerrousScrap } from '../Assets/images/ic_ferrous-scrap.svg'
import { ReactComponent as Steel } from '../Assets/images/ic_steel.svg'
import { allDbData, getAllDataFromDB, RemoveAllDataFromDB } from "../Pages/Company/company-reducers.js"
// css
import '../App.css'
import { Link } from 'react-router-dom'
import axios from '../axiosconfiguration/axios'
import { useState } from 'react'
// import { useNavigate } from 'react-router-dom'

import { Badge, Spin, Tag } from 'antd'
import { LoadingOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import { Modal } from 'antd'

const Welcome = () => {
  const [accessLevels, setAccessLevels] = useState(null)
  const [Loading, setLoading] = useState(false)
  let ListIdstring = localStorage.getItem('ListIds')
  let ListId = JSON.parse(ListIdstring)
  const location = useLocation()
  const navigate = useNavigate()

  const warning = text => {
    Modal.warning({
      title: <p className='text-xl'>Access Denied</p>,
      content: (
        <div>
          <p>
            We're sorry, but you don’t have permission to access <b>“{text}”</b>
          </p>
          <br />
          <p>
            We'd love to grant you access! Simply send us a request, and we'll
            take care of the rest. <br />{' '}
            <a
              className='text-sky-700'
              href='mailto:support@navigatecommodities.com'
            >
              support@navigatecommodities.com
            </a>{' '}
          </p>
        </div>
      ),
      okText: 'Got it',
      bodyStyle: {
        borderRadius: '0px !important'
      }
    })
  }
  useEffect(() => {
    setTimeout(() => {
      let synth = window?.speechSynthesis
      synth?.cancel()
    }, 300)
  }, [location])

  useEffect(() => {
    getAccessLevels()
  }, [])
  const delay = (delayInms) => {
    return new Promise(resolve => setTimeout(resolve, delayInms));
  };
  //api call access level
  const getAccessLevels = async () => {
    setLoading(true)
    try {
      const response = await axios.get('/access-levels', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })
      if (response?.data) {
        console.log('acess levels resp', response?.data)
        setAccessLevels(response?.data?.data)
        localStorage.setItem(
          'AccessLevelData',
          JSON.stringify(response?.data?.data)
        )
        await RemoveAllDataFromDB();
        await getAllDataFromDB();
        setLoading(false)
      }

    } catch (error) { }
  }

  function cardItemsWithAccesslevels() {
    let cardItems = []
    if (accessLevels !== null) {
      // console?.log('access levels',accessLevels[0]?.id)
      return (cardItems = [
        {
          id: 2,
          text: 'Navigate Marine',
          icon: <NavigateMarine />,
          path: '/ferrousScrap'
        },
        {
          id: 12,
          text: 'Navigate Earth',
          icon: <NavigateEarth />,
          path: '/welcome',
          comingSoon: true
        },
        {
          id: 11,
          text: 'Market Compass',
          icon: <NavigateCompass />,
          path: '/market-compass'
        },
        {
          id: 1,
          text: 'Interactive Directory',
          icon: <NavigateDirectory />,
          path: '/inter-dir',
          disabled: true
        },
        {
          id: 13,
          text: 'Iron Ore',
          icon: <IronOre />,
          path: '/iron-ore'
        },
        {
          id: 8,
          text: 'Ferrous Scrap & Metallics',
          icon: <FerrousScrap />,
          path: '/ferrous-scrap'
        },
        {
          id: 9,
          text: 'Semis & Finished Steel',
          icon: <Steel />,
          path: '/semis-steel'
        },
        {
          id: 10,
          text: 'Metallurgical Coal & Coke',
          icon: <Coal />,
          path: '/coal-coke'
        }
      ])
    }
  }
  const actionitems = cardItemsWithAccesslevels()

  const antIcon = (
    <Navlogo
      style={{
        fontSize: 24,
        color: '#fff'
      }}
      spin
    />
  )

  return (
    <>
      <div className='Welcome-vector5'>
        <VectorUp />
      </div>
      <div className='Welcome-vector6'>
        <VectorDown />
      </div>
      <div className='welcome-page-wrapper w-full flex flex-col items-center  '>
        <p className='welcome-page-headline font-semibold text-2xl '>
          Welcome to Navigate Members’ area
        </p>
        <p className='welcome-page-sub-headline font-normal mt-4 mb-7 '>
          Please choose from the options below
        </p>
        <div className='welcome-page-cards-wrapper flex flex-col w-4/5 h-auto m-auto md:flex-row  md:w-4/5 md:justify-evenly rounded z-10'>
          {Loading ? (
            <div className='flex' style={{ width: '100%' }}>
              {' '}
              {/* <Spin className="welcome-spin" indicator={antIcon} size='large'/>{' '} */}
              <Navlogo className='navlogo' />
              {/* <LeftDish className='leftdish'/>
              <RightDish className='rightdish'/>
              <Antpath className='ant-path'/> */}
              <div className='fullimg-div'>
                <Fullimg1 className='fullimg' />
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '95%', color: 'white', fontSize: '0.8em', marginTop: '5px' }}>
                  <p style={{ textAlign: 'center', lineHeight: '16px' }}>Establishing<br />Satellite Uplink</p>
                  <p style={{ textAlign: 'center', lineHeight: '16px' }}>Establishing<br />Satellite Downlink</p>
                </div>
              </div>

              {/* <img src={Fullimg1}></img> */}
            </div>

          ) : (
            actionitems?.map((item, index) => {
              // parse ids from ['2','3'] to [2,3]
              let currentItem = accessLevels?.find(i => i?.id == item?.id)
              let itemids = currentItem?.lists?.map(it => JSON.parse(it))
              if (item?.id == 2) {
                const currentItemList = accessLevels?.filter(i => (i?.id == 2 || i?.id == 14 || i?.id == 15 || i?.id == 17 || i?.id == 18))
                itemids = [];
                currentItemList.map((ci) => {
                  const marineObj = {}
                  const marineIds = (ci?.lists?.map(it => JSON.parse(it)));
                  marineObj.id = ci.id
                  marineObj.comodityIdList = marineIds;
                  itemids.push(marineObj);
                })
              }

              return (
                <div
                  style={{ cursor: item?.disabled == true ? 'not-allowed' : '' }}
                  className='relative welcome-page-cards w-full h-40 mb-4 rounded bg-white flex flex-col justify-center items-center cursor-pointer md:w-1/5 transition duration-300 ease-in-out hover:shadow-lg hover:scale-110 dark:hover:shadow-black/30'
                  key={item.id}
                  onClick={() => {
                    // if (item?.comingSoon == true) {
                    //   window.open('https://navigate.gains.earthi.world/', '_blank')
                    //   return
                    // } else 
                    if (item?.disabled == true) {
                      return
                    }
                    if (item.path == '/ferrousScrap') {
                      const accessId = itemids.map((itemId) => {
                        if (ListId?.some(ai => itemId.comodityIdList.includes(ai)))
                          return itemId?.id;
                      })
                      if (accessId[0] == 2) {
                        navigate("/ferrousScrap", { state: { accessLevels: accessLevels } })
                      } else if (accessId[2] == 15) {
                        navigate("/merchantPigIron", { state: { accessLevels: accessLevels } })
                      } else if (accessId[1] == 14) {
                        navigate("/driHbi", { state: { accessLevels: accessLevels } })
                      } else if (accessId[3] == 17) {
                        navigate("/ironOre", { state: { accessLevels: accessLevels } })
                      } else if (accessId[4] == 18) {
                        navigate("/stainlessScrap", { state: { accessLevels: accessLevels } })
                      } else {
                        warning(item?.text)
                      }
                    } else {
                      itemids?.length < 1 ||
                        ListId?.some(ai => itemids.includes(ai))
                        ?
                        item?.comingSoon ?
                          window.open('https://navigate.gains.earthi.world/', '_blank')
                          : navigate(item?.path, { state: { accessLevels: accessLevels } })
                        : warning(item?.text)
                    }
                  }}
                >
                  {item?.disabled == true && (
                    <Tag className='tag-Name absolute top-0 left-0'>Coming Soon</Tag>
                  )}
                  <div className='flex justify-center'>{item?.icon}</div>
                  <p className='mt-5 font-medium md:text-center  welcome-parts'>
                    {item?.text}
                  </p>
                </div>
              )
            })
          )}
        </div>
      </div>
    </>
  )
}

export default Welcome
