import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { company_apis } from './api'
const initialState = {
  seaRoutes: [],
  coloadRoute: []
}

var allSeaRoutes = []
var allCoload = []
const dbName = 'myDatabase'
const dbVersion = 1
let allDbData;
export const getSeaRoutes = createAsyncThunk('data/seaRoutes', async token => {
  // const response = await getAllSeaRoutes(1, token)
  const response = await getAllData(token);
  console.log("All routes added", response)
  localStorage.setItem('seaRoutes', JSON.stringify(response?.alldata))
  return response
})

export const getCoLoadRoutes = createAsyncThunk('data/coload', async token => {
  response = await getAllSeaCoload(1, token)
  console.log(response)
  return response
})

export async function getRouteFromDB(id) {

  const db = await openDB();
  const data = await getItemFromDB(db, id);
  if (data) {
    return data.data;
  }
  return data;

}
async function getItemFromDB(db, id) {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction('myObjectStore', 'readonly');
    const objectStore = transaction.objectStore('myObjectStore');

    // Get item by id
    const getRequest = objectStore.get(id);

    // Handling the success and error via Promise
    getRequest.onsuccess = () => resolve(getRequest.result);
    getRequest.onerror = (event) => reject(new Error('Failed to retrieve item: ' + event.target.error));
  });
}

export function getAllDataFromDB() {
  if (allDbData && allDbData.length > 0)
    return allDbData;

  return new Promise((resolve, reject) => {
    openDB()
      .then(db => {
        const transaction = db.transaction(['myObjectStore'], 'readonly')
        const objectStore = transaction.objectStore('myObjectStore')

        const getRequest = objectStore.getAll()

        getRequest.onsuccess = function (event) {
          allDbData = event.target.result;
          resolve(event.target.result)
        }

        getRequest.onerror = function (event) {
          reject(event.target.error)
        }
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function RemoveAllDataFromDB() {
  return new Promise((resolve, reject) => {
    openDB()
      .then(db => {
        const transaction = db.transaction(['myObjectStore'], 'readwrite')
        const objectStore = transaction.objectStore('myObjectStore')

        const getRequest = objectStore.clear();

        getRequest.onsuccess = function (event) {
          allDbData = event.target.result;
          resolve(event.target.result)
        }

        getRequest.onerror = function (event) {
          reject(event.target.error)
        }
      })
      .catch(error => {
        reject(error)
      })
  })
}
export const getRouteDataById = async (id) => {
  try {
    const token = localStorage.getItem('token');
    const result = await company_apis?.getSeaRoutes(`/shipData/companies/routes/shipdata?id=${id}&limit=1&page=${1}`, token)
    return result?.data?.data;
  } catch (error) {
    console.log("Route not found error: ", error)
    return [];
  }
}
export const checkNewDataAvailable = async (token) => {
  const result1 = await company_apis?.getSeaRoutes(`/shipData/companies/routes/shipdata?page=${1}`, token)
  if (result1) {
    const allData = await getAllDataFromDB();
    allData && allData.length > 0 && console.log("Local Ship data count: ", allData[0].length)
    console.log("Remote Ship data count: ", result1?.data?.meta?.total)
    if (allData && allData.length > 0 && allData[0].length >= result1?.data?.meta?.total) {
      return false;
    } else {
      // await RemoveAllDataFromDB();
    }
    return result1;
  }
  return false;
}
const delay = (delayInms) => {
  return new Promise(resolve => setTimeout(resolve, delayInms));
};
export const getAllData = async (token) => {
  // let result1 = await checkNewDataAvailable(token);
  if (true) return;
  allDbData = null;
  allSeaRoutes = [];
  const allData = await getAllDataFromDB();
  if (allData && allData.length > 0 && allData[0].length > 0) {
    const lastRoute = allData[0][allData[0].length - 1]
    const result = await company_apis?.getSeaRoutes(`/shipData/companies/routes/shipdata?id=${lastRoute.id}&limit=1000000&page=${1}`, token)
    allSeaRoutes = allData[0].concat(result?.data?.data);
  } else {
    const pageLimit = 200;
    const result = await company_apis?.getSeaRoutes(`/shipData/companies/routes/shipdata?limit=${pageLimit}&page=${1}`, token)
    allSeaRoutes = result?.data?.data;

    const promiseCallCount = Math.ceil(result.data.meta.last_page / 30);
    let pageIndex = 2;
    let totalPages = 30

    for (let index = 0; index < promiseCallCount; index++) {
      const promises = []
      if (index > 0) {
        const tempPage = result.data.meta.last_page - totalPages;
        if (tempPage < 30)
          totalPages = totalPages + tempPage;
        else
          totalPages = totalPages + 30;
        totalPages
        await delay(20000);
      }
      for (pageIndex; pageIndex <= totalPages; pageIndex++) {
        const promise = Promise.resolve(company_apis?.getSeaRoutes(`/shipData/companies/routes/shipdata?limit=${pageLimit}&page=${pageIndex}`, token))
        promises.push(promise)
      }
      await Promise.all(promises).then(res => {
        for (let index = 0; index < res.length; index++) {
          const element = res[index];
          allSeaRoutes = [...allSeaRoutes, ...element?.data?.data]
        }

      })
    }
  }
  console.log("All Combined Data", allSeaRoutes)
  await RemoveAllDataFromDB();
  await updateDataToDB(allSeaRoutes);
}

const getAllSeaCoload = async (pageNo, token) => {
  await company_apis
    ?.getSeaRoutes(`/shipData/companies/routes/coload?page=${pageNo}`, token)
    .then(data => {
      console.log(data)
      allCoload = [...allCoload, ...data?.data?.data]
      if (pageNo < data?.data?.meta?.last_page) {
        getAllSeaCoload(data?.data?.meta?.current_page + 1, token)
      } else {
        localStorage.setItem('coloads', JSON.stringify(allCoload))
        data.alldata = allCoload

        return data
      }
    })
    .catch(err => {
      return []
    })
}

export async function updateDataToDB(data, dataId) {
  return new Promise((resolve, reject) => {
    openDB()
      .then(db => {
        const transaction = db.transaction(['myObjectStore'], 'readwrite')
        const objectStore = transaction.objectStore('myObjectStore')
        console.log("Data store in db", data);
        const addRequest = objectStore.put({ id: dataId, data });
        addRequest.onsuccess = function (event) {
          resolve(event.target.result)
        }

        addRequest.onerror = function (event) {
          reject(event.target.error)
        }
      })
      .catch(error => {
        reject(error)
      })
  })
}
function openDB() {
  return new Promise((resolve, reject) => {
    const openRequest = indexedDB.open(dbName, dbVersion)

    openRequest.onupgradeneeded = function (event) {
      const db = event.target.result
      if (!db.objectStoreNames.contains('myObjectStore')) {
        db.createObjectStore('myObjectStore', {
          keyPath: 'id',
          autoIncrement: false
        })
      }
    }

    openRequest.onsuccess = function (event) {
      resolve(event.target.result)
    }

    openRequest.onerror = function (event) {
      reject(event.target.error)
    }
  })
}
export const companySlice = createSlice({
  name: 'company',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getSeaRoutes.fulfilled, (state, action) => {
        state.seaRoutes = action?.payload?.data?.data
      })
      .addCase(getSeaRoutes.rejected, (state, action) => {
        state.seaRoutes = []
      })
      .addCase(getCoLoadRoutes.fulfilled, (state, action) => {
        state.coloadRoute = action?.payload?.data?.data
      })
      .addCase(getCoLoadRoutes.rejected, (state, action) => {
        state.coloadRoute = []
      })
  }
})

export default companySlice?.reducer
